import { useEffect, useState } from "react";
import { saveMatterChildObjectFieldChanges, saveMatterChildTableLinksChanges } from "api/matter";
import PropTypes from "prop-types";
import { getMatterContacts } from "api/contact";
import { t } from "locale/dictionary";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { sectionTypes } from "utilities/constants";
import SuperSelect from "components/global/SuperSelect";
import { useSelector } from "react-redux";
import { findById, idsAreEqual } from "utilities/stringAndArray";

// Container Component for the display of the name field for Company and Legal Team Link Contacts
function PanelFieldContactNameLookup({ keyProps, canEdit, onChange }) {
  const appState = useSelector((state) => state.app);

  const [matterContacts, setMatterContacts] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const isLegalTeam = keyProps.tableName === "legalTeamContactLinks";
  const initialMatterContactId = isLegalTeam
    ? keyProps.rowData.matterLegalTeamContactLink_MatterContactId
    : keyProps.rowData.matterCompanyLinkContactLink_MatterContactId;

  //debugger;

  useEffect(() => {
    if (isLegalTeam) {
      const allLegalTeam = getAllLookupValuesForSource("LegalTeam").map((matterContact) => {
        return {
          id: matterContact.id,
          matterId: matterContact.matterId,
          displayValue: `${matterContact.givenName} ${matterContact.familyName}`,
        };
      });
      setMatterContacts(allLegalTeam);
      setSelectedContactId(initialMatterContactId);
    } else {
      // Confusing use of fieldnames. If one is null, try the other. @Anthony, what is going on here?
      let matterId = keyProps.rowData[keyProps.parentIdFieldName];
      if (!matterId) matterId = keyProps.rowData[keyProps.parentIdOtherFieldName];
      getMatterContacts(appState.customerId, matterId).then((response) => {
        const matterContactsDisplay = response.map((matterContact) => {
          return {
            id: matterContact.id,
            displayValue: `${matterContact.matterContact_GivenName} ${matterContact.matterContact_FamilyName}`, // Companies only
          };
        });
        setMatterContacts(matterContactsDisplay);
        setSelectedContactId(initialMatterContactId);
      });
    }
  }, [keyProps.rowData.matterCompanyLinkContactLink_MatterId, initialMatterContactId]);

  const onContactChange = (optionId) => {
    setSelectedContactId(optionId);
    if (keyProps.isAdding) onChange(keyProps.field.fieldName, optionId);
    else if (keyProps.isProject) onChange(keyProps.field.fieldName, keyProps.rowData, optionId);
    else {
      const contactmatterId = findById(matterContacts, optionId)?.matterId;
      if (isLegalTeam) {
        saveMatterChildObjectFieldChanges(
          keyProps.record.id,
          keyProps.tableName,
          keyProps.rowData.id,
          keyProps.field.fieldName,
          optionId,
          initialMatterContactId,
          contactmatterId
        );
      } else {
        saveMatterChildTableLinksChanges(
          keyProps.record.id,
          keyProps.parentTableName,
          keyProps.parentRowData.id,
          keyProps.tableName,
          keyProps.rowData.id,
          keyProps.field.fieldName,
          optionId,
          initialMatterContactId
        );
      }
    }
  };

  const renderValueContents = canEdit ? (
    <SuperSelect
      id={`${keyProps.tableName}ContactName${keyProps.rowData ? keyProps.rowData.id : ""}`}
      options={matterContacts}
      selectedOptionId={selectedContactId}
      onChange={(id) => onContactChange(id)}
    />
  ) : isLegalTeam ? (
    keyProps.rowData.matterLegalTeamContactLink_FullName
  ) : (
    keyProps.rowData.matterCompanyLinkContactLink_FullName
  );

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <>{renderValueContents}</>
  ) : (
    <>
      <div className="field__display-name">{t("Name")}</div>
      <div className="field__display-value">{renderValueContents}</div>
    </>
  );
}

PanelFieldContactNameLookup.propTypes = {
  keyProps: PropTypes.object,
  canEdit: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PanelFieldContactNameLookup;
