import { useState } from "react";
import { useDispatch } from "react-redux";
import { setBorderProperty } from "redux/reportSlice";
import Border from "./Border";
import BorderPicker from "./BorderPicker";
import useComponentVisible from "components/global/useComponentVisible";
import { FaCaretDown } from "react-icons/fa";
import PropTypes from "prop-types";
import Tooltip from "components/global/Tooltip";

function ToolbarBorderPicker({ value }) {
  const dispatch = useDispatch();

  const [border, setBorder] = useState(value);

  const [refPicker, showPicker, setShowPicker] = useComponentVisible(false);

  return (
    <Tooltip content="Select cell borders" direction="left">
      <>
        <div className="toolbar__button" onClick={() => dispatch(setBorderProperty({ type: border }))}>
          <div
            style={{
              display: "grid",
              // justifyContent: "center",
              gridTemplateColumns: "1fr 16px 1fr",
              gridTemplateRows: "1fr 16px 1fr",
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                gridRow: 2,
                gridColumn: 2,
              }}
            >
              <Border type={border} />
            </div>
          </div>
        </div>
        <div
          className="toolbar__button"
          onClick={() => setShowPicker(!showPicker)}
          style={{
            width: "18px",
          }}
        >
          <FaCaretDown className="button__icon" />
        </div>
        <div style={{ verticalAlign: "middle" }}>
          <BorderPicker
            inputRef={refPicker}
            border={border}
            setBorder={(borderType) => {
              setBorder(borderType);
              dispatch(setBorderProperty({ type: borderType }));
              setShowPicker(false);
            }}
            show={showPicker}
            setShow={setShowPicker}
          />
        </div>
      </>
    </Tooltip>
  );
}

ToolbarBorderPicker.propTypes = {
  value: PropTypes.string,
};

export default ToolbarBorderPicker;
