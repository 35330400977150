import PropTypes from "prop-types";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";

function FieldListGroupHeader({ groupName, label, isVisible, onHeaderClick, filtered, dataFields }) {
  const classNames = ["groups__group-header"];
  if (filtered) {
    classNames.push("groups__group-header-filtered");
    label = `${label} (${dataFields.length})`;
  }
  return (
    <div
      className={classNames.join(" ")}
      onClick={(event) => {
        onHeaderClick(groupName);
      }}
    >
      {isVisible ? <FaCaretDown /> : <FaCaretRight />}&nbsp;{label}&nbsp;&nbsp;&nbsp;
    </div>
  );
}

FieldListGroupHeader.propTypes = {
  groupName: PropTypes.string,
  label: PropTypes.string,
  isVisible: PropTypes.bool,
  onHeaderClick: PropTypes.func,
  filtered: PropTypes.bool,
  dataFields: PropTypes.array,
};

export default FieldListGroupHeader;
