import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import dataFieldSlice from "./dataFieldSlice";
import localeSlice from "./localeSlice";
import lookupSlice from "./lookupSlice";
import mapDataSlice from "./mapDataSlice";
import matterSlice from "./matterSlice";
import reportSlice from "./reportSlice";
import searchResultsSlice from "./searchResultsSlice";
import simpleSearchSlice from "./simpleSearchSlice";
import customerSlice from "./customerSlice";
import homeSlice from "./homeSlice";
import securitySlice from "./securitySlice";
import batchCreateSlice from "./batchCreateSlice";
import batchImportSlice from "./batchImportSlice";
import projectSlice from "./projectSlice";

// Redux global store referencing various slices of state
export default configureStore({
  reducer: {
    app: appSlice,
    customer: customerSlice,
    lookup: lookupSlice,
    dataField: dataFieldSlice,
    simpleSearch: simpleSearchSlice,
    searchResults: searchResultsSlice,
    report: reportSlice,
    project: projectSlice,
    batchCreate: batchCreateSlice,
    batchImport: batchImportSlice,
    locale: localeSlice,
    security: securitySlice,
    matter: matterSlice,
    mapData: mapDataSlice,
    home: homeSlice,
  },
});
