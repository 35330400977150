import store from "redux/store";

export async function getDocumentData(matterId, documentId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const requestOptions = {
    method: "GET",
    headers: { Authorization: state.app.bearerToken },
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_ROOT}customers/${customerId}/matters/${matterId}/documentdata/${documentId}/formdata`,
    requestOptions
  );
  return await response.blob();
}
