import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFilter, setKeyboardFocusedSelection } from "redux/simpleSearchSlice";
import { labelTypes, searchViewTypes, simpleSearchKeyboardFocusedPopup } from "utilities/constants";
import { getInputBoolean, getDefaultOperatorForField } from "utilities/simpleSearch";
import PropTypes from "prop-types";
import SuggestedFieldPopupBoolean from "./SuggestedFieldPopupTypes/SuggestedFieldPopupBoolean";
import SuggestedFieldPopupDate from "./SuggestedFieldPopupTypes/SuggestedFieldPopupDate";
import SuggestedFieldPopupLookup from "./SuggestedFieldPopupTypes/SuggestedFieldPopupLookup";
import SuggestedFieldPopupString from "./SuggestedFieldPopupTypes/SuggestedFieldPopupString";
import { isFieldNameUnique } from "utilities/datafield";
import { accessRightFilterAdd, accessRightFilterUpdate } from "api/security";
import { addBatchImportSearchFilter, updateBatchImportSearchFilter } from "api/batchImport";

/* Suggested Field Popup Content. This displays the content for configuring a field filter within the Suggested Field Popup Container
 */
function SuggestedFieldPopupContent({
  field,
  sectionDisplayName,
  parent,
  isFieldNameMatch,
  filter,
  handleIsApplicableChangeParent,
  isApplying,
  handleHasAppliedField,
  handleForceApply,
}) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const securityState = useSelector((state) => state.security);
  const batchImportState = useSelector((state) => state.batchImport);
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const [valueArray, setValueArray] = useState(
    filter ? filter.valueArray : field.dataType === labelTypes.BOOLEAN ? [getInputBoolean(true)] : null
  );
  const [operator, setOperator] = useState(filter ? filter.operator : getDefaultOperatorForField(field));
  const [qualified, setQualified] = useState(filter?.qualified);
  const [qualifiedDictionary, setQualifiedDictionary] = useState(filter?.qualifiedDictionary);

  const [isApplicable, setIsApplicable] = useState(false); //filter ? false : field.dataType === labelTypes.BOOLEAN);

  useEffect(() => {
    if (isApplicable && isApplying) {
      let section = "";
      if (isFieldNameUnique(field.fieldName) === false) section = sectionDisplayName;

      const filterBody = {
        fieldName: field.fieldName,
        section,
        operator,
        valueArray,
        qualified,
        qualifiedDictionary,
      };
      setIsApplicable(false);
      //ACCESS RIGHTS
      if (simpleSearch.viewType === searchViewTypes.ACCESS_RIGHTS) {
        const accessRight = securityState.currentUserGroup.accessRight;
        if (accessRight.filters.find((f) => f.fieldName === field.fieldName)) {
          accessRightFilterUpdate(
            securityState.currentCustomer.id,
            accessRight.id,
            filter.id,
            "valueArray",
            filter.valueArray,
            valueArray
          );
        } else accessRightFilterAdd(securityState.currentCustomer.id, accessRight.id, filterBody);
      }
      //BATCH IMPORT
      // if (simpleSearch.viewType === searchViewTypes.BATCH_IMPORT) {
      //   const batchImportSearchFilters = batchImportState.project.searchFilters;
      //   if (batchImportSearchFilters.find((f) => f.fieldName === field.fieldName)) {
      //     if (filter.operator !== operator) {
      //       updateBatchImportSearchFilter(
      //         appState.customerId,
      //         batchImportState.project.id,
      //         filter.id,
      //         "operator",
      //         filter.operator,
      //         operator
      //       );
      //     }
      //     updateBatchImportSearchFilter(
      //       appState.customerId,
      //       batchImportState.project.id,
      //       filter.id,
      //       "valueArray",
      //       filter.valueArray,
      //       valueArray
      //     );
      //   } else addBatchImportSearchFilter(appState.customerId, batchImportState.project.id, filterBody);
      // } else
      dispatch(addFilter(filterBody));
    }
    handleHasAppliedField(field.fieldName);
  }, [isApplying]);

  const handleKeyDown = (e) => {
    if (simpleSearch.isKeyboardFocusedSelection === simpleSearchKeyboardFocusedPopup.RHS) {
      if (e.key === "ArrowLeft" || (e.shiftKey && e.key === "Tab")) {
        e.preventDefault();
        dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.MAIN));
      }
    }

    // if user hits enter send force apply to container
    if (e.key === "Enter") {
      handleForceApply();
      e.preventDefault();
    }
  };

  const handleValueChange = (newValueArray, newQualified, newQualifiedDictionary) => {
    setValueArray(newValueArray);
    if (newQualified) setQualified(newQualified);
    if (newQualifiedDictionary) setQualifiedDictionary(newQualifiedDictionary);
  };

  const handleOperatorChange = (newOperator) => {
    setOperator(newOperator);
  };

  const handleIsApplicableChange = (isApplicableIn) => {
    if (isApplicable !== isApplicableIn) {
      setIsApplicable(isApplicableIn);
      if (isApplicableIn) handleIsApplicableChangeParent(isApplicableIn);
    }
  };

  let renderTypeSpecificMarkup = null;

  let dataType = field.dataType;
  // Handle exceptions for certain fields, e.g. integer fields that aren't lookups, such as Matter ID
  if (field.fieldName === "matter_MatterId") dataType = labelTypes.STRING;

  switch (dataType) {
    case labelTypes.BOOLEAN:
      renderTypeSpecificMarkup = (
        <SuggestedFieldPopupBoolean
          valueArray={parent === "Field" ? [null] : valueArray}
          onValueChange={handleValueChange}
          onIsApplicableChange={handleIsApplicableChange}
        />
      );
      break;
    case labelTypes.DATE:
      renderTypeSpecificMarkup = (
        <SuggestedFieldPopupDate
          filter={filter}
          operator={operator}
          onOperatorChange={handleOperatorChange}
          onIsApplicableChange={handleIsApplicableChange}
          onValueChange={handleValueChange}
        />
      );
      break;
    case labelTypes.LOOKUP:
      renderTypeSpecificMarkup = (
        <SuggestedFieldPopupLookup
          field={field}
          parent={parent}
          isFieldNameMatch={isFieldNameMatch}
          filter={filter}
          operator={operator}
          valueArray={valueArray}
          onOperatorChange={handleOperatorChange}
          onValueChange={handleValueChange}
          onIsApplicableChange={handleIsApplicableChange}
        />
      );
      break;
    case labelTypes.STRING: {
      renderTypeSpecificMarkup = (
        <SuggestedFieldPopupString
          field={field}
          isFieldNameMatch={isFieldNameMatch}
          operator={operator}
          valueArray={valueArray}
          qualified={qualified}
          qualifiedDictionary={qualifiedDictionary}
          onOperatorChange={handleOperatorChange}
          onValueChange={handleValueChange}
          onIsApplicableChange={handleIsApplicableChange}
        />
      );
      break;
    }
    default: // it's a null
  }

  return parent === simpleSearch.labelSelected.clickSource ? (
    <div className="suggested-field__popup" ref={containerRef} onKeyDown={handleKeyDown}>
      {renderTypeSpecificMarkup}
    </div>
  ) : (
    parent === "Section" && (
      <div className="suggested-field__popup" ref={containerRef} onKeyDown={handleKeyDown}>
        <div className="popup__section-field-container">
          <div className="popup__section-field-title">{field.displayName}</div>
          {renderTypeSpecificMarkup}
        </div>
      </div>
    )
  );
}

SuggestedFieldPopupContent.propTypes = {
  field: PropTypes.object,
  sectionDisplayName: PropTypes.string,
  parent: PropTypes.string,
  isFieldNameMatch: PropTypes.bool,
  filter: PropTypes.object,
  handleIsApplicableChangeParent: PropTypes.func,
  isApplying: PropTypes.bool,
  handleHasAppliedField: PropTypes.func,
  handleForceApply: PropTypes.func,
};

export default SuggestedFieldPopupContent;
