import PropTypes from "prop-types";
import FieldGroup from "./FieldGroup";

function FieldGroups({ fieldGroups, onHeaderClick, filterInput, visibleFieldGroups, filtered }) {
  const dataFieldProperty = filtered ? "filteredDataFields" : "unFilteredDataFields";

  return (
    <>
      {fieldGroups
        .filter((fieldGroup) => fieldGroup[dataFieldProperty].length > 0)
        .map((fieldGroup) => {
          if (fieldGroup.name === "CustomerImages") return null;
          const groupName = (filtered ? "filt__" : "unfilt__") + fieldGroup.name;
          const isVisible = visibleFieldGroups.find((fieldGroup) => fieldGroup.name === groupName)
            ? !filtered
            : filtered;
          return (
            <FieldGroup
              key={groupName}
              isVisible={isVisible}
              groupName={groupName}
              groupLabel={fieldGroup.label}
              onHeaderClick={onHeaderClick}
              dataFields={fieldGroup[dataFieldProperty]}
              filterInput={filterInput}
              filtered={filtered}
            />
          );
        })}
    </>
  );
}

FieldGroups.propTypes = {
  fieldGroups: PropTypes.array,
  onHeaderClick: PropTypes.func,
  filterInput: PropTypes.string,
  visibleFieldGroups: PropTypes.array,
  filtered: PropTypes.bool,
};

export default FieldGroups;
