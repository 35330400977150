import { getCustomerImages, saveImageFieldChanges } from "api/image";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import FileUploader from "./FileUploader";
import { useDispatch, useSelector } from "react-redux";
import { setImages } from "redux/customerSlice";
import ImageBrowserThumbnail from "./ImageBrowserThumbnail";
import ImageBrowserThumbnailInterstice from "./ImageBrowserThumbnailInterstice";
import { compareByFieldSortIndex, findById, idsAreEqual } from "utilities/stringAndArray";
import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { getDataFieldFromName } from "utilities/reportEditor";
import { setSectionCellField, setSectionRowHeight } from "redux/reportSlice";

function ImageBrowser({ isSelectionMode, onClose }) {
  const customerImages = useSelector((state) => state.customer).images;
  const selectedGridElements = useSelector((state) => state.report).selectedGridElements;

  const dispatch = useDispatch();

  const [imagesSorted, setImagesSorted] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);

  useEffect(() => {
    if (customerImages) {
      let imagesTemp = [...customerImages];
      const imagesSortedTemp = imagesTemp.sort(compareByFieldSortIndex);
      setImagesSorted(imagesSortedTemp);
    }
  }, [customerImages]);

  useEffect(() => {
    const runGetCustomerImages = async () => {
      const response = await getCustomerImages();
      dispatch(setImages(response));
    };
    if (!customerImages) runGetCustomerImages();
  }, []);

  const onDrop = (imageBeingDroppedId, droppedBeforeImageId) => {
    let images = [...customerImages];
    const imageBeingDropped = findById(images, imageBeingDroppedId);
    let droppedBeforeSortIndex = null;
    if (droppedBeforeImageId) {
      const droppedBeforeImage = findById(images, droppedBeforeImageId);
      droppedBeforeSortIndex = droppedBeforeImage.sortIndex;
    } else {
      droppedBeforeSortIndex = customerImages.length + 1;
    }
    const beingDroppedSortIndex = imageBeingDropped.sortIndex;

    // Update sortIndex of dropped image
    saveImageFieldChanges(
      imageBeingDropped.id,
      "sortIndex",
      beingDroppedSortIndex > droppedBeforeSortIndex ? droppedBeforeSortIndex : droppedBeforeSortIndex - 1,
      beingDroppedSortIndex
    );

    // Update sortIndices of images in the order after dropped image

    let imagesAfterDrop =
      beingDroppedSortIndex > droppedBeforeSortIndex
        ? images.filter((image) => image.sortIndex >= droppedBeforeSortIndex && image.sortIndex < beingDroppedSortIndex)
        : images.filter((image) => image.sortIndex < droppedBeforeSortIndex && image.sortIndex > beingDroppedSortIndex);
    imagesAfterDrop.forEach((image) => {
      saveImageFieldChanges(
        image.id,
        "sortIndex",
        beingDroppedSortIndex > droppedBeforeSortIndex ? image.sortIndex + 1 : image.sortIndex - 1,
        image.sortIndex
      );
    });
  };

  const handleSelectImage = (imageId) => {
    setSelectedImageId(imageId);
  };

  const handleAddSelectedImageToReport = () => {
    const image = findById(customerImages, selectedImageId);
    if (image) {
      dispatch(
        setSectionCellField({
          fieldName: image.dataFieldName,
          dataField: getDataFieldFromName(image.dataFieldName),
          sectionId: selectedGridElements[0].sectionId,
          row: selectedGridElements[0].row,
          column: selectedGridElements[0].col,
        })
      );
      onClose();
      let newRowHeight = 100;
      if (image.height < 100) newRowHeight = image.height;
      dispatch(setSectionRowHeight({ value: newRowHeight }));
    }
  };

  const renderReportCommandSection = (
    <div className="image-store__command-bar">
      <div className="command-bar__item">{t("Select an image to add to the report cell")}</div>
      <div className="command-bar__item">
        {selectedImageId ? (
          <button onClick={handleAddSelectedImageToReport}>{t("Add selected image to report")}</button>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
    </div>
  );

  return (
    <>
      <FileUploader isImage={true} />
      {isSelectionMode && renderReportCommandSection}
      <div className="thumbnail-list">
        {imagesSorted?.map((image) => (
          <div className="flex-row-center" key={image.id}>
            {!isSelectionMode && <ImageBrowserThumbnailInterstice images={customerImages} imageId={image.id} />}
            <ImageBrowserThumbnail
              customerImages={customerImages}
              image={image}
              onDrop={onDrop}
              isSelectionMode={isSelectionMode}
              isSelected={idsAreEqual(selectedImageId, image.id)}
              onSelect={handleSelectImage}
            />
          </div>
        ))}
      </div>
    </>
  );
}

ImageBrowser.propTypes = {
  isSelectionMode: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ImageBrowser;
