import { useState } from "react";
import LoadingModal from "components/global/LoadingModal";
import { exportMatterData } from "api/security";
import { saveAs } from "file-saver";
import { t } from "locale/dictionary";

export default function MatterExport() {
  const [isExporting, setIsExporting] = useState(false);

  const exportData = async () => {
    const blob = await exportMatterData();
    saveAs(blob, "MatterFiles.zip");
    setIsExporting(false);
  };

  const handleExportData = () => {
    setIsExporting(true);
    exportData();
  };

  if (isExporting) {
    return <LoadingModal delay={1} text={"Downloading matter data. Please wait..."} />;
  } else {
    return (
      <div className="margin-0-5-rem-top">
        <button onClick={() => handleExportData()}>{t("Export Matter Data")}</button>
      </div>
    );
  }
}
