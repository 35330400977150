// Customer API calls
import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";
import {
  addBookmark,
  deleteBookmark,
  setBookmarks,
  setCountryGroups,
  setLegalTeam,
  setWidgets,
} from "redux/customerSlice";
import { displayToast } from "utilities/toast";
import { severities } from "utilities/constants";
import { set, sub } from "date-fns";
import { toISOStringWithOffset } from "utilities/dateTime";

export async function loadLegalTeam() {
  const state = store.getState();
  const customerId = state.app.customerId;

  fetchWrapper.get(`customers/${customerId}/LegalTeam`).then((data) => {
    store.dispatch(setLegalTeam(data));
  });
}

/*************************
    BOOKMARKS
**************************/
export async function loadBookmarks() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/bookmarks`);
  store.dispatch(setBookmarks(response));
}

export async function getBookmarkById(id) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/bookmarks/${id}`);
  return response;
}

export async function saveBookmark(matterId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { matterId };
  const response = await fetchWrapper.post(`customers/${customerId}/bookmarks`, body);
  if (response.id) {
    store.dispatch(addBookmark(response));
    displayToast(`Record with ID: ${matterId} has been bookmarked`, severities.INFO);
  }
}

export async function clearBookmark(bookmark) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const confirmedBookmark = await getBookmarkById(bookmark.id);
  if (confirmedBookmark) fetchWrapper.delete(`customers/${customerId}/bookmarks/${bookmark.id}`);
  store.dispatch(deleteBookmark(bookmark));
  displayToast(`Record with ID: ${bookmark.matterId} bookmark has been removed`, severities.INFO);
}

/***************************
 * MY DIARY
 ***************************/

export async function loadMyDiary() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/search/Table?queryType=3`);
  store.dispatch(setBookmarks(response));
}

/******************************
 * MOST RECENTLY USED FILES
 ******************************/
export async function loadMruRecords() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const now = new Date();
  const before = sub(now, { days: 30 }); // HARD CODE 30 DAYS
  const body = {
    maxRows: 100,
    startUtcDate: toISOStringWithOffset(before),
  };
  const response = await fetchWrapper.post(`customers/${customerId}/mattermrus`, body);
  return response;
}

/*************************
    WIDGETS
**************************/
export async function loadWidgets() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/widgets`);
  store.dispatch(setWidgets(response));
}

/*************************
 * COUNTRY GROUPS
 *************************/

export async function loadCountryGroups() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/countrygroups`);
  store.dispatch(setCountryGroups(response));
  return response;
}
