import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { SectionTypeEnum, findSectionGroup, getDisplayNameForField, subqueryParent } from "utilities/reportEditor";
import {
  setGridSectionCellSelected,
  setSectionCellDataProperty,
  setSectionCellField,
  setSectionCellTextDirect,
} from "redux/reportSlice";
import { getDataFieldFromName, getDefaultStyleItem, pushStyle, createCellStyle } from "utilities/reportEditor";
import {
  isGridSelected,
  isColumnSelected,
  isSectionSelected,
  isRowSelected,
  isCellSelected,
  highlightSelected,
} from "utilities/reportEditor";
import { FaChevronCircleDown } from "react-icons/fa";
import { getImageData } from "api/image";
import { idsAreEqual } from "utilities/stringAndArray";

function ReportSectionCell({ section, cell, styleItems, cellPosition, columnStyleItems, row, matterTypeIds, format }) {
  const reportState = useSelector((state) => state.report);
  const selectedGridElements = reportState.selectedGridElements;

  const dispatch = useDispatch();

  const [cellStyle, setCellStyle] = useState(null);
  const [cellSelectedClassName, setCellSelectedClassName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState("");
  const [labelInitial, setLabelInitial] = useState("");
  const [fieldDisplayName, setFieldDisplayName] = useState("");

  const [hasOptionsDropdown, setHasOptionsDropdown] = useState(false);
  const [isDisplayingOptionsPopup, setIsDisplayingOptionsPopup] = useState(false);
  const [fieldNamePrependChar, setFieldNamePrependChar] = useState("");
  const [fieldNameAppendChar, setFieldNameAppendChar] = useState("");
  const [cellOptions, setCellOptions] = useState([]);
  const [selectedCellOption, setSelectedCellOption] = useState(null);
  const [imageData, setImageData] = useState(null);

  const controlId = `cell-at-section-${section.id}-col-${cell.column}-row-${cell.row}`;

  const dropField = (item, target) => {
    const dataField = getDataFieldFromName(item.name);
    //If moving a field from one cell to another, swap the current cell with the location where the dragging cell originated
    if (item.sourceRow) {
      if (target.name) {
        const dataFieldSource = getDataFieldFromName(target.name);
        dispatch(
          setSectionCellField({
            fieldName: target.name,
            dataField: dataFieldSource,
            sectionId: item.sectionId,
            row: item.sourceRow,
            column: item.sourceColumn,
          })
        );
      } else {
        dispatch(
          setSectionCellTextDirect({
            sectionId: item.sectionId,
            cell: { row: item.sourceRow, column: item.sourceColumn },
            value: "",
          })
        );
      }
    }
    // Set the drop cell to the dropped field name and properties
    dispatch(
      setSectionCellField({
        fieldName: item.name,
        dataField: dataField,
        sectionId: target.sectionId,
        row: target.row,
        column: target.column,
      })
    );
  };

  const [{ isDragging }, drag] = useDrag(() => {
    return {
      type: "box",
      item: {
        name: cell.fieldName,
        sectionId: section.id,
        sourceRow: cell.row,
        sourceColumn: cell.column,
        type: "box",
      },
      end: (item, monitor) => {},
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    };
  }, [cell.fieldName, cell.row, cell.column, section.id]);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "box",
      drop(item, monitor) {
        const target = { sectionId: section.id, row: cell.row, column: cell.column, name: cell.fieldName };
        dropField(item, target);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        row: cell.row,
        column: cell.column,
        canDrop: monitor.canDrop(),
      }),
    }),
    [cell.row, cell.column, cell.fieldName, section.id, dropField]
  );

  // Options popup initialisation
  useEffect(() => {
    // Check whether to display field title
    let hasAliasTitle = false;

    // Check whether to display multi-row subquery option
    let isMultiRowSubQuery = false;

    if (idsAreEqual(section.sectionTypeId, SectionTypeEnum.LINE)) {
      if (cell && cell.fieldName) {
        hasAliasTitle = true;
      }
      const sectionGroup = findSectionGroup(format.gridSectionGroup, section.id);
      const cellSubqueryAlias = subqueryParent(cell.fieldName);
      if (
        (cellSubqueryAlias && !sectionGroup.subqueryAlias) ||
        (cellSubqueryAlias && cellSubqueryAlias.name !== sectionGroup.subqueryAlias)
      ) {
        isMultiRowSubQuery = true;
      }
    }

    let fieldNamePrependCharLocal = "{";
    let fieldNameAppendCharLocal = "}";
    const cellOptionsLocal = [];
    cellOptionsLocal.push({
      value: "",
      label: "Default",
    });

    if (hasAliasTitle) {
      cellOptionsLocal.push({
        value: "title",
        label: "Display title",
      });
    }

    if (idsAreEqual(section.sectionTypeId, SectionTypeEnum.LINE)) {
      fieldNameAppendCharLocal = "»";
      fieldNamePrependCharLocal = "«";
    }
    if (isMultiRowSubQuery) {
      fieldNamePrependCharLocal = fieldNamePrependCharLocal + "*";
      fieldNameAppendCharLocal = "*" + fieldNameAppendCharLocal;

      cellOptionsLocal.push({
        value: "multirow",
        label: "Multi-row",
      });
    }
    setFieldNamePrependChar(fieldNamePrependCharLocal);
    setFieldNameAppendChar(fieldNameAppendCharLocal);

    setCellOptions(cellOptionsLocal);

    let selectedCellOptionLocal = "";
    if (cell.displayDataFieldLabel) {
      selectedCellOptionLocal = "title";
    } else if (cell.multiRowSubquery) {
      selectedCellOptionLocal = "multirow";
    }
    setSelectedCellOption(selectedCellOptionLocal);

    setHasOptionsDropdown(isMultiRowSubQuery || hasAliasTitle);
  }, [cell.fieldName]);

  // We have to manually set focus on a textarea when the readonly equivalent (simple HTML text) is clicked on, as the textarea isn't in the DOM on first click
  useEffect(() => {
    if (isEditing) {
      const el = document.getElementById(controlId);
      if (el) el.focus();
    }
  }, [isEditing, controlId]);

  useEffect(() => {
    let labelLocal = "";
    if (cell.fieldName) {
      const fieldDisplayNameLocal = `${fieldNamePrependChar}${getDisplayNameForField(
        cell.fieldName,
        matterTypeIds
      )}${fieldNameAppendChar}`;
      setFieldDisplayName(fieldDisplayNameLocal);
      labelLocal = fieldDisplayNameLocal;
    } else labelLocal = cell.text ?? "";

    setLabel(labelLocal);
    setLabelInitial(labelLocal);
  }, [cell.fieldName, cell.text, matterTypeIds, fieldNamePrependChar, fieldNameAppendChar]);

  useEffect(() => {
    const cellStyleItems = [...styleItems];

    // Get the default style
    let styleItem = getDefaultStyleItem(cell.style);
    if (styleItem) {
      pushStyle(cellStyleItems, "cell", styleItem);
    }

    const cellStyle = createCellStyle(cellStyleItems, cell.column, columnStyleItems, cellPosition);

    if (!cellStyle.verticalAlign) {
      cellStyle.verticalAlign = "top";
    }

    //debugger;

    const isCellSelectedLocal = isCellSelected(selectedGridElements, section.id, cell.row, cell.column, true);

    if (isOver) {
      cellStyle.backgroundColor = "#90ee90";
      setCellSelectedClassName("");
    } else if (
      isCellSelectedLocal ||
      isRowSelected(selectedGridElements, section.id, row.row, true) ||
      isSectionSelected(selectedGridElements, section.id) ||
      isColumnSelected(selectedGridElements, cell.column, true) ||
      isGridSelected(selectedGridElements)
    ) {
      cellStyle.backgroundColor = highlightSelected(cellStyle.backgroundColor);
      setCellSelectedClassName(!cellStyle.backgroundColor ? "table__object--selected" : "");
      setIsEditing(isCellSelectedLocal);
    } else {
      setCellSelectedClassName("");
    }
    setCellStyle(cellStyle);
  }, [
    cell.style,
    cell.column,
    cell.row,
    section.id,
    cellPosition,
    columnStyleItems,
    row.row,
    styleItems,
    selectedGridElements,
    isOver,
  ]);

  const onTextChange = (newValue) => {
    if (cell.fieldName && newValue !== fieldDisplayName) {
      newValue = "";
      dispatch(setSectionCellTextDirect({ sectionId: section.id, cell, value: "" }));
    }
    setLabel(newValue);
  };

  const onInputBlur = (value) => {
    setIsEditing(false);
    if (value !== labelInitial) {
      dispatch(setSectionCellTextDirect({ sectionId: section.id, cell, value }));
    }
    if (hasOptionsDropdown) setIsDisplayingOptionsPopup(false);
  };

  const opacity = isDragging ? 0.4 : 1;

  useEffect(() => {
    const runGetImageData = async () => {
      const imageId = cell.fieldName.split("-")[1];
      const response = await getImageData(imageId);
      setImageData(response);
    };
    if (cell.fieldName?.startsWith("customerImage") && !imageData) runGetImageData();
  }, [cell.fieldName]);

  let renderCellContents = label;
  if (cell.fieldName) {
    if (cell.fieldName.startsWith("customerImage")) {
      const imageStyle = {
        maxWidth: "100%", // Ensures the image does not exceed the column width
        maxHeight: row.rowHeight, // Ensures the image does not exceed the row height
        height: "auto", // Maintains aspect ratio
        width: "auto", // Maintains aspect ratio
        display: "block", // Prevents extra space at the bottom of the image
        margin: "0 auto", // Optionally centers the image in the cell
      };
      renderCellContents = <img src={imageData} alt={cell.fieldName} style={imageStyle} />;
    } else {
      renderCellContents = (
        <span ref={drag} className="section-cell__field-name" style={{ opacity }}>
          {label}
        </span>
      );
    }
  }

  const renderOptionsPopup = (
    <div className="popup-container__popup">
      {cellOptions.map((option) => {
        const classNameOptionBase = "popup__option";
        let classNameOption = classNameOptionBase;
        if (option.value === selectedCellOption.value) classNameOption += ` ${classNameOptionBase}--selected`;
        return (
          <div
            key={option.value}
            className={classNameOption}
            onClick={() => dispatch(setSectionCellDataProperty({ value: option.value }))}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );

  let sectionCellClassNameBase = "body__section-cell";
  let sectionCellClassNameArray = [sectionCellClassNameBase, cellSelectedClassName];
  if (isDisplayingOptionsPopup) sectionCellClassNameArray.push(`${sectionCellClassNameBase}--popup-showing`);
  return (
    <td
      className={sectionCellClassNameArray.join(" ")}
      ref={drop}
      rowSpan={cell.rowSpan ? cell.rowSpan : 1}
      colSpan={cell.colSpan ? cell.colSpan : 1}
      style={cellStyle}
      onMouseUp={(event) => {
        event.stopPropagation();
        if (!isDragging)
          dispatch(
            setGridSectionCellSelected({
              ctrlKey: event.ctrlKey,
              shiftKey: event.shiftKey,
              section: section,
              cell: cell,
            })
          );
      }}
    >
      <div className="section-cell__contents">
        {isEditing ? (
          <input
            id={controlId}
            onChange={(e) => onTextChange(e.target.value)}
            value={label}
            onBlur={(e) => onInputBlur(e.target.value)}
          />
        ) : (
          renderCellContents
        )}
        <div className="contents__popup-container">
          {hasOptionsDropdown && (
            <div
              className="popup-container__open-button"
              onClick={() => setIsDisplayingOptionsPopup(!isDisplayingOptionsPopup)}
            >
              <FaChevronCircleDown />
            </div>
          )}
          {isDisplayingOptionsPopup && renderOptionsPopup}
        </div>
      </div>
    </td>
  );
}

ReportSectionCell.propTypes = {
  format: PropTypes.object,
  cell: PropTypes.object,
  row: PropTypes.object,
  section: PropTypes.object,
  styleItems: PropTypes.array,
  columnStyleItems: PropTypes.array,
  cellPosition: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default ReportSectionCell;
