import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "locale/dictionary";
import Spinner from "./Spinner";

// Component for displaying a loading modal dialog
function LoadingModal({ delay, text }) {
  const [active, setActive] = useState(false);
  const [modalText] = useState(t(text ?? "Loading data. Please wait..."));

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, delay || 100);
  }, [delay]);

  // const hideModal = () => {
  //   clearInterval(timeout);
  //   setActive(false);
  // };

  return (
    active && (
      <div className="modal-mask">
        <div className="loading__popup">
          {/* <div className="loading__header">{t("Something here")}</div> */}
          <div className="loading__body">
            <div className="loading__row">{modalText}</div>
            <div className="loading__row">
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    )
  );
}

LoadingModal.propTypes = {
  delay: PropTypes.number,
  text: PropTypes.string,
};

export default LoadingModal;
