import { createSlice } from "@reduxjs/toolkit";
import { filterOutOnId, findById, findIndexById, idsAreEqual } from "utilities/stringAndArray";

// Redux global state component for customer-specific settings and data
export const securitySlice = createSlice({
  name: "security",
  initialState: {
    customers: [],
    currentCustomer: {},
    currentAccount: {},
    currentUserGroup: {},
  },
  reducers: {
    //CUSTOMERS
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    addCustomer: (state, action) => {
      state.customers = [...state.customers, action.payload];
    },
    updateCustomer: (state, action) => {
      const updatedCustomer = action.payload;
      const accountIndexToUpdate = findIndexById(state.customers, updatedCustomer.id);
      if (accountIndexToUpdate !== -1) {
        state.project.newLinkedMatters[accountIndexToUpdate] = updatedCustomer;
      }
    },
    setCurrentCustomer: (state, action) => {
      state.currentCustomer = action.payload;
    },

    //ACCOUNTS
    setAccounts: (state, action) => {
      state.currentCustomer.accounts = action.payload;
    },
    addAccount: (state, action) => {
      state.currentCustomer.accounts = [...state.currentCustomer.accounts, action.payload];
    },
    modifyCustomerAccount: (state, action) => {
      const { accountId, fieldName, value } = action.payload;
      const accountIndexToUpdate = findIndexById(state.currentCustomer.accounts, accountId);
      if (accountIndexToUpdate !== -1) {
        state.currentCustomer.accounts[accountIndexToUpdate] = {
          ...state.currentCustomer.accounts[accountIndexToUpdate],
          [fieldName]: value,
        };
      }
    },
    removeAccount: (state, action) => {
      state.currentCustomer.accounts = filterOutOnId(state.currentCustomer.accounts, action.payload);
    },
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload;
    },

    //USERS
    addUser: (state, action) => {
      state.currentCustomer.users = [...state.currentCustomer.users, action.payload];
    },
    updateUser: (state, action) => {
      const { userId, fieldName, value } = action.payload;
      const updatedUserIndex = findIndexById(state.currentCustomer.users, userId);

      if (updatedUserIndex !== -1) {
        state.currentCustomer.users[updatedUserIndex] = {
          ...state.currentCustomer.users[updatedUserIndex],
          [fieldName]: value,
        };
      }
    },
    setUsers: (state, action) => {
      state.currentCustomer.users = action.payload;
    },
    removeUserFromAccount: (state, action) => {
      const { userId, accountId } = action.payload;
      if (idsAreEqual(state.currentAccount?.id, accountId)) {
        state.currentAccount.users = filterOutOnId(state.currentAccount.users, userId);
      } else {
        const accountIndex = findIndexById(state.currentCustomer.accounts, accountId);
        state.currentCustomer.accounts[accountIndex].users = filterOutOnId(
          state.currentCustomer.accounts[accountIndex].users,
          userId
        );
      }
    },

    //USER GROUPS
    setUserGroups: (state, action) => {
      state.currentAccount.userGroups = action.payload;
    },
    addUserGroup: (state, action) => {
      state.currentAccount.userGroups.push(action.payload);
    },
    updateCurrentUserGroup: (state, action) => {
      const { fieldName, value } = action.payload;
      state.currentUserGroup[fieldName] = value;
    },
    removeUserGroup: (state, action) => {
      const { userGroupId } = action.payload;
      state.currentAccount.userGroups = filterOutOnId(state.currentAccount.userGroups, userGroupId);
    },
    setCurrentUserGroup: (state, action) => {
      state.currentUserGroup = action.payload;
    },

    //ACCOUNT USERS
    appendUserToAccount: (state, action) => {
      const { accountId, user } = action.payload;
      if (idsAreEqual(accountId, state.currentAccount?.id)) {
        state.currentAccount.users.push(user);
      } else {
        const accountIndex = findIndexById(state.currentCustomer.accounts, accountId);
        state.currentCustomer.accounts[accountIndex].users.push(user);
      }
    },
    updateUsersUserGroup: (state, action) => {
      const { accountId, userId, fieldName, value } = action.payload;
      const accountIndex = findIndexById(state.currentCustomer.accounts, accountId);
      const userIndex = idsAreEqual(state.currentAccount?.id, accountId)
        ? findIndexById(state.currentAccount.users, userId)
        : findIndexById(state.currentCustomer.accounts[accountIndex].users, userId);

      if (idsAreEqual(state.currentAccount?.id, accountId)) {
        state.currentAccount.users[userIndex] = {
          ...state.currentAccount.users[userIndex],
          [fieldName]: value,
        };
      } else {
        state.currentCustomer.accounts[accountIndex].users[userIndex] = {
          ...state.currentCustomer.accounts[accountIndex].users[userIndex],
          [fieldName]: value,
        };
      }
    },

    setAccountUsers: (state, action) => {
      state.currentAccount.users = action.payload;
    },

    //ACCESS RIGHTS
    setMasterAccessPermissionId: (state, action) => {
      state.currentUserGroup.accessRight.permissionId = action.payload.permissionId;
    },
    addAccessRight: (state, action) => {
      state.currentUserGroup.accessRight = action.payload;
    },

    // FILTERS
    addAccessRightFilter: (state, action) => {
      state.currentUserGroup.accessRight.filters.push(action.payload);
    },
    updateAccessRightFilter: (state, action) => {
      const fieldName = action.payload.fieldName;
      const updateFilter = findById(state.currentUserGroup.accessRight.filters, action.payload.id);
      updateFilter[fieldName] = action.payload.value;
      if (action.payload.qualifiedDictionaryEntry) {
        updateFilter.qualifiedDictionary = [
          ...updateFilter.qualifiedDictionary,
          action.payload.qualifiedDictionaryEntry,
        ];
      }

      state.currentUserGroup.accessRight.filters = state.currentUserGroup.accessRight.filters.map((filter) =>
        idsAreEqual(filter.id, updateFilter.id) ? updateFilter : filter
      );
    },
    deleteAccessRightFilter: (state, action) => {
      state.currentUserGroup.accessRight.filters = filterOutOnId(
        state.currentUserGroup.accessRight.filters,
        action.payload
      );
    },

    //MATTER TYPES
    addMatterTypeAccess: (state, action) => {
      state.currentUserGroup.accessRight.matterTypes.push(action.payload);
    },
    updateMatterTypeAccess: (state, action) => {
      const { matterTypeId, fieldName, value } = action.payload;
      const matterTypeIndex = findIndexById(state.currentUserGroup.accessRight.matterTypes, matterTypeId);
      if (matterTypeIndex !== -1) {
        state.currentUserGroup.accessRight.matterTypes[matterTypeIndex] = {
          ...state.currentUserGroup.accessRight.matterTypes[matterTypeIndex],
          [fieldName]: value,
        };
      }
    },
    removeMatterTypeAccess: (state, action) => {
      state.currentUserGroup.accessRight.matterTypes = filterOutOnId(
        state.currentUserGroup.accessRight.matterTypes,
        action.payload
      );
    },

    //MATTER OBJECTS - section overrides
    addMatterObjectAccess: (state, action) => {
      state.currentUserGroup.accessRight.matterObjects.push(action.payload);
    },
    updateMatterObjectAccess: (state, action) => {
      const { matterObjectId, fieldName, value } = action.payload;
      const matterObjectIndex = findIndexById(state.currentUserGroup.accessRight.matterObjects, matterObjectId);
      if (matterObjectIndex !== -1) {
        state.currentUserGroup.accessRight.matterObjects[matterObjectIndex] = {
          ...state.currentUserGroup.accessRight.matterObjects[matterObjectIndex],
          [fieldName]: value,
        };
      }
    },
    removeMatterObjectAccess: (state, action) => {
      state.currentUserGroup.accessRight.matterObjects = filterOutOnId(
        state.currentUserGroup.accessRight.matterObjects,
        action.payload
      );
    },
  },
});

export const {
  setCustomers,
  addCustomer,
  updateCustomer,
  setCurrentCustomer,
  setCurrentAccount,
  setAccounts,
  addAccount,
  modifyCustomerAccount,
  removeAccount,
  addUser,
  updateUser,
  setCurrentUserGroup,
  setUsers,
  setUserGroups,
  addUserGroup,
  removeUserGroup,
  updateUsersUserGroup,
  removeUserFromAccount,
  appendUserToAccount,
  updateCurrentUserGroup,
  setAccountUsers,
  setMasterAccessPermissionId,
  addAccessRight,
  addMatterTypeAccess,
  updateMatterTypeAccess,
  removeMatterTypeAccess,
  addMatterObjectAccess,
  updateMatterObjectAccess,
  removeMatterObjectAccess,
  addAccessRightFilter,
  updateAccessRightFilter,
  deleteAccessRightFilter,
} = securitySlice.actions;

export default securitySlice.reducer;
