import { passwordReset, passwordResetRequest } from "api/user";
import LogoBlue from "components/header/LogoBlue";
import { t } from "locale/dictionary";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function NewUser() {
  const stages = { INITIAL: 0, PASSWORD_FORM: 1, SUCCESS: 2 };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [stage, setStage] = useState(stages.INITIAL);

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onSecurityCodeChange = (e) => {
    // only allow numbers
    if (isNaN(e.target.value)) return;
    setSecurityCode(e.target.value);
  };

  const onRequestSubmit = async () => {
    const response = await passwordResetRequest(email);
    if (response.success) {
      setStage(stages.PASSWORD_FORM);
      setErrorMessage("");
    } else {
      setErrorMessage(t("Password reset request failed: ") + response.error);
    }
  };

  const onFormSubmit = async () => {
    // check that password is filled in
    if (password.length === 0) {
      setErrorMessage(t("Please enter a password"));
      return;
    }
    // check that passwords match
    if (password !== confirmPassword) {
      setErrorMessage(t("Passwords do not match"));
      return;
    }
    // check that security code is filled in
    if (securityCode.length === 0) {
      setErrorMessage(t("Please enter a security code"));
      return;
    }

    const response = await passwordReset(email, password, securityCode);
    if (response.success) {
      setStage(stages.SUCCESS);
      setErrorMessage("");
    } else {
      setErrorMessage(t("Password reset failed: ") + response.error);
    }
  };

  const renderFormInitial = (
    <>
      <p>{t("You or your system administrator has requested that you set or reset your password.")}</p>
      <p>{t("For your security, a verification code will be sent to your email address.")}</p>
      <button onClick={onRequestSubmit}>{t("Click here to email the verification code")}</button>
    </>
  );

  const renderFormPassword = (
    <>
      <p>
        {t(
          "Please enter and confirm a new password, along with the 6-digit security code that came with the email just sent to you."
        )}
      </p>
      <div className="password-reset__label">{t("New Password")}</div>
      <div className="password-reset__input">
        <input value={password} type="password" onChange={onPasswordChange} />
      </div>
      <div className="password-reset__label">{t("Confirm Password")}</div>
      <div className="password-reset__input">
        <input value={confirmPassword} type="password" onChange={onConfirmPasswordChange} />
      </div>
      <div className="password-reset__label">{t("Security Code")}</div>
      <div className="password-reset__input">
        <input value={securityCode} onChange={onSecurityCodeChange} />
      </div>
      <button onClick={onFormSubmit}>{t("Submit")}</button>
    </>
  );

  const renderSuccess = (
    <div className="password-reset__success">
      {t("Password successfully reset!")} <Link to="/">{t("Click here to login")}</Link>
    </div>
  );

  let renderForm = renderFormInitial;
  if (stage === stages.PASSWORD_FORM) renderForm = renderFormPassword;
  else if (stage === stages.SUCCESS) renderForm = renderSuccess;

  return (
    <>
      <div className="header">
        <LogoBlue />
      </div>
      <div className="password-reset-container">
        <div className="password-reset">
          <div className="password-reset__title">
            {t("Password reset request for")} {email}
          </div>
          {renderForm}
          {errorMessage.length > 0 && <div className="password-reset__error">{errorMessage}</div>}
        </div>
      </div>
    </>
  );
}
