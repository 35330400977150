import { createSlice } from "@reduxjs/toolkit";
import { filterOutOnId, findIndexById } from "utilities/stringAndArray";

// Redux global state component for common project properties and functions
export const projectSlice = createSlice({
  name: "project",
  initialState: {
    draftProjects: [],
    matterTypesDisplay: [],
    project: null,
    newCompanyPopupRecordId: null,
    collapsedSections: [],
  },
  reducers: {
    // DRAFT PROJECTS LIST

    deleteProject: (state, action) => {
      state.draftProjects = filterOutOnId(state.draftProjects, action.payload);
      state.project = null;
    },
    setDraftProjects: (state, action) => {
      state.draftProjects = action.payload;
    },
    setMatterTypesDisplay: (state, action) => {
      state.matterTypesDisplay = action.payload;
    },

    // ACTIVE PROJECT
    setProject: (state, action) => {
      state.project = action.payload;
    },
    updateProjectField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.project[fieldName] = value;
    },

    // LEGAL TEAM
    addLegalTeamContactLink: (state, action) => {
      state.project.legalTeamContactLinks.push(action.payload);
    },
    updateLegalTeamContactLink: (state, action) => {
      const { legalTeamContactId, changedFieldName, value } = action.payload;
      const legalTeamContactIndex = findIndexById(state.project.legalTeamContactLinks, legalTeamContactId);
      if (legalTeamContactIndex !== -1) {
        state.project.legalTeamContactLinks[legalTeamContactIndex] = {
          ...state.project.legalTeamContactLinks[legalTeamContactIndex],
          [changedFieldName]: value,
        };
      }
    },
    deleteLegalTeamContactLink: (state, action) => {
      state.project.legalTeamContactLinks = filterOutOnId(state.project.legalTeamContactLinks, action.payload);
    },

    // NEW COMPANY POPUP
    setNewCompanyPopupRecordId: (state, action) => {
      state.newCompanyPopupRecordId = action.payload;
    },

    // COLLAPSED SECTIONS
    clearCollapsedSections: (state) => {
      state.collapsedSections = [];
    },
    toggleCollapsedSection: (state, action) => {
      const sectionIndex = state.collapsedSections.indexOf(action.payload);
      if (sectionIndex === -1) {
        state.collapsedSections.push(action.payload);
      } else {
        state.collapsedSections.splice(sectionIndex, 1);
      }
    },
  },
});

export const {
  deleteProject,
  setDraftProjects,
  setMatterTypesDisplay,
  setProject,
  updateProjectField,
  updateLegalTeamContactLink,
  addLegalTeamContactLink,
  deleteLegalTeamContactLink,
  setNewCompanyPopupRecordId,
  toggleCollapsedSection,
  clearCollapsedSections,
} = projectSlice.actions;

export default projectSlice.reducer;
