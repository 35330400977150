import PropTypes from "prop-types";
import ToolbarElement from "../format-grid/toolbar/ToolbarElement";

function ToolbarButton({ style, disabled, onClick, tooltipText, tooltipDirection, children }) {
  return (
    <ToolbarElement tooltipText={tooltipText} tooltipDirection={tooltipDirection}>
      <div
        className="toolbar__button"
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
      >
        {children}
      </div>
    </ToolbarElement>
  );
}

ToolbarButton.propTypes = {
  style: PropTypes.object,
  tooltipText: PropTypes.string,
  tooltipDirection: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ToolbarButton;
