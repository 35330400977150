import PropTypes from "prop-types";
import { FaTimesCircle } from "react-icons/fa";
import { dndTypes } from "utilities/constants";
import { useDrag } from "react-dnd";
import { useEffect, useState } from "react";
import { deleteCustomerImage, getImageData, saveImageFieldChanges } from "api/image";
import Delete from "components/global/Delete";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";

// Container Component for the image thumbnail within the global image browser
function ImageBrowserThumbnail({ customerImages, image, onDrop, isSelectionMode, isSelected, onSelect }) {
  const [imageData, setImageData] = useState(null);
  const [description, setDescription] = useState(image.description ?? "");
  const [isChanged, setIsChanged] = useState(false);

  /* eslint-disable */
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: dndTypes.THUMBNAIL,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (dropResult) onDrop(image.id, dropResult.droppedBeforeImageId);
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [customerImages]
  );

  useEffect(() => {
    setDescription(image.description ?? "");
  }, [image.description]);

  useEffect(() => {
    const runGetImageData = async () => {
      const data = await getImageData(image.id);
      setImageData(data);
    };
    runGetImageData();
  }, [image]);

  const onDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription === description && isChanged) setIsChanged(false);
    if (newDescription !== description && !isChanged) setIsChanged(true);

    setDescription(newDescription);
  };

  const saveDescriptionChanges = () => {
    if (description !== image.description) {
      saveImageFieldChanges(image.id, "description", description, image.description);
    }
    setIsChanged(false);
  };

  const secondaryInfoText = `${t("Filename")}: ${image.filename}, ${t("File size")}: ${image.byteCount} ${t("bytes")}`;

  // set className modifier to --selection-mode if isSelectionMode
  const thumbnailClassNameBase = "thumbnail";
  let thumbnailClassName = thumbnailClassNameBase;
  if (isSelectionMode) thumbnailClassName += ` ${thumbnailClassNameBase}--selection-mode`;
  if (isSelected) thumbnailClassName += ` ${thumbnailClassNameBase}--selected`;

  const getDraggingRefIfAny = () => (isSelectionMode ? null : drag);

  return (
    <div className={thumbnailClassName} onClick={() => onSelect(image.id)}>
      <div ref={getDraggingRefIfAny()} className="thumbnail__body">
        <img src={imageData} alt={image.filename} />
        {/* {image.matterImage_SortIndex ?? "NULL"} */}
        <div className="body__overlay">
          <div className="overlay__items">
            <div className="overlay__item">
              <Delete
                message={`Remove "${image.description}" from the list`}
                onConfirm={() => deleteCustomerImage(image.id)}
              />
              <FaTimesCircle />
            </div>
          </div>
        </div>
      </div>
      <Tooltip content={secondaryInfoText}>
        <div className="thumbnail__description">
          <input
            value={description}
            onChange={onDescriptionChange}
            onBlur={saveDescriptionChanges}
            autoComplete="off"
          />
        </div>
      </Tooltip>
    </div>
  );
}

ImageBrowserThumbnail.propTypes = {
  customerImages: PropTypes.array,
  image: PropTypes.object,
  onDrop: PropTypes.func,
  isSelectionMode: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default ImageBrowserThumbnail;
