import BorderQuarter from "./BorderQuarter";

function Border({ type, onSelect }) {
  let topLeftQuarter;

  switch (type) {
    case "outside":
      topLeftQuarter = <BorderQuarter topWidth={2} leftWidth={2} bottomWidth={0} rightWidth={0} />;
      break;
    case "all":
      topLeftQuarter = <BorderQuarter topWidth={2} leftWidth={2} bottomWidth={1} rightWidth={1} />;
      break;
    case "top":
      topLeftQuarter = <BorderQuarter topWidth={2} leftWidth={0} bottomWidth={0} rightWidth={0} />;
      break;
    case "bottom":
    case "right":
    case "none":
      topLeftQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={0} rightWidth={0} />;
      break;
    case "left":
      topLeftQuarter = <BorderQuarter topWidth={0} leftWidth={2} bottomWidth={0} rightWidth={0} />;
      break;
    default:
  }

  let topRightQuarter;
  switch (type) {
    case "outside":
      topRightQuarter = <BorderQuarter topWidth={2} leftWidth={0} bottomWidth={0} rightWidth={2} />;
      break;
    case "all":
      topRightQuarter = <BorderQuarter topWidth={2} leftWidth={1} bottomWidth={1} rightWidth={2} />;
      break;
    case "top":
      topRightQuarter = <BorderQuarter topWidth={2} leftWidth={0} bottomWidth={0} rightWidth={0} />;
      break;
    case "right":
      topRightQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={0} rightWidth={2} />;
      break;
    case "bottom":
    case "left":
    case "none":
      topRightQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={0} rightWidth={0} />;
      break;

    default:
  }

  let bottomLeftQuarter;
  switch (type) {
    case "outside":
      bottomLeftQuarter = <BorderQuarter topWidth={0} leftWidth={2} bottomWidth={2} rightWidth={0} />;
      break;
    case "all":
      bottomLeftQuarter = <BorderQuarter topWidth={1} leftWidth={2} bottomWidth={2} rightWidth={1} />;
      break;
    case "top":
    case "right":
    case "none":
      bottomLeftQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={0} rightWidth={0} />;
      break;
    case "bottom":
      bottomLeftQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={2} rightWidth={0} />;
      break;
    case "left":
      bottomLeftQuarter = <BorderQuarter topWidth={0} leftWidth={2} bottomWidth={0} rightWidth={0} />;
      break;
    default:
  }

  let bottomRightQuarter;
  switch (type) {
    case "outside":
      bottomRightQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={2} rightWidth={2} />;
      break;
    case "all":
      bottomRightQuarter = <BorderQuarter topWidth={1} leftWidth={1} bottomWidth={2} rightWidth={2} />;
      break;
    case "top":
    case "left":
    case "none":
      bottomRightQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={0} rightWidth={0} />;
      break;
    case "bottom":
      bottomRightQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={2} rightWidth={0} />;
      break;
    case "right":
      bottomRightQuarter = <BorderQuarter topWidth={0} leftWidth={0} bottomWidth={0} rightWidth={2} />;
      break;

    default:
  }

  return (
    <div
      className="grid__item"
      onClick={() => {
        if (onSelect) {
          onSelect(type);
        }
      }}
    >
      {topLeftQuarter}
      {topRightQuarter}
      {bottomLeftQuarter}
      {bottomRightQuarter}
    </div>
  );
}

export default Border;
