import { copyMatterRecord, saveMatterListChanges, saveNewChildObject, updateMatterOnCopy } from "api/matter";
import Checkbox from "components/global/Checkbox";
import PopupHeader from "components/global/PopupHeader";
import SuperSelect from "components/global/SuperSelect";
import Tooltip from "components/global/Tooltip";
import useComponentVisible from "components/global/useComponentVisible";
import { t } from "locale/dictionary";
import { useEffect } from "react";
import { useState } from "react";
import { MdFileCopy } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsShowingFullScreenModal } from "redux/appSlice";
import { labelTypes, severities } from "utilities/constants";
import { getDataTypeForField } from "utilities/datafield";
import { fetchWrapper } from "utilities/fetchWrapper";
import { getMatterLinkTypes } from "utilities/links";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { defaultSectionOrder } from "utilities/sectionDefinitions";
import { sectionDefinitions } from "utilities/sectionDefinitions";
import { findById, idsAreEqual } from "utilities/stringAndArray";
import { displayToast } from "utilities/toast";

function RecordCopyModal({ record = {} }) {
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recordToCopy, setRecordToCopy] = useState({});
  const [refModal, showModal, setShowModal] = useComponentVisible(false);
  const [matterTypes, setMatterTypes] = useState([]);
  const [selectedOption, setSelectedOption] = useState({ id: 0, displayValue: "" });
  const [currentMatterType, setCurrentMatterType] = useState({ id: 0, displayValue: "" });
  const [currentSectionDefinitions, setCurrentSectionDefinitions] = useState([]);

  //configure default checked and readonly fields
  const defaultCheckedFields = {
    "Core Details": { readOnly: true, defaultChecked: true },
    Images: { readOnly: false, defaultChecked: true },
    Categories: { readOnly: false, defaultChecked: true },
    "Linked Matters": { readOnly: false, defaultChecked: true },
    "Legal Team": { readOnly: false, defaultChecked: true },
    "Classes and goods/services": { readOnly: false, defaultChecked: true },
    Documents: { readOnly: false, defaultChecked: false },
    "Company Links": { readOnly: false, defaultChecked: true },
    Actions: { readOnly: false, defaultChecked: true },
  };

  useEffect(() => {
    //creates copy of record and adds toCopy & readOnly bools to each section based on defaultCheckedFields
    if (record.sections !== undefined) {
      createCheckboxValues(defaultCheckedFields);
      const currentSections = defaultSectionOrder.find((section) =>
        idsAreEqual(section.matterTypeId, record.matter_MatterTypeId)
      );
      const currentDefinitions = currentSections.sectionOrder.map((sectionId) =>
        findById(sectionDefinitions, sectionId)
      );
      setCurrentSectionDefinitions(currentDefinitions);
    }
  }, [record]);

  useEffect(() => {
    dispatch(setIsShowingFullScreenModal(showModal));
  }, [showModal]);

  //gets matterTypes and sets them to state for use in dropdown
  useEffect(() => {
    const types = getAllLookupValuesForSource("MatterTypes");
    const currentType = findById(types, record.matter_MatterTypeId);
    //if current matter type is company only copy to company type otherwise copy to any type except company.
    let filteredTypes = types.filter((type) => {
      if (idsAreEqual(currentType.id, matterTypes.COMPANY)) {
        return idsAreEqual(type.id, currentType.id);
      } else {
        return !idsAreEqual(type.id, matterTypes.COMPANY);
      }
    });
    setMatterTypes(filteredTypes);
    setSelectedOption(findById(filteredTypes, record.matter_MatterTypeId));
    setCurrentMatterType(currentType);
  }, [record.matter_MatterTypeId]);

  //sets checkbox values based on fields passed in
  const createCheckboxValues = (checkedFields) => {
    let newRecord = JSON.parse(JSON.stringify(record));
    const newSections = newRecord.sections.map((section) => {
      const newSection = section;
      if (newSection.sectionName in checkedFields) {
        newSection.toCopy = checkedFields[newSection.sectionName].defaultChecked;
        newSection.isReadOnly = checkedFields[newSection.sectionName].isReadOnly;
      } else {
        newSection.toCopy = false;
        newSection.isReadOnly = false;
      }

      return newSection;
    });

    newRecord.sections = newSections;
    setRecordToCopy(newRecord);
  };

  //handles toggling of checkbox to set copy state
  const toggleSectionCheckbox = (index) => {
    if (recordToCopy.sections[index].isReadOnly) return;
    let newRecord = { ...recordToCopy };
    newRecord.sections[index].toCopy = !newRecord.sections[index].toCopy;
    setRecordToCopy(newRecord);
  };

  //creates a connected matter to original copied record
  const createConnectedMatter = async (newRecord) => {
    const availableLinkTypes = getMatterLinkTypes(record.matter_MatterTypeId, newRecord.matter_MatterTypeId);
    let newLinkObject = {
      recordId: newRecord.id,
      matterLink_MatterId: record.id,
      matterLink_LinkTypeId:
        availableLinkTypes.length === 1 ||
        (availableLinkTypes.length === 2 && idsAreEqual(availableLinkTypes[1].id, 2600))
          ? availableLinkTypes[0].id
          : 2600,
    };
    //update linked matters with link to previous record
    await saveNewChildObject(newRecord.id, null, "links", newLinkObject, true);
  };

  const createCopyRecordBody = () => {
    const body = {
      matter_MatterTypeId: selectedOption.id,
      matter_AccountId: recordToCopy.matter_AccountId,
      references: recordToCopy.references,
    };

    //copies sections selected from checkboxes
    const sectionsToCopy = recordToCopy.sections.filter((section) => section.toCopy);

    sectionsToCopy.forEach((section) => {
      if (section.tableName) {
        body[section.tableName] = recordToCopy[section.tableName];
      } else {
        section.fields.forEach((field) => {
          body[field.fieldName] = recordToCopy[field.fieldName];
        });
      }
    });

    //prefix for new copy name
    body.matter_Name = "Copy - " + body.matter_Name;

    //only company links type Client, Agent and Owner.
    const allowedLinkIds = [100, 600, 1900];
    body.companyLinks = body?.companyLinks?.filter((link) =>
      allowedLinkIds.includes(link.matterCompanyLink_LinkTypeId)
    );

    if (!idsAreEqual(selectedOption.id, record.matter_MatterTypeId)) {
      // filter only form sections
      const formSections = currentSectionDefinitions.filter((def) => !def.tableName);
      //filter only form sections with dropdowns
      formSections.forEach((section) => {
        const fields = section.fieldList.filter((field) => {
          if (getDataTypeForField(field.fieldName) === labelTypes.LOOKUP || field.isListType) {
            return field;
          }
        });
        //null dropdown values
        if (fields.length > 0) {
          fields.forEach((field) => {
            body[field.fieldName] = null;
          });
        }
      });
    }

    return body;
  };

  const createListValues = async (response) => {
    //copy list values accross to new record
    if (recordToCopy.lists.length === 0) return;
    for (let index = 0; index < recordToCopy.lists.length; index++) {
      const list = recordToCopy.lists[index];
      await saveMatterListChanges(response.id, list.matterList_ListTypeId, list.matterList_ListValues, [], true);
    }
  };

  // handles copy logic - will redirect to new entry and display a toast notification
  const copyRecord = async () => {
    const body = createCopyRecordBody();

    try {
      const response = await copyMatterRecord(body);
      if (response.id) {
        if (!idsAreEqual(selectedOption.id, record.matter_MatterTypeId)) {
          await createConnectedMatter(response);
        }
        await createListValues(response);
        await updateMatterOnCopy();
        navigate(`/matter/${response.id}`);
        displayToast("Successfully copied record", severities.INFO);
        setShowModal(false);
      }
    } catch (error) {
      displayToast("Error copying report", severities.ERROR);
      console.error(error);
    }
  };

  //when drop down changes this sets readonly values based on target matter type.
  const handleMatterTypeSelectChange = (id) => {
    const sectionNames = currentSectionDefinitions.reduce((a, currentValue) => [...a, currentValue.name], []);

    setSelectedOption({
      id: id,
      displayValue: findById(matterTypes, id).displayValue,
    });
    const checkedFields = { ...defaultCheckedFields };
    recordToCopy.sections.forEach((section) => {
      if (sectionNames.includes(section.sectionName) === false) {
        checkedFields[section.sectionName] = { readOnly: true, toCopy: false };
      }
    });
    if (!idsAreEqual(id, record.matter_MatterTypeId)) {
      checkedFields["Linked Matters"] = { readOnly: true, toCopy: false };
      checkedFields["Actions"] = { readOnly: true, toCopy: false };
    }
    createCheckboxValues(checkedFields);
  };

  return (
    <>
      <div className="record-header__action-icon" onClick={() => setShowModal(true)}>
        <Tooltip content="Copy">
          <MdFileCopy />
        </Tooltip>
      </div>
      {showModal ? (
        <div className="modal-mask">
          <div ref={refModal} className="copy-modal__selection-popup">
            <PopupHeader title={t("Copy Record")} onClose={() => setShowModal(false)} />
            <div className="copy-modal__selection-body">
              <div className="copy-modal__matter-type">
                {matterTypes.length > 1 ? (
                  <>
                    <span className="copy-modal__matter-type--span">
                      Copy from {currentMatterType.displayValue} to:{" "}
                    </span>
                    <div className="copy-modal__matter-type--dropdown">
                      <SuperSelect
                        options={matterTypes}
                        id={"copy-modal-matter-type"}
                        selectedOptionId={selectedOption?.id}
                        onChange={handleMatterTypeSelectChange}
                        tabIndex={0}
                        placeholderText={selectedOption?.displayValue}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              <div className="copy-modal__selection-row">{t("Please select categories to copy:")}</div>
              <div className="copy-modal__checkbox-container">
                {recordToCopy.sections.map((section, index) => {
                  const checkboxLabel =
                    section.sectionName === "Company Links"
                      ? `${section.sectionName} (Client, Agent & Owner only)`
                      : section.sectionName;

                  return (
                    <div key={section.sectionName}>
                      <div className="copy-modal__checkbox-item">
                        <Checkbox
                          id={section.sectionName}
                          label={checkboxLabel}
                          onCheckChange={() => toggleSectionCheckbox(index)}
                          isChecked={section.toCopy}
                          isDisabled={section.isReadOnly}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="copy-modal__selection-row">
                <button
                  disabled={!recordToCopy?.sections.length > 0}
                  onClick={() => {
                    copyRecord();
                  }}
                  className="copy-modal__selection-confirm"
                >
                  {t("Create Copy")}
                </button>
                <button onClick={() => setShowModal(false)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
export default RecordCopyModal;
