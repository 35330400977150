import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  setGridSelected,
  setGridColumnSelected,
  formatInsert,
  setColumnWidth,
  formatInsertColumnSingleDirect,
} from "redux/reportSlice";
import { isGridSelected, isColumnSelected, isAnySectionCellSelected } from "utilities/reportEditor";
import { FaPlusCircle, FaArrowsAltH } from "react-icons/fa";

function ReportColumnHeader({ format, onDragChange }) {
  const reportState = useSelector((state) => state.report);
  const selectedGridElements = reportState.selectedGridElements;

  const dispatch = useDispatch();

  const [rootSelectedClassName, setRootSelectedClassName] = useState("");
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [dragState, setDragState] = useState({ isDragging: false, column: null, startWidth: null, newWidth: null });

  useEffect(() => {
    setRootSelectedClassName(isGridSelected(selectedGridElements) ? "table__object--selected" : "");
  }, [selectedGridElements]);

  // MOUSE HANDLERS

  const handleDividerInsertColumn = (event, colIndex) => {
    dispatch(formatInsertColumnSingleDirect(colIndex));
  };

  const handleColBodyClick = (event, col) => {
    if (!dragState.isDragging) {
      dispatch(setGridColumnSelected({ ctrlKey: event.ctrlKey, shiftKey: event.shiftKey, column: col.column }));
    }
  };

  const handleDividerDragMouseDown = (event, col) => {
    setDragState({ isDragging: true, column: col.column, startWidth: col.width, newWidth: col.width });
    setStartPos({ x: event.clientX, y: event.clientY });
  };

  const handleHeaderRowMouseMove = (event) => {
    if (dragState.isDragging) {
      const diffX = event.clientX - startPos.x;
      const newWidth = dragState.startWidth + diffX;
      if (newWidth < 10) return;
      const newDragState = { ...dragState, newWidth };
      setDragState(newDragState);
      onDragChange(newDragState);
    }
  };

  const handleHeaderRowMouseUp = () => {
    if (dragState.isDragging) {
      dispatch(setColumnWidth({ value: dragState.newWidth }));
      setDragState({ isDragging: false, column: null, startWidth: null, newWidth: null });
    }
  };

  // RENDERING

  // All but the last column on the grid. Inserts are straightforward since they are always before the column,
  // but dragging the divider is offset by 1 column since the divider is dragging the width of the column to the left.
  const renderBeforeDivider = (col, prevColIsSelected) => {
    const prevCol = format.gridColumns.find((c) => c.column === col.column - 1);
    return prevColIsSelected ? (
      <div
        className="column-cell__resize column-cell__resize--before"
        onMouseDown={(e) => handleDividerDragMouseDown(e, prevCol)}
      >
        <FaArrowsAltH />
      </div>
    ) : (
      <div
        className="column-cell__insert column-cell__insert--before"
        onClick={(e) => handleDividerInsertColumn(e, col.column)}
      >
        <FaPlusCircle />
      </div>
    );
  };

  const renderColBody = (col) => {
    return (
      <div className="column-cell__body" onClick={(e) => handleColBodyClick(e, col)}>
        {col.column}
      </div>
    );
  };

  // Rightmost column only. Resizing is straightforward since one is dragging this column. But inserts are after the column.
  const renderAfterDivider = (col, colIsSelected, index) => {
    const cols = format.gridColumns;
    if (index !== cols.length - 1) return null;
    return colIsSelected ? (
      <div
        className="column-cell__resize column-cell__resize--after"
        onMouseDown={(e) => handleDividerDragMouseDown(e, col, true)}
      >
        <FaArrowsAltH />
      </div>
    ) : (
      <div
        className="column-cell__insert column-cell__insert--after"
        onClick={(e) => handleDividerInsertColumn(e, col.column + 1)}
      >
        <FaPlusCircle />
      </div>
    );
  };

  const gridColumnsRender = format.gridColumns.map((col, index) => {
    const colIsSelected = isColumnSelected(selectedGridElements, col.column, true);
    const prevColIsSelected = isColumnSelected(selectedGridElements, col.column - 1, true);
    const columnSelectedClassName =
      isGridSelected(selectedGridElements) ||
      colIsSelected ||
      isAnySectionCellSelected(selectedGridElements, col.column, true)
        ? "table__object--selected"
        : "";

    return (
      <td key={col.column} className={["header__column-cell", columnSelectedClassName].join(" ")}>
        {renderColBody(col)}
        {renderBeforeDivider(col, prevColIsSelected)}
        {renderAfterDivider(col, colIsSelected, index)}
      </td>
    );
  });

  return (
    // We handle the mouse drag related events on the row, not the cells, otherwise the mouse events don't fire if they go outside the cell.
    <tr onMouseMove={handleHeaderRowMouseMove} onMouseUp={handleHeaderRowMouseUp}>
      <td
        colSpan={2}
        className={["header__root-cell", rootSelectedClassName].join(" ")}
        onClick={(event) => {
          event.stopPropagation();
          dispatch(setGridSelected({ ctrlKey: event.ctrlKey }));
        }}
      ></td>
      {gridColumnsRender}
    </tr>
  );
}

ReportColumnHeader.propTypes = {
  format: PropTypes.object,
  onDragChange: PropTypes.func,
};

export default ReportColumnHeader;
