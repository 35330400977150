import checkbox from "images/checkbox64x64.png";
import checkboxUnchecked from "images/checkbox_unchecked64x64.png";
import checkboxDisabled from "images/checkbox-disabled-64x64.png";
import checkboxUncheckedDisabled from "images/checkbox_unchecked-disabled-64x64.png";
import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";

// A replacement component for the standard input checkbox
function Checkbox({
  isChecked: isCheckedExternal,
  label,
  onCheckChange,
  tabIndex,
  isDisabled = false,
  position = "left",
}) {
  const [isCheckedInternal, setIsCheckedInternal] = useState(isCheckedExternal);

  useEffect(() => {
    setIsCheckedInternal(isCheckedExternal);
  }, [isCheckedExternal]);

  let checkboxProps;
  if (isCheckedInternal && isDisabled) checkboxProps = { img: checkboxDisabled, alt: "checkbox-checked-disabled" };
  if (isCheckedInternal && !isDisabled) checkboxProps = { img: checkbox, alt: "checkbox-checked" };
  if (!isCheckedInternal && isDisabled)
    checkboxProps = { img: checkboxUncheckedDisabled, alt: "checkbox-unchecked-disabled" };
  if (!isCheckedInternal && !isDisabled) checkboxProps = { img: checkboxUnchecked, alt: "checkbox-unchecked" };

  const labelClassNameBase = "checkbox__label";
  let labelClassName = labelClassNameBase;
  if (isDisabled) labelClassName += ` ${labelClassNameBase}--disabled`;

  const handleCheckChange = () => {
    if (isDisabled) return;
    setIsCheckedInternal(!isCheckedInternal);
    onCheckChange && onCheckChange(!isCheckedInternal);
  };

  const handleClick = () => {
    handleCheckChange();
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      handleCheckChange();
    }
  };

  return (
    <div
      className={`checkbox checkbox--${position} clickable`}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
    >
      <img className="checkbox__check" src={checkboxProps.img} alt={checkboxProps.alt} />
      <span className={labelClassName}>{label}</span>
    </div>
  );
}

Checkbox.propTypes = {
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  onCheckChange: PropTypes.func,
};

export default Checkbox;
