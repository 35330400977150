import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { idsAreEqual } from "utilities/stringAndArray";

//modular horizontal radio button control
export default function RadioHorizontal({
  groupName,
  onRadioChange,
  optionsArray,
  defaultSelected,
  isButtonStyle,
  isDisabled = false,
}) {
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    //get default selected radio
    if (optionsArray?.length > 0) {
      if (defaultSelected) setSelectedOption(defaultSelected);
    }
  }, [defaultSelected, optionsArray]);

  const handleRadioChange = (event) => {
    const value = parseInt(event.target.value);
    //set local radio state
    setSelectedOption(value);
    // value returned to parent
    onRadioChange(value);
  };

  const className = isButtonStyle ? "button-radio-group" : "radio-group";

  //MAIN RENDER
  return (
    <div className={className}>
      {optionsArray?.length > 0 &&
        optionsArray.map((option) => (
          <div className="radio-item" key={option.id}>
            <input
              id={option.id}
              type="radio"
              name={groupName}
              value={option?.id}
              checked={idsAreEqual(option?.id, selectedOption)}
              onChange={(e) => handleRadioChange(e)}
              disabled={isDisabled}
            />
            <label htmlFor={option.id}>{option?.displayValue}</label>
          </div>
        ))}
    </div>
  );
}
RadioHorizontal.propTypes = {
  groupName: PropTypes.string,
  optionsArray: PropTypes.array,
  onRadioChange: PropTypes.func,
  defaultSelected: PropTypes.number,
  isButtonStyle: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
