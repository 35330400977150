import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "components/global/DatePicker";
import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { parseISO } from "date-fns";
import { toISOStringWithOffset } from "utilities/dateTime";
import { getInputDate } from "utilities/simpleSearch";

function SuggestedFieldPopupDate({ filter, operator, onOperatorChange, onIsApplicableChange, onValueChange }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;

  const [startDate, setStartDate] = useState(getInputDate(null));
  const [endDate, setEndDate] = useState(getInputDate(null, 2));

  useEffect(() => {
    if (filter && filter !== undefined) {
      setStartDate(parseISO(filter.valueArray[0]));
      if (filter.valueArray.length === 2) setEndDate(parseISO(filter.valueArray[1]));
    }
  }, [filter]);

  //simple search with date value
  useEffect(() => {
    startDate && changeDate(startDate);
  }, []);

  //console.log("SuggestedFieldPopupDate, startDate: " + startDate + ", endDate: " + endDate);

  const checkIsApplicable = (startDateLocal, endDateLocal, operatorLocal) => {
    if (!operatorLocal) operatorLocal = operator;
    if (operatorLocal === "between") {
      onIsApplicableChange(startDateLocal !== null && endDateLocal !== null);
    } else {
      onIsApplicableChange(startDateLocal !== null);
    }
  };

  const changeDate = (startDateLocal, endDateLocal) => {
    checkIsApplicable(startDateLocal, endDateLocal);
    let valueArray = [];
    if (operator !== "isempty" && operator !== "isnotempty") {
      valueArray.push(toISOStringWithOffset(startDateLocal));
      if (endDateLocal) valueArray.push(toISOStringWithOffset(endDateLocal));
    }
    onValueChange(valueArray);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    changeDate(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    changeDate(startDate, date);
  };

  const handleOperatorChange = (operatorLocal) => {
    checkIsApplicable(startDate, endDate, operatorLocal);
    onOperatorChange(operatorLocal);
  };

  return (
    <>
      <div className="suggested-field-popup__operator">
        {/* <FaAngleDown /> */}
        <select value={operator} onChange={(e) => handleOperatorChange(e.target.value)} tabIndex={0}>
          <option value="=">On</option>
          <option value="<>">Not On</option>
          <option value="<=">Before</option>
          <option value=">=">After</option>
          <option value="between">Between</option>
          {!simpleSearch.queryType.isExternal && (
            <>
              <option value="isempty">Empty</option>
              <option value="isnotempty">Not Empty</option>
            </>
          )}
        </select>
      </div>
      {operator !== "isempty" && operator !== "isnotempty" && (
        <DatePicker
          selected={startDate}
          onDateChange={handleStartDateChange}
          isSimpleSearchParent={true}
          tabIndex={0}
        />
      )}
      {operator === "between" && (
        <>
          <p>{t("and")}</p>
          <DatePicker selected={endDate} onDateChange={handleEndDateChange} isSimpleSearchParent={true} tabIndex={0} />
        </>
      )}
    </>
  );
}

SuggestedFieldPopupDate.propTypes = {
  filter: PropTypes.object,
  operator: PropTypes.string,
  onOperatorChange: PropTypes.func,
  onIsApplicableChange: PropTypes.func,
  onValueChange: PropTypes.func,
};

export default SuggestedFieldPopupDate;
