import { t } from "locale/dictionary";
import React, { useEffect, useState } from "react";
import { getBatchImageData, updateDraftRecord } from "api/batchCreate";
import ProjectSection from "../ProjectSection";
import { projectStatuses } from "utilities/constants";
import Checkbox from "components/global/Checkbox";
import { getCountryNameFromIsoCode } from "utilities/countries";
import { getLookupValueBySource } from "utilities/lookup";
import { updateDraftRecordFieldValue } from "redux/batchCreateSlice";
import { useDispatch, useSelector } from "react-redux";
import { getDisplayNameForField } from "utilities/datafield";
import { findById } from "utilities/stringAndArray";

export default function BatchCreateSummary() {
  const batchCreateState = useSelector((state) => state.batchCreate);
  const project = batchCreateState?.project;

  const dispatch = useDispatch();

  const [imagesData, setImagesData] = useState([]);

  // TODO: This is a kludge - far easier for the backend to return the base64 data with the project.images data set
  useEffect(() => {
    const imagesDataLocal = [...imagesData];
    const runGetImageData = async (image) => {
      let base64data = null;
      base64data = await getBatchImageData(project.id, image.id, true);
      imagesDataLocal.push({ id: image.id, base64data });
    };
    project.images && project.images.forEach((image) => !image.base64data && runGetImageData(image));
    setImagesData(imagesDataLocal);
  }, [project.images]);

  const handleFieldValueChange = (draftRecordId, value, fieldName) => {
    updateDraftRecord(project.id, draftRecordId, fieldName, value);
    dispatch(updateDraftRecordFieldValue({ draftRecordId, value, fieldName }));
  };

  const renderHeaderRow = () => {
    const renderHeaderCell = (columnName) => {
      return <th key={columnName}>{t(columnName)}</th>;
    };

    return (
      <thead>
        <tr>
          {renderHeaderCell("Include in Batch")}
          {renderHeaderCell("Matter Type")}
          {renderHeaderCell(getDisplayNameForField("matter_Name", [project?.project_MatterTypeId]))}
          {renderHeaderCell("File Reference")}
          {renderHeaderCell("Country")}
          {renderHeaderCell("Record Type")}
          {renderHeaderCell("Classes")}
          {renderHeaderCell("Company Links")}
          {renderHeaderCell("Status")}
          {renderHeaderCell("Images")}
        </tr>
      </thead>
    );
  };

  const renderRecordRow = (record) => {
    return (
      <tr key={record.id}>
        <td>
          {project.project_Status !== projectStatuses.MATTER_GENERATED ? (
            <div className="checkbox-container">
              <Checkbox
                isChecked={record.createRecord}
                onCheckChange={() => handleFieldValueChange(record.id, !record.createRecord, "createRecord")}
              />
            </div>
          ) : (
            <span>{record.createRecord ? "Yes" : "No"}</span>
          )}
        </td>
        <td>{getLookupValueBySource("MatterTypes", project.project_MatterTypeId)}</td>
        <td>{project.project_MatterName}</td>
        <td>{record.fileReference}</td>
        <td>{getCountryNameFromIsoCode(record.countryId)}</td>
        <td>{getLookupValueBySource("RecordTypes", record.recordTypeId)}</td>
        <td>{record.goods.map((g) => g.good).join(", ")}</td>
        <td>{project.companyLinks.map((cl) => cl.name).join(", ")}</td>
        <td>{getLookupValueBySource("ProjectStatuses", project.project_Status)}</td>
        <td>
          {project.images.map((i) => (
            <img
              key={i.id}
              className="data-grid__img"
              src={findById(imagesData, i.id)?.base64data}
              alt="matter thumbnail"
            />
          ))}
        </td>
      </tr>
    );
  };

  return (
    (project?.project_Status === projectStatuses.TEMPLATE_GENERATED ||
      project?.project_Status === projectStatuses.PREVIEW_GENERATED ||
      project?.project_Status === projectStatuses.MATTER_GENERATED) &&
    project?.draftRecords?.length > 0 && (
      <ProjectSection title="Summary">
        <div className="section__summary">
          <table className="table__inner">
            {renderHeaderRow()}
            <tbody>{project?.draftRecords?.map((record) => renderRecordRow(record))}</tbody>
          </table>
        </div>
      </ProjectSection>
    )
  );
}
