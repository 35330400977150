import { createBatchProject } from "api/project";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { setAvailableCountries } from "redux/batchCreateSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountriesAndCodes } from "utilities/countries";
import { matterTypes, projectTypes } from "utilities/constants";
import PopupHeader from "components/global/PopupHeader";
import { clearResults } from "redux/searchResultsSlice";
import { useLocation } from "react-router-dom";
import { setMatterTypesDisplay } from "redux/projectSlice";
import { findById, idsAreEqual } from "utilities/stringAndArray";
import { getAllLookupValuesForSource } from "utilities/lookup";

function ProjectCreatePopup({ onClose }) {
  const searchResults = useSelector((state) => state.searchResults).currentSearch;
  //const batchCreateState = useSelector((state) => state.batchCreate);
  //const batchImportState = useSelector((state) => state.batchImport);
  const projectState = useSelector((state) => state.project);
  const countryGroups = useSelector((state) => state.customer).countryGroups;
  const appState = useSelector((state) => state.app);
  const lookupState = useSelector((state) => state.lookup);
  const locale = useSelector((state) => state.locale);
  const allMatterTypes = appState.allMatterTypes;
  const dispatch = useDispatch();
  const location = useLocation();

  const [projectName, setProjectName] = useState("");
  const [matterTypesFiltered, setMatterTypesFiltered] = useState([]);
  const [selectedMatterTypeId, setSelectedMatterTypeId] = useState(null);
  const [selectedDataSourceId, setSelectedDataSourceId] = useState(null);

  const [allCountriesAndCodes, setAllCountriesAndCodes] = useState([]);

  const accountId = appState.accountId;
  const projectTypeId = location.pathname.includes("/batch-create")
    ? projectTypes.BATCH_CREATE
    : projectTypes.BATCH_IMPORT;
  const project = projectState.project;
  const matterTypesDisplay = projectState.matterTypesDisplay;
  //const draftProjects = batchCreateState.draftProjects;

  const batchImportSources = getAllLookupValuesForSource("ProjectImportSourceTypes");
  const batchImportSourceOptions = batchImportSources?.map((bis) => ({
    id: bis.id,
    displayValue: locale.translations[bis.translationCode],
  }));

  useEffect(() => {
    if (searchResults?.results) dispatch(clearResults());
  }, []);

  useEffect(() => {
    setSelectedMatterTypeId(project?.project_MatterTypeId ?? selectedMatterTypeId);
    if (project?.project_MatterTypeId && countryGroups) setCountryFilters(project.project_MatterTypeId);
  }, [project?.project_MatterTypeId]);

  useEffect(() => {
    setProjectName(project?.project_ProjectName ?? projectName);
  }, [project?.project_ProjectName]);

  useEffect(() => {
    const allCountriesLookup = getAllCountriesAndCodes();
    if (allCountriesLookup?.length > 0) {
      setAllCountriesAndCodes(
        allCountriesLookup.map((country) => ({
          id: country.id,
          displayValue: country.displayName,
        }))
      );
    }
    if (lookupState?.global?.length > 0) {
      const matterTypesFilteredLocal = allMatterTypes?.filter(
        (mt) =>
          idsAreEqual(mt.id, matterTypes.DESIGNS) ||
          idsAreEqual(mt.id, matterTypes.PATENT) ||
          idsAreEqual(mt.id, matterTypes.TRADEMARK)
      );
      const matterTypesFilteredDisplayLocal = matterTypesFilteredLocal.map((mt) => ({
        id: mt.id,
        displayValue: locale.translations[mt.translationCode],
      }));

      dispatch(setMatterTypesDisplay(matterTypesFilteredDisplayLocal));
      setMatterTypesFiltered(matterTypesFilteredLocal);
    }
  }, [lookupState, allMatterTypes]);

  const setCountryFilters = (matterTypeId) => {
    const matterType = findById(matterTypesFiltered, matterTypeId);
    const countryGroupForMatterType = matterType && findById(countryGroups, matterType.countryGroupId);
    let availableCountries = countryGroupForMatterType
      ? countryGroupForMatterType.memberCountries.map((id) => {
          const country = findById(allCountriesAndCodes, id);
          return { id: country.id, displayValue: country.displayValue };
        })
      : [];
    if (availableCountries.length === 0) availableCountries = allCountriesAndCodes;
    dispatch(setAvailableCountries(availableCountries));
  };

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleMatterTypeChange = (matterTypeId) => {
    setSelectedMatterTypeId(matterTypeId);
  };

  const handleCreateProject = async () => {
    createBatchProject(accountId, selectedMatterTypeId, projectName, projectTypeId, selectedDataSourceId);
    countryGroups && setCountryFilters(selectedMatterTypeId);
    dispatch(clearResults());
    onClose();
  };

  const renderPopupProjectNameRow = (
    <div className="project-popup__row">
      <div className="project-popup__label">{t("Project Name")}</div>
      <input className="project-popup__input" type="text" value={projectName} onChange={handleProjectNameChange} />
    </div>
  );

  const renderPopupMatterTypeRow = (
    <div className="project-popup__row">
      <div className="project-popup__label">{t("Matter Type")}</div>
      <div className="project-popup__select">
        {matterTypesDisplay && (
          <SuperSelect
            id="matter-type"
            options={matterTypesDisplay}
            selectedOptionId={selectedMatterTypeId}
            onChange={handleMatterTypeChange}
          />
        )}
      </div>
    </div>
  );

  const renderPopupDataSourceRow = (
    <div className="project-popup__row">
      <div className="project-popup__label">{t("Data Source")}</div>
      <div className="project-popup__select">
        {batchImportSourceOptions && (
          <SuperSelect
            id="batch-import-source"
            options={batchImportSourceOptions}
            selectedOptionId={selectedDataSourceId}
            onChange={(id) => setSelectedDataSourceId(id)}
          />
        )}
      </div>
    </div>
  );

  const renderPopupButtonRow = projectName?.length > 2 &&
    selectedMatterTypeId &&
    (projectTypeId !== projectTypes.BATCH_IMPORT || selectedDataSourceId) && (
      <div className="project-popup__button-row">
        <button onClick={handleCreateProject}>{t("Create Project")}</button>
        <button onClick={onClose}>{t("Cancel")}</button>
      </div>
    );

  return (
    <div className="modal-mask">
      <div className="project__popup">
        <PopupHeader onClose={onClose} title={t("Create New Project")} />
        <div className="project-popup__body">
          {renderPopupProjectNameRow}
          {projectName?.length > 0 && renderPopupMatterTypeRow}
          {idsAreEqual(projectTypeId, projectTypes.BATCH_IMPORT) && selectedMatterTypeId && renderPopupDataSourceRow}
          {renderPopupButtonRow}
        </div>
      </div>
    </div>
  );
}

ProjectCreatePopup.propTypes = {
  onClose: PropTypes.func,
};

export default ProjectCreatePopup;
