import Paginator from "components/global/Paginator";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { pageChange } from "redux/searchResultsSlice";

// Component for the Search Results Footer
export default function SearchResultsFooter() {
  const searchResults = useSelector((state) => state.searchResults).currentSearch;
  const dispatch = useDispatch();

  const paginationState = searchResults.pagination;

  const handlePageFirstClick = () => {
    dispatch(pageChange(0));
  };

  const handlePagePrevClick = () => {
    dispatch(pageChange(paginationState.currentPage - 1));
  };

  const handlePageNextClick = () => {
    dispatch(pageChange(paginationState.currentPage + 1));
  };

  const handlePageLastClick = () => {
    dispatch(pageChange(paginationState.totalPages - 1));
  };

  return (
    paginationState.totalPages > 0 && (
      <div className="search-results__footer">
        <Paginator
          totalPages={paginationState.totalPages}
          currentPage={paginationState.currentPage}
          onPageFirstClick={handlePageFirstClick}
          onPagePrevClick={handlePagePrevClick}
          onPageNextClick={handlePageNextClick}
          onPageLastClick={handlePageLastClick}
        />
      </div>
    )
  );
}
