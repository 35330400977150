import PropTypes from "prop-types";
import PivotAxes from "./pivot-fields/PivotAxes";

function FormatPivot({ format, matterTypeIds }) {
  return <PivotAxes format={format} matterTypeIds={matterTypeIds} />;
}

FormatPivot.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default FormatPivot;
