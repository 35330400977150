import { createSlice } from "@reduxjs/toolkit";

// Redux global state component for general locale settings
export const localeSlice = createSlice({
  name: "locale",
  initialState: { selectedLocale: "en-GB", translations: [], shortTranslations: [] },
  reducers: {
    changeLocale: (state, action) => {
      state.selectedLocale = action.payload;
    },
    setInitialLanguageStore: (state) => {
      state.selectedLocale = navigator.language ?? "en-GB";
    },
    setTranslations: (state, action) => {
      state.translations = action.payload;
    },
    addTranslation: (state, action) => {
      state.translations = { ...state.translations, ...action.payload };
    },
    updateTranslation: (state, action) => {
      state.translations[action.payload.translationCode] = action.payload.newValue;
    },
    removeTranslation: (state, action) => {
      delete state.translations[action.payload];
    },
    setShortTranslations: (state, action) => {
      state.shortTranslations = action.payload;
    },
  },
});

export const {
  changeLocale,
  setInitialLanguageStore,
  setTranslations,
  addTranslation,
  updateTranslation,
  removeTranslation,
  setShortTranslations,
} = localeSlice.actions;

export default localeSlice.reducer;
