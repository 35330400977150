import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addRecord } from "redux/reportSlice";
import { getReportById } from "api/report";
import LoadingModal from "components/global/LoadingModal";
import { FormatTypes } from "utilities/constants";
import ReportEditorBasic from "./ReportEditorBasic";
import ReportEditor from "./ReportEditor";

function ReportEditorContainer() {
  const appState = useSelector((state) => state.app);
  const matterState = useSelector((state) => state.matter);
  const reportState = useSelector((state) => state.report);

  const dispatch = useDispatch();

  const { param } = useParams();

  useEffect(() => {
    const loadReport = async () => {
      const report = await getReportById(param);
      dispatch(addRecord({ report, selected: true, replace: true }));
    };

    if (appState.customerId && matterState.requiredMatterData) {
      loadReport();
    }
  }, [param, appState.customerId, matterState.requiredMatterData, dispatch]);

  const [report, setReport] = useState(null);
  const [format, setFormat] = useState(null);
  //const [reportId, setReportId] = useState(null);

  useEffect(() => {
    const report = reportState.records[reportState.selectedRecordIndex];
    if (report) {
      setFormat(report.queryObject);
      setReport(report);
    }
  }, [reportState]);

  console.log("Container format: ", format);

  let content;
  if (format) {
    content =
      format.formatType === FormatTypes.LIST ? (
        <ReportEditorBasic report={report} />
      ) : (
        <ReportEditor report={report} format={format} />
      );
  } else {
    content = <LoadingModal />;
  }

  return content;
}

export default ReportEditorContainer;
