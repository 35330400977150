import { t } from "locale/dictionary";
import React from "react";
import ProjectSection from "../ProjectSection";
import SuperSelect from "components/global/SuperSelect";
import { useState } from "react";
import { getCountryNameFromIsoCode } from "utilities/countries";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCountry, removeCountry, updateCountryFieldValue } from "redux/batchCreateSlice";
import Switch from "components/global/Switch";
import { createBatchCountry, refreshBatchSummary, removeBatchCountry, updateBatchCountry } from "api/batchCreate";
import { projectStatuses, superSelectOperationTypes } from "utilities/constants";
import { findById, idsAreEqual } from "utilities/stringAndArray";

function BatchCreateJurisdiction() {
  const batchCreateState = useSelector((state) => state.batchCreate);
  const countryGroups = useSelector((state) => state.customer).countryGroups;
  const localState = useSelector((state) => state.locale);

  const project = batchCreateState.project;

  const dispatch = useDispatch();

  const [countryGroupsDisplay, setCountryGroupsDisplay] = useState([]);
  const [countriesSorted, setCountriesSorted] = useState([]);

  useEffect(() => {
    function compareByCountryDisplayName(a, b) {
      let aValue = getCountryNameFromIsoCode(a.countryId);
      let bValue = getCountryNameFromIsoCode(b.countryId);

      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    }

    if (!project.countries) return;
    const sortedCountries = [...project.countries].sort(compareByCountryDisplayName);
    setCountriesSorted(sortedCountries);

    if (project?.project_Status !== projectStatuses.MATTER_GENERATED) refreshBatchSummary(project);
  }, [project?.countries]);

  useEffect(() => {
    if (!project?.project_MatterTypeId) return;
    let displayColumnsLocal = [];
    displayColumnsLocal.push({ id: 0, name: t("National") });

    countryGroups?.forEach((cg) => {
      if (cg.isIpRegistry && (cg.matterTypes.length === 0 || cg.matterTypes.includes(project.project_MatterTypeId))) {
        displayColumnsLocal.push({ id: cg.id, name: localState.translations[cg.translationCode] ?? cg.code });
      }
    });
    const multiClassCountryGroup = countryGroups?.find((cg) => cg.code === "MULTICLASS");
    displayColumnsLocal.push({
      id: multiClassCountryGroup.id,
      name: localState.translations[multiClassCountryGroup.translationCode],
    });
    setCountryGroupsDisplay(displayColumnsLocal);
  }, [project]);

  const handleChangeSelectedCountryList = async (countryId, op) => {
    if (op === superSelectOperationTypes.ADD) {
      const newCountry = await createBatchCountry(project.id, { countryId });
      dispatch(addCountry(newCountry));
    } else {
      const country = project.countries.find((c) => idsAreEqual(c.countryId, countryId));
      removeBatchCountry(project.id, country.id);
      dispatch(removeCountry(country.id));
    }
  };

  const handleFieldValueChange = (countryId, value, fieldName) => {
    //console.log("handleFieldValueChange: ", countryId, value, fieldName);
    updateBatchCountry(project.id, countryId, fieldName, value);
    dispatch(updateCountryFieldValue({ countryId, value, fieldName }));

    // If WIPO selected and WIPO not on countries list, add WIPO to countries list
    if (value === 25 && !project.countries.find((c) => idsAreEqual(c.countryId, 920))) {
      handleChangeSelectedCountryList(920, superSelectOperationTypes.ADD);
    }
    // If ARIPO selected and ARIPO not on countries list, add ARIPO to countries list
    else if (value === 62 && !project.countries.find((c) => idsAreEqual(c.countryId, 900))) {
      handleChangeSelectedCountryList(900, superSelectOperationTypes.ADD);
    }
  };

  const renderHeaderRow = () => {
    return (
      <thead>
        <tr>
          <th>{t("Selected Country")}</th>
          {countryGroupsDisplay.map((column) => (
            <th key={column.id}>{column.name}</th>
          ))}
        </tr>
      </thead>
    );
  };

  const renderCountryRow = (country) => {
    const fieldBooleanCell = (displayCountryGroupId) => {
      const countryGroup = findById(countryGroups, displayCountryGroupId);
      let isPresent = false;
      let isChecked = false;
      if (idsAreEqual(displayCountryGroupId, 0)) {
        isPresent = true;
        isChecked = !country?.countryGroupId;
      } else {
        isPresent = findById(countryGroup?.memberCountries, country.countryId);
        isChecked = idsAreEqual(country?.countryGroupId, displayCountryGroupId);
      }

      if (!isPresent) return "";
      return idsAreEqual(displayCountryGroupId, 22) ? (
        // Multiclass column handled differently
        project.project_Status === projectStatuses.MATTER_GENERATED ? (
          <div className="margin-0-5-rem">{country.multiClassFiling ? "Yes" : "No"}</div>
        ) : (
          <Switch
            isOn={country.multiClassFiling}
            onSwitchChange={(e) => handleFieldValueChange(country.id, !country.multiClassFiling, "multiClassFiling")}
          />
        )
      ) : project.project_Status === projectStatuses.MATTER_GENERATED ? (
        <div className="margin-0-5-rem">{isChecked ? "Yes" : "No"}</div>
      ) : (
        <input
          type="radio"
          name={`radio-${country.id}`}
          id={`${country.id}-${displayCountryGroupId}`}
          checked={isChecked}
          onChange={() => handleFieldValueChange(country.id, displayCountryGroupId, "countryGroupId")}
        />
      );
    };

    return (
      <tr key={country.id}>
        <td>{getCountryNameFromIsoCode(country.countryId)}</td>
        {countryGroupsDisplay.map((countryGroup) => (
          <td key={countryGroup?.id}>{fieldBooleanCell(countryGroup?.id)}</td>
        ))}
      </tr>
    );
  };

  const renderCountriesTable = () => {
    return (
      <table className="jurisdiction__table">
        {renderHeaderRow()}
        <tbody>{countriesSorted.map((country) => renderCountryRow(country))}</tbody>
      </table>
    );
  };

  const renderCountriesSelect = () => {
    return (
      <div className="jurisdiction__countries-row">
        <div className="jurisdiction__countries-label">{t("Countries")}:</div>
        <div className="jurisdiction__countries-select">
          <SuperSelect
            id="countries-jurisdiction-select"
            placeholderText="Begin Typing to Search or Select Countries from the list..."
            isMultiValued={true}
            options={availableCountries}
            selectedOptionIds={selectedOptionIds}
            onChangeMulti={handleChangeSelectedCountryList}
          />
        </div>
      </div>
    );
  };

  // Unsure why we have to make a copy of what should be a read-only array, but this works and a direct reference does not
  const availableCountries = [...batchCreateState?.availableCountries];
  const selectedOptionIds = countriesSorted.map((c) => c.countryId);

  return (
    <ProjectSection title="Jurisdiction">
      <div className="section__jurisdiction">
        {project?.project_Status !== projectStatuses.MATTER_GENERATED && renderCountriesSelect()}
        {project?.countries?.length > 0 && renderCountriesTable()}
      </div>
    </ProjectSection>
  );
}

BatchCreateJurisdiction.propTypes = {};

export default BatchCreateJurisdiction;
