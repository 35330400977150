import PropTypes from "prop-types";

// Container Component for the display of Company details (address, phone etc.) readonly concatenated label
function PanelFieldCompanyAddress({ keyProps }) {
  const rd = keyProps.rowData;
  const addressArray = [];
  rd.matterCompanyLink_Address1 &&
    rd.matterCompanyLink_Address1.length > 0 &&
    addressArray.push(rd.matterCompanyLink_Address1);
  rd.matterCompanyLink_Address2 &&
    rd.matterCompanyLink_Address2.length > 0 &&
    addressArray.push(rd.matterCompanyLink_Address2);
  rd.matterCompanyLink_Address3 &&
    rd.matterCompanyLink_Address3.length > 0 &&
    addressArray.push(rd.matterCompanyLink_Address3);

  const cityStatePostcodeArray = [];
  rd.matterCompanyLink_City &&
    rd.matterCompanyLink_City.length > 0 &&
    cityStatePostcodeArray.push(rd.matterCompanyLink_City);
  rd.matterCompanyLink_State &&
    rd.matterCompanyLink_State.length > 0 &&
    cityStatePostcodeArray.push(rd.matterCompanyLink_State);
  rd.matterCompanyLink_PostalCode &&
    rd.matterCompanyLink_PostalCode.length > 0 &&
    cityStatePostcodeArray.push(rd.matterCompanyLink_PostalCode);

  return (
    <>
      <div>{addressArray.join(", ")}</div>
      <div>{cityStatePostcodeArray.join(", ")}</div>
    </>
  );
}

PanelFieldCompanyAddress.propTypes = {
  keyProps: PropTypes.object,
};

export default PanelFieldCompanyAddress;
