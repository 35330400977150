import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSectionRowCount, setSectionStripe } from "redux/reportSlice";
import PropTypes from "prop-types";
import { SectionTypeEnum, findSection } from "utilities/reportEditor";
import InputNumberPicker from "./InputNumberPicker";
import { idsAreEqual } from "utilities/stringAndArray";

function ToolbarSection({ format, selected }) {
  const dispatch = useDispatch();

  const [rowCount, setRowCount] = useState(null);
  const [displayStripe, setDisplayStripe] = useState(null);
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    let rowCount = null;
    let stripe = null;
    let displayStripe = false;

    for (let i = 0; i < selected.length; i++) {
      const section = findSection(format.gridSectionGroup, selected[i].sectionId);
      if (i === 0) {
        rowCount = section.rowCount;
      } else if (rowCount !== section.rowCount) {
        rowCount = null;
        break;
      }
      if (i === 0 && idsAreEqual(section.sectionTypeId, SectionTypeEnum.LINE)) {
        stripe = section.stripe;
        displayStripe = true;
      } else {
        displayStripe = false;
      }
    }
    setRowCount(rowCount);
    setDisplayStripe(displayStripe);
    setStripe(stripe);
  }, [selected, format.gridSectionGroup]);

  return (
    <>
      <InputNumberPicker
        label="Rows"
        value={rowCount}
        onChange={(value) => dispatch(setSectionRowCount({ value: value }))}
        min={1}
        max={99}
        numbers={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 24]}
        tooltipText="Select number of rows"
      />
      {displayStripe && (
        <div className="toolbar__input">
          <span style={{ margin: "0rem 1rem" }}>Stripe</span>
          <div style={{ height: "26px", display: "inline-block" }}>
            <input
              style={{
                verticalAlign: "middle",
                height: "18px",
                margin: "0px 4px 3px 4px",
              }}
              type="checkbox"
              name="stripe"
              checked={stripe}
              onChange={(event) => dispatch(setSectionStripe({ value: event.target.checked }))}
            />
          </div>
        </div>
      )}
    </>
  );
}

ToolbarSection.propTypes = {
  format: PropTypes.object,
  selected: PropTypes.arrayOf(PropTypes.object),
};

export default ToolbarSection;
