import { ReportOutputTypes } from "utilities/reportEditor";
import { FaDesktop, FaFileExcel, FaFileWord } from "react-icons/fa";

const OutputPicker = ({ inputRef, setValue, show, setShow }) => {
  const style = { display: show ? "block" : "none" };

  return (
    <div className="toolbar__number-picker" style={style} ref={inputRef}>
      <div
        key={ReportOutputTypes.DEFAULT}
        onClick={() => {
          setValue(ReportOutputTypes.DEFAULT);
          setShow(false);
        }}
      >
        <FaDesktop className="button__icon" />
      </div>
      <div
        key={ReportOutputTypes.EXCEL}
        onClick={() => {
          setValue(ReportOutputTypes.EXCEL);
          setShow(false);
        }}
      >
        <FaFileExcel className="button__icon" />
      </div>
      <div
        key={ReportOutputTypes.WORD}
        onClick={() => {
          setValue(ReportOutputTypes.WORD);
          setShow(false);
        }}
      >
        <FaFileWord className="button__icon" />
      </div>
    </div>
  );
};

export default OutputPicker;
