import { useEffect, useState } from "react";
import { getAllCountriesAndCodes, getCountryNamesFromIsoList, isMatterTypeMultiCountry } from "utilities/countries";
import { saveMatterCountries } from "api/matter";
import PropTypes from "prop-types";
import SuperSelect from "components/global/SuperSelect";
import { sectionTypes, superSelectOperationTypes } from "utilities/constants";
import { filterOutOnId, formatControlId } from "utilities/stringAndArray";

// Container Component for the display of country information within a matter data panel
function PanelFieldCountries({ keyProps, canEdit }) {
  const [displayName, setDisplayName] = useState(null);
  const [displayValue, setDisplayValue] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [selectedOptionIds, setSelectedOptionIds] = useState(null);

  const isMultiSelect = keyProps.record ? isMatterTypeMultiCountry(keyProps.record.matter_MatterTypeId) : false;

  useEffect(() => {
    const countryDisplay = getCountryNamesFromIsoList(keyProps.data);
    setDisplayName(countryDisplay.displayName);
    setDisplayValue(countryDisplay.displayValue);
    if (isMultiSelect) setSelectedOptionIds(keyProps.data);
    else setSelectedOptionId(keyProps.data && keyProps.data[0]);
  }, [keyProps.data]);

  const controlId = formatControlId(keyProps);
  const allCountriesAndCodes = getAllCountriesAndCodes(keyProps.field.fieldName, keyProps.record.matter_MatterTypeId);
  let lookupOptions = allCountriesAndCodes.map((country) => ({ id: country.id, displayValue: country.displayName }));
  lookupOptions = [{ id: null, displayValue: "" }, ...lookupOptions];

  const onChangeSelectedCountryList = (id, op) => {
    let ids = keyProps.data && Array.isArray(keyProps.data) ? [...keyProps.data] : [];
    if (isMultiSelect) {
      if (op === superSelectOperationTypes.ADD) {
        ids.push(id);
      } else {
        ids = filterOutOnId(ids, id);
      }
    } else {
      ids = id === null ? [] : [id];
    }
    saveMatterCountries(keyProps.record.id, ids, keyProps.data);
    document.activeElement.blur();
    setSelectedOptionId(null);
  };

  const renderValueContents = canEdit
    ? allCountriesAndCodes && (
        <SuperSelect
          id={controlId}
          options={lookupOptions}
          selectedOptionId={selectedOptionId}
          selectedOptionIds={selectedOptionIds}
          isMultiValued={isMultiSelect}
          onChange={onChangeSelectedCountryList}
          onChangeMulti={onChangeSelectedCountryList}
          tabIndex={0}
        />
      )
    : displayValue; //getLookupValueForField(keyProps.field.fieldName, props.value);

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <>{renderValueContents}</>
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value">{renderValueContents}</div>
    </>
  );
}

PanelFieldCountries.propTypes = {
  keyProps: PropTypes.object,
  canEdit: PropTypes.bool,
};

export default PanelFieldCountries;
