// Boolean utility functions

export function stringToBoolean(inputString) {
  try {
    switch (String(inputString).toLowerCase()) {
      case "yes":
      case "true":
      case "1":
        return true;
      case "no":
      case "false":
      case "0":
        return false;
      default:
        return NaN;
    }
  } catch {
    return NaN;
  }
}
