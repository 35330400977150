import PropTypes from "prop-types";
import FormatListItem from "./FormatListItem";
import { useDrop } from "react-dnd";
import { removeListField } from "redux/reportSlice";
import { useDispatch } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";

function FormatList({ format, matterTypeIds }) {
  const dispatch = useDispatch();

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "self",
      drop(item) {
        dispatch(removeListField({ id: item.field.id }));
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    []
  );

  const renderGrid = () => {
    let grid = new Array(10).fill(null);

    return grid.map((item, i) => {
      const field = format?.listColumns[i];
      const key = field?.id ?? "u" + i;

      return <FormatListItem key={key} field={field} listIndex={i} matterTypeIds={matterTypeIds} maxEntries={10} />;
    });
  };

  const trashButtonClassBase = "quick-report-grid__remove-button";
  let trashButtonClass = canDrop ? `${trashButtonClassBase}--active` : trashButtonClassBase;
  if (isOver) trashButtonClass = canDrop ? `${trashButtonClassBase}--drop` : trashButtonClassBase;

  return (
    <div className="quick-report-grid-container">
      <div className="quick-report-grid">{renderGrid()}</div>
      <button className={trashButtonClass} ref={drop}>
        <FaTrashAlt />
        &nbsp; Remove
      </button>
    </div>
  );
}

FormatList.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.arrayOf(PropTypes.number),
};

export default FormatList;
