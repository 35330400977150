import PropTypes from "prop-types";
import { setBorderStyle } from "utilities/reportShared";
import { setOutputStyle } from "utilities/reportOutput";

function ColumnGroup({ output }) {
  return (
    <colgroup>
      {output.columns.map((col) => {
        const columnProp = output.columnProperties[col.p];
        const columnStyle = {
          width: columnProp.width + "px",
        };

        if (output.style !== null) {
          const tableStyle = output.styles.formats[output.style];
          const border = output.styles.borders[tableStyle.borderId];

          if (col.c > 1) {
            setBorderStyle(columnStyle, border.innerVerticalBorder, "borderLeft");
          }
          if (col.c < output.columnCount) {
            setBorderStyle(columnStyle, border.innerVerticalBorder, "borderRight");
          }
        }

        if (columnProp.style !== null) {
          setOutputStyle(output, columnStyle, columnProp.style, "column");
        }

        return <col key={col.c} style={columnStyle} />;
      })}
    </colgroup>
  );
}

ColumnGroup.propTypes = {
  output: PropTypes.object,
};

export default ColumnGroup;
