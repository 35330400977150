import { createUserGroup, deleteUserGroup } from "api/security";
import Delete from "components/global/Delete";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { labelTypes } from "utilities/constants";
import { idsAreEqual } from "utilities/stringAndArray";
import SecurityColumnHeader from "./SecurityColumnHeader";
import { useEffect } from "react";

export default function AccountUserGroups() {
  const securityState = useSelector((state) => state.security);
  const params = useParams();

  const [userGroups, setUserGroups] = useState([]);
  const [groupNameInput, setGroupNameInput] = useState("");
  const [isAddingNewUserGroup, setIsAddingNewUserGroup] = useState(false);
  const [sortParams, setSortParams] = useState({
    sortCol: "name",
    sortAsc: true,
  });

  const customerId = parseInt(params.customerId);
  const accountId = parseInt(params.accountId);
  const currentAccount = securityState.currentAccount;

  useEffect(() => {
    currentAccount && setUserGroups([...currentAccount.userGroups]);
  }, [currentAccount?.userGroups]);

  const handleAddNewUserGroup = () => {
    createUserGroup(customerId, accountId, groupNameInput);
    setIsAddingNewUserGroup(false);
  };

  const handleUpdateSortParams = (sortedData, newSortParams) => {
    setUserGroups(sortedData);
    setSortParams(newSortParams);
  };

  const renderUserGroupTableBody =
    userGroups?.length > 0 ? (
      userGroups.map((userGroup) => (
        <tr className="security__table__body-row" key={userGroup.id}>
          <td className="security__table__body-row-cell">
            <Link to={`/customers/${customerId}/accounts/${accountId}/usergroups/${userGroup.id}`}>
              {userGroup.name}
            </Link>
          </td>
          <td className="security__table__body-row-cell">{userGroup.userCount ?? 0}</td>
          <td className="security__table__body-row-cell ">
            {currentAccount.users.filter((user) => idsAreEqual(user.userGroupId, userGroup.id)).length === 0 ? (
              <Delete
                message={"Delete this User Group"}
                onConfirm={() => deleteUserGroup(customerId, currentAccount.id, userGroup.id)}
              ></Delete>
            ) : (
              <Tooltip content="Cannot delete this user group as it contains users" direction="right">
                <FaTimes className="security__table__body-row-cell--disabled" />
              </Tooltip>
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td className="security__table__body__no-data">{t("No User Groups")}</td>
      </tr>
    );

  const renderUserGroupTable = (
    <>
      <div className="security__section-title">{t("User Groups")}</div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <SecurityColumnHeader
                fieldName="name"
                fieldType={labelTypes.STRING}
                displayName="Name"
                tableData={userGroups}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="userCount"
                fieldType={labelTypes.DECIMAL}
                displayName="Count"
                tableData={userGroups}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <th className="security__table__header-row-cell">{t("Delete")}</th>
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderUserGroupTableBody}
            {isAddingNewUserGroup && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell ">
                  <input
                    className="modal__input"
                    id="addGroupNameInput"
                    type="text"
                    onChange={(e) => setGroupNameInput(e.target.value)}
                  ></input>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isAddingNewUserGroup ? (
        <button className="flex-row-center" onClick={() => setIsAddingNewUserGroup(true)}>
          {t("Add User Group")}
        </button>
      ) : (
        <button onClick={handleAddNewUserGroup}>{t("Confirm")}</button>
      )}
    </>
  );

  //MAIN RENDER
  return <>{renderUserGroupTable}</>;
}
