import PropTypes from "prop-types";
import ReportTable from "./table/ReportTable";

function FormatGrid({ format, matterTypeIds }) {
  return <ReportTable format={format} matterTypeIds={matterTypeIds} />;
}

FormatGrid.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default FormatGrid;
