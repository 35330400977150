import PropTypes from "prop-types";
import D3JSBar from "./d3js/D3JSBar";
import HighchartBar from "./highcharts/HighchartBar";

function PivotBarChart({ widgetId, output, onClick, highcharts = true }) {
  if (highcharts) {
    return <HighchartBar widgetId={widgetId} output={output} onClick={onClick} />;
  } else {
    return <D3JSBar output={output} onClick={onClick} />;
  }
}

PivotBarChart.propTypes = {
  widgetId: PropTypes.number,
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  highcharts: PropTypes.bool,
};

export default PivotBarChart;
