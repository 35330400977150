import store from "redux/store";
import { findById, hasMatchingId } from "./stringAndArray";

export function getCountryNameFromIsoCode(isoCode) {
  const state = store.getState();
  if (typeof isoCode === "string") isoCode = parseInt(isoCode);
  const lookupSource = state.lookup.global.find((lookup) => lookup.name === "Countries");
  const country = findById(lookupSource.lookup, isoCode) ?? "??";
  return state.locale.translations[country.translationCode];
}

export function getCountryCodeAlpha2FromIsoCode(isoCode) {
  const state = store.getState();
  if (typeof isoCode === "string") isoCode = parseInt(isoCode);
  const lookupSource = state.lookup.global.find((lookup) => lookup.name === "Countries");
  const country = findById(lookupSource.lookup, isoCode) ?? "??";
  return country.isoCodeAlpha2;
}

export function getCountryIsoCodeFromAlpha2Code(alpha2Code) {
  const state = store.getState();
  const lookupSource = state.lookup.global.find((lookup) => lookup.name === "Countries");
  const country = lookupSource.lookup.find((lookup) => lookup.isoCodeAlpha2 === alpha2Code) ?? "??";
  return country.id;
}

export function getCountryNamesFromIsoList(isoList) {
  const state = store.getState();
  const displayName = state.locale.translations["api.datafield.mattercountry.name"];
  const countriesArray = Array.isArray(isoList) && isoList.map((isoCode) => getCountryNameFromIsoCode(isoCode));
  const displayValue = countriesArray ? countriesArray.join(", ") : "Error";
  return { displayName, displayValue };
}

export function getAllCountriesAndCodes(fieldName, matterTypeId) {
  const state = store.getState();

  const matterTypeIds = matterTypeId ? [matterTypeId] : null;
  const countriesArray = getCountries(fieldName, matterTypeIds);

  const outputArray =
    countriesArray?.map((country) => {
      const id = country.id;
      const isoCodeAlpha2 = country.isoCodeAlpha2;
      const isoCodeAlpha3 = country.isoCodeAlpha3;
      const displayName = state.locale.translations[country.translationCode];
      return { id, isoCodeAlpha2, isoCodeAlpha3, displayName };
    }) ?? [];

  return outputArray;
}

export function isMatterTypeMultiCountry(matterTypeId) {
  const state = store.getState();

  const lookupSource = state.lookup.global.find((lookup) => lookup.name === "MatterTypes");
  return findById(lookupSource.lookup, matterTypeId).multiCountry;
}

export function getCountries(fieldName, matterTypeIds) {
  const state = store.getState();
  const lookupSource = state.lookup.global.find((lookup) => lookup.name === "Countries");
  const countriesArray = lookupSource?.lookup;

  if (!fieldName) {
    return countriesArray;
  }
  if (fieldName === "countries") {
    fieldName = "matterCountry_Id";
  }

  const countryGroupDataFieldMaps = state.lookup.global.find((lookup) => lookup.name === "CountryGroupDataFieldMaps");
  const countryGroupDataFieldMap = countryGroupDataFieldMaps?.lookup?.find(
    (countryGroupDataFieldMap) => countryGroupDataFieldMap.dataFieldName === fieldName
  );
  let exceptions = [];
  if (matterTypeIds?.length > 0) {
    exceptions =
      countryGroupDataFieldMap?.exceptions?.filter((exception) => findById(matterTypeIds, exception.matterTypeId)) ??
      [];
  }

  if (exceptions.length > 0 && exceptions.length === matterTypeIds?.length) {
    let countryGroupMembers = [];
    exceptions.forEach((exception, index) => {
      const members = getCountryGroupMembers(exception.countryGroupId);
      countryGroupMembers = index === 0 ? members : [...new Set([...countryGroupMembers, ...members])];
    });
    return getCountryGroupMembersToCountries(countriesArray, countryGroupMembers);
  } else if (exceptions.length > 0 && !countryGroupDataFieldMap?.countryGroupId) {
    return countriesArray;
  } else if (exceptions.length > 0 && countryGroupDataFieldMap?.countryGroupId) {
    let countryGroupMembers = getCountryGroupMembers(countryGroupDataFieldMap?.countryGroupId);
    exceptions.forEach((exception) => {
      const members = getCountryGroupMembers(exception.countryGroupId);
      countryGroupMembers = [...new Set([...countryGroupMembers, ...members])];
    });
    return getCountryGroupMembersToCountries(countriesArray, countryGroupMembers);
  } else if (countryGroupDataFieldMap?.countryGroupId) {
    const members = getCountryGroupMembers(countryGroupDataFieldMap.countryGroupId);
    return getCountryGroupMembersToCountries(countriesArray, members);
  } else {
    return countriesArray;
  }
}

function getCountryGroupMembers(countryGroupId) {
  const state = store.getState();
  const countryGroupMember = findById(state.customer.countryGroups, countryGroupId);

  return countryGroupMember?.memberCountries ?? [];
}

function getCountryGroupMembersToCountries(countriesArray, countryGroupMembers) {
  return countriesArray.filter((country) => hasMatchingId(countryGroupMembers, country.id));
}
