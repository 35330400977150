import PropTypes from "prop-types";
import GridOutput from "./grid/GridOutput";
import MapOutput from "./map/MapOutput";
import PivotOutput from "./pivot/PivotOutput";
import { ResultSetTypes } from "utilities/reportEditor";

function ReportOutput({ widgetId, output, onClick }) {
  let control = null;

  switch (output.type) {
    case ResultSetTypes.MAP:
      control = <MapOutput key={output.logId} widgetId={widgetId} output={output.map} onClick={onClick} />;
      break;
    case ResultSetTypes.PIVOT:
      control = <PivotOutput key={output.logId} widgetId={widgetId} output={output.pivot} onClick={onClick} />;
      break;
    case ResultSetTypes.TABLE:
      control = <GridOutput key={output.logId} output={output.table} />;
      break;
    default:
  }

  return control;
}

ReportOutput.propTypes = {
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default ReportOutput;
