import { deleteUserFromGroup, changeUsersUserGroup, addUserToAccount, editUserGroup } from "api/security";
import TabsHorizontal from "components/global/TabsHorizontal";
import PopupHeader from "components/global/PopupHeader";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AccessRights from "./components/access-rights/AccessRights";
import useComponentVisible from "components/global/useComponentVisible";
import Delete from "components/global/Delete";
import { findById, idsAreEqual } from "utilities/stringAndArray";
import SecurityColumnHeader from "./SecurityColumnHeader";
import { labelTypes } from "utilities/constants";

export default function UserGroupDetails() {
  const securityState = useSelector((state) => state.security);
  const customerState = useSelector((state) => state.customer);

  const [users, setUsers] = useState([]);
  const [isAddingMemberToUserGroup, setIsAddingMemberToUserGroup] = useState(false);
  const [userGroupName, setUserGroupName] = useState(securityState?.currentUserGroup?.name);
  const [userDetailsTabId, setUserDetailsTabId] = useState(1);
  const [sortParams, setSortParams] = useState({
    sortCol: "name",
    sortAsc: true,
  });

  const [refModal, showModal, setShowModal] = useComponentVisible(false);

  const currentCustomer = securityState.currentCustomer;
  const currentAccount = securityState.currentAccount;
  const currentUserGroup = securityState?.currentUserGroup;

  useEffect(() => {
    let usersLocal = currentAccount?.users.filter((user) => idsAreEqual(user?.userGroupId, currentUserGroup?.id)) ?? {};
    usersLocal = usersLocal.map((accountUser) => {
      const userData = {
        ...findById(customerState.users, accountUser.customerUserId),
        groupId: accountUser.userGroupId,
        groupUserId: accountUser.id,
      };
      return {
        id: accountUser.id,
        customerUserId: accountUser.customerUserId,
        fullName: userData.fullName,
      };
    });
    setUsers(usersLocal);
  }, [currentAccount?.users]);

  const addNewMemberToGroup = async (memberId) => {
    const user = currentAccount.users.find((user) => idsAreEqual(user.customerUserId, memberId));
    if (!user) {
      const newMember = await addUserToAccount(currentCustomer.id, currentAccount.id, memberId);
      changeUsersUserGroup(
        currentCustomer.id,
        currentAccount.id,
        newMember.id,
        "userGroupId",
        user?.userGroupId ?? null,
        currentUserGroup.id
      );
    } else {
      changeUsersUserGroup(
        currentCustomer.id,
        currentAccount.id,
        user?.id,
        "userGroupId",
        user?.userGroupId ?? null,
        currentUserGroup.id
      );
    }
    setIsAddingMemberToUserGroup(false);
  };

  const renameUserGroup = () => {
    editUserGroup(
      currentCustomer.id,
      securityState.currentAccount.id,
      currentUserGroup.id,
      "name",
      currentUserGroup.name,
      userGroupName
    );
    setUserGroupName("");
    setShowModal(false);
  };

  const handleUpdateSortParams = (sortedData, newSortParams) => {
    setUsers(sortedData);
    setSortParams(newSortParams);
  };

  const renderRenameUserGroupModalBody = (
    <>
      <div className="modal__selection-grid">
        <label htmlFor="renameGroupNameInput">{t("Name")}</label>
        <input
          className="modal__input"
          id="renameGroupNameInput"
          type="text"
          onChange={(e) => setUserGroupName(e.target.value)}
          value={userGroupName}
        ></input>
      </div>
      <div className="modal__selection-row">
        <button onClick={() => renameUserGroup()}>{t("Submit")}</button>
        <button onClick={() => setShowModal(false)}>{t("Cancel")}</button>
      </div>
    </>
  );

  const getMemberOptions = () => {
    if (currentAccount?.users && currentCustomer?.users) {
      const idsInGroup = currentAccount.users.map((user) => {
        if (user.userGroupId) return user.customerUserId;
      });
      const options = [];
      currentCustomer.users.forEach((user) => {
        if (!idsInGroup.includes(user.id)) {
          options.push({ id: user.id, displayValue: user.fullName });
        }
      });
      return options;
    } else return [];
  };

  const renderTableBody =
    users?.length > 0 ? (
      users.map((accountUser) => {
        const user = findById(currentCustomer.users, accountUser.customerUserId);
        //if (!user) return null;
        return (
          <tr className="security__table__body-row" key={user.id}>
            <td className="security__table__body-row-cell">
              <Link
                to={`/customers/${currentCustomer.id}/accounts/${currentAccount.id}/usergroups/${currentUserGroup.id}/users/${user.id}`}
              >
                {user.fullName}
              </Link>
            </td>
            <td className="security__table__body-row-cell">
              {customerState.fullName !== user.fullName && (
                <Delete
                  message={`Delete ${user.fullName} from the ${currentUserGroup.name}?`}
                  onConfirm={() => deleteUserFromGroup(currentCustomer.id, currentAccount.id, accountUser.id)}
                ></Delete>
              )}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="security__table__body__no-data">{t("No Users")}</td>
      </tr>
    );

  const renderMembersTable = (
    <>
      <div className="security__section-title">{t("Members")}</div>

      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <SecurityColumnHeader
                fieldName="fullName"
                fieldType={labelTypes.STRING}
                displayName="Name"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <th className="security__table__header-row-cell">{t("Delete")}</th>
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderTableBody}
            {isAddingMemberToUserGroup && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell">
                  <SuperSelect
                    id="userNameInput"
                    selectedOptionId={null}
                    options={getMemberOptions()}
                    placeholderText="Search for User"
                    onChange={(optionId) => addNewMemberToGroup(optionId)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isAddingMemberToUserGroup && (
        <button className="flex-row-center" onClick={() => setIsAddingMemberToUserGroup(true)}>
          {t("Add New Member")}
        </button>
      )}
    </>
  );

  const tabArray = [
    {
      id: 1,
      label: "Members",
      body: renderMembersTable,
    },
    { id: 2, label: "Access Rights", body: <AccessRights /> },
  ];

  //MAIN RENDER
  return (
    <>
      <div className="security__title-container">
        <span onClick={() => setShowModal(true)} className="link">
          {t("Rename User Group")}
        </span>
      </div>
      {currentCustomer?.id && (
        <TabsHorizontal
          tabArray={tabArray}
          currentTabId={userDetailsTabId}
          onTabChange={(tab) => setUserDetailsTabId(tab.id)}
        >
          {tabArray.map((tab) => (
            <div key={tab.id}>{idsAreEqual(tab?.id, userDetailsTabId) ? tab.body : null}</div>
          ))}
        </TabsHorizontal>
      )}
      {showModal && (
        <div className="modal-mask">
          <div className="modal__selection-popup">
            <PopupHeader
              title={t("Rename User Group")}
              onClose={() => {
                setShowModal(false);
              }}
            />
            <div className="modal__selection-body">{renderRenameUserGroupModalBody}</div>
          </div>
        </div>
      )}
    </>
  );
}
