import { useEffect, useState } from "react";
import useComponentVisible from "components/global/useComponentVisible";
import NumberPicker from "./NumberPicker";
import { FaCaretDown } from "react-icons/fa";
import PropTypes from "prop-types";
import Tooltip from "components/global/Tooltip";

function InputNumberPicker({ label, numbers, max, min, onChange, value, tooltipText }) {
  const [refPicker, showPicker, setShowPicker] = useComponentVisible(false);

  const [inputCharacters, setInputCharacters] = useState(2);

  useEffect(() => {
    const maxNumber = max ? max : Math.max(...numbers);

    setInputCharacters((Math.log(maxNumber) * Math.LOG10E + 1) | 0);
  }, [max, numbers]);

  return (
    <Tooltip content={tooltipText} direction="left">
      <div className="toolbar__number-picker-input">
        {label && <span style={{ marginRight: "4px" }}>{label}</span>}
        <input
          className="number-picker-input__input"
          style={{ width: inputCharacters + 3 + "rem" }}
          type="number"
          min={min}
          max={max}
          value={value}
          onChange={(event) => onChange(Number(event.target.value))}
        />
        <div
          onClick={() => {
            setShowPicker(!showPicker);
          }}
          className="toolbar__button"
          style={{
            verticalAlign: "none",
            width: "18px",
          }}
        >
          <FaCaretDown className="button__icon" />
        </div>
        <NumberPicker
          inputRef={refPicker}
          numbers={numbers}
          value={value}
          setValue={(value) => onChange(value)}
          show={showPicker}
          setShow={setShowPicker}
          width={inputCharacters + 3 + "rem"}
        />
      </div>
    </Tooltip>
  );
}

InputNumberPicker.propTypes = {
  label: PropTypes.string,
  numbers: PropTypes.arrayOf(PropTypes.number),
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default InputNumberPicker;
