import { updateAccessRight } from "api/security";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useSelector } from "react-redux";
import { getAllLookupValuesForSource } from "utilities/lookup";

export default function MasterAccessPanel() {
  const securityState = useSelector((state) => state.security);

  const currentUserGroup = securityState?.currentUserGroup;

  const permissions = getAllLookupValuesForSource("Permissions");

  const handleMasterAccessChange = (permissionId) => {
    updateAccessRight(
      { customerId: securityState.currentCustomer.id, accessRightId: currentUserGroup.accessRight.id },
      currentUserGroup.accessRight.permissionId,
      permissionId
    );
  };

  return (
    <div className="security__section">
      <div className="security__section-title">{t(" Master Access Rights")}</div>
      <div className="security__section-body">
        <p>{t("Applies to all other filtering unless overriden")} </p>
        <p>&nbsp;</p>
        {permissions.length > 0 && currentUserGroup?.accessRight?.permissionId && (
          <SuperSelect
            id="masterAccessOptions"
            selectedOptionId={currentUserGroup.accessRight.permissionId ?? ""}
            options={permissions ?? []}
            onChange={handleMasterAccessChange}
          />
        )}
      </div>
    </div>
  );
}
