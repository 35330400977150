import PropTypes from "prop-types";
import Checkbox from "components/global/Checkbox";
import SuperSelect from "components/global/SuperSelect";
import { FaTimes } from "react-icons/fa";
import { ImSortAlphaAsc, ImSortAlphaDesc } from "react-icons/im";
import Tooltip from "components/global/Tooltip";

// Displays a row in the Sort Order modal
function SortOrderKey({ index, label, options, sortColumn, showGrouping, onSelectChange, onDelete, onReverseSort }) {
  return (
    <tr className="modal__selection-row modal__selection-row--left">
      <td className="selector__header">
        <div className="modal__label sort-order__label">{label}</div>
      </td>
      <td className="modal__select sort-order__select">
        <SuperSelect
          id={`sortOrderKey${index}`}
          selectedOptionId={sortColumn.fieldName}
          options={options}
          onChange={(optionId) => onSelectChange(index, sortColumn, "fieldName", optionId)}
        />
      </td>
      <td className="sort-order__icon sort-order__icon--button" onClick={() => onReverseSort(index)}>
        <Tooltip content={`Sort ${sortColumn.descending ? "Ascending" : "Descending"}`} direction="right">
          {sortColumn.descending ? <ImSortAlphaDesc /> : <ImSortAlphaAsc />}
        </Tooltip>
      </td>
      {showGrouping && (
        <>
          <td className="modal__label sort-order__checkbox">
            <Checkbox
              label="Group by Field"
              isChecked={sortColumn.outputHeader}
              onCheckChange={() => onSelectChange(index, sortColumn, "outputHeader", !sortColumn.outputHeader)}
            />
          </td>
          <td className="modal__label sort-order__checkbox">
            <Checkbox
              label="Group Footer"
              isChecked={sortColumn.outputFooter}
              onCheckChange={() => onSelectChange(index, sortColumn, "outputFooter", !sortColumn.outputFooter)}
            />
          </td>
        </>
      )}
      <td className="delete__container sort-order__icon">
        {onDelete ? (
          <div onClick={() => onDelete(index)}>
            <FaTimes />
          </div>
        ) : (
          <span>&nbsp;</span>
        )}
      </td>
    </tr>
  );
}

SortOrderKey.propTypes = {
  index: PropTypes.number,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  sortColumn: PropTypes.object,
  onSelectChange: PropTypes.func,
  onDelete: PropTypes.func,
  onReverseSort: PropTypes.func,
};

export default SortOrderKey;
