import { t } from "locale/dictionary";
import { useDispatch, useSelector } from "react-redux";
import { setAllFilters, setSavedSearchLoadedId } from "redux/simpleSearchSlice";
import { findById } from "utilities/stringAndArray";

// Displays clickable Saved Search used commonly in Pinned Search and Favourites panels
export default function SavedSearchLink({ search }) {
  const savedSearches = useSelector((state) => state.simpleSearch).currentSearch.savedSearches;
  const dispatch = useDispatch();

  const onSavedSearchClick = async (savedSearchId) => {
    const savedSearch = findById(savedSearches, savedSearchId);
    dispatch(setAllFilters(savedSearch.filters));
    dispatch(setSavedSearchLoadedId(savedSearchId));
  };

  return (
    <p className="link" onClick={() => onSavedSearchClick(search.id)}>
      {search.name}
      {search.isDefault && t("DEFAULT")}
    </p>
  );
}
