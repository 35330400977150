import { useSelector } from "react-redux";
import { t } from "locale/dictionary";
import { getUnpinnedRecordPosition } from "utilities/matter";
import { RiPushpinFill, RiPushpin2Fill } from "react-icons/ri";

export default function PageNumber() {
  const matterState = useSelector((state) => state.matter);
  const selectedRecordIndex = matterState.selectedRecordIndex;
  const selectedRecordPosition = getUnpinnedRecordPosition(matterState.records, selectedRecordIndex);
  const numRecords = matterState.records.length;
  const numPinnedRecords = matterState.records.filter((r) => r.isPinned === true).length;
  const numUnpinnedRecords = numRecords - numPinnedRecords;
  const unpinnedDisplayCount = matterState.numberComparing - numPinnedRecords;
  const hasAllSlotsFilled = numPinnedRecords >= matterState.numberComparing;

  if (!matterState.isComparing) {
    return (
      <div className="matters__page-number">
        <strong>{selectedRecordIndex + 1}</strong>
        &nbsp;{t("of")}&nbsp;
        <strong>{numRecords}</strong>
      </div>
    );
  } else {
    return (
      <div className="matters__page-number">
        {numPinnedRecords > 0 && (
          <>
            <RiPushpin2Fill />
            &nbsp;
            <strong>{numPinnedRecords}</strong>
            &nbsp;
          </>
        )}
        <>
          {numPinnedRecords > 0 && (
            <>
              <RiPushpinFill />
              &nbsp;
            </>
          )}
          {!hasAllSlotsFilled && (
            <>
              <strong>
                {selectedRecordPosition}
                {unpinnedDisplayCount > 1 && <> - {selectedRecordPosition + unpinnedDisplayCount - 1}</>}
              </strong>
              &nbsp;{t("of")}&nbsp;
            </>
          )}
          <strong>{numUnpinnedRecords}</strong>
        </>
      </div>
    );
  }
}
