// Lookup API calls

import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";
import {
  addLookup as addLookupRedux,
  updateLookup as updateLookupRedux,
  removeLookup as removeLookupRedux,
  addLookupEntry as addLookupEntryRedux,
  updateLookupEntry as updateLookupEntryRedux,
  removeLookupEntry as removeLookupEntryRedux,
  setLookup,
} from "redux/lookupSlice";
import { queryTypeChange, setDefaultSearchId } from "redux/simpleSearchSlice";
import { addTranslation, updateTranslation } from "redux/localeSlice";
import { addToLoadingList, removeFromLoadingList } from "redux/appSlice";

function loadLookup(name, path) {
  store.dispatch(addToLoadingList(name));
  return fetchWrapper.get(path).then((data) => {
    store.dispatch(setLookup({ name, lookup: data }));
    store.dispatch(removeFromLoadingList(name));
    return data;
  });
}

export async function loadAllGlobalLookups() {
  const state = store.getState();
  const customerId = state.app.customerId;

  // DO NOT DELETE
  // Admin Level functions - causing exceptions when non-Admin is logged in. Uncomment once we have access to user admin level info
  // fetchWrapper.get("AccessFilters").then((data) => {
  //   store.dispatch(setLookup({ name: "AccessFilters", lookup: data }));
  // });

  // fetchWrapper.get("ExtendableTables").then((data) => {
  //   store.dispatch(setLookup({ name: "ExtendableTables", lookup: data }));
  // });
  // Admin Level functions - causing exceptions when non-Admin is logged in. Uncomment once we have access to user admin level info

  // These special lookups must be loaded asynchronously, as they are needed in the next global initialisation call
  const dataSources = await fetchWrapper.get("DataSources");
  store.dispatch(setLookup({ name: "DataSources", lookup: dataSources }));

  loadLookup("AccountNumberTypes", `customers/${customerId}/accountnumbertypes`);
  loadLookup("Accounts", `customers/${customerId}/Accounts`);
  loadLookup("ActionTypes", `customers/${customerId}/actiontypes`);
  loadLookup("CommentContactLinkTypes", `customers/${customerId}/commentcontactlinktypes`);
  loadLookup("CommentTypes", `customers/${customerId}/CommentTypes`);
  loadLookup("CompanyLinkContactLinkTypes", `customers/${customerId}/CompanyLinkContactLinkTypes`);
  loadLookup("ContactLinkTypes", `customers/${customerId}/contactlinktypes`);
  loadLookup("ContactPositions", `customers/${customerId}/ContactPositions`);
  loadLookup("CorsearchStatuses", "CorsearchStatuses");
  loadLookup("Countries", "Countries");
  loadLookup("CountryDataFields", "CountryDataFields");
  loadLookup("CountryGroupDataFieldMaps", "CountryGroupDataFieldMaps");
  loadLookup("CustomerStatuses", "CustomerStatuses");
  loadLookup("DataFieldGroups", `customers/${customerId}/datafieldgroups`);
  loadLookup("DataFieldOverrides", "DataFieldOverrides");
  loadLookup("DirectContactLinkTypes", `customers/${customerId}/directcontactlinktypes`);
  loadLookup("DocumentTypes", `customers/${customerId}/documenttypes`);
  loadLookup("FileExtensions", `customers/${customerId}/fileextensions`);
  loadLookup("FormatFonts", "FormatFonts");
  loadLookup("FormatSectionTypes", "FormatSectionTypes");
  loadLookup("GoodsTypes", `customers/${customerId}/GoodsTypes`);
  loadLookup("Goods", `customers/${customerId}/Goods`);
  loadLookup("Groups", `customers/${customerId}/UserGroups`);
  loadLookup("Languages", "Languages");
  loadLookup("LegalTeam", `customers/${customerId}/LegalTeam`);
  loadLookup("LegalTeamContactLinkTypes", `customers/${customerId}/legalteamcontactlinktypes`);
  loadLookup("LinkTypes", `customers/${customerId}/LinkTypes`);
  loadLookup("ListTypes", `customers/${customerId}/ListTypes`);
  loadLookup("ListValues", `customers/${customerId}/ListValues`);
  loadLookup("MatterDataFields", "MatterDataFields");
  loadLookup("MatterObjects", "MatterObjects");
  loadLookup("MatterTypes", "MatterTypes");
  loadLookup("NiceClasses", "NiceClasses");
  loadLookup("Permissions", "Permissions");
  loadLookup("ProjectImportSourceTypes", `customers/${customerId}/ProjectImportSourceTypes`);
  loadLookup("ProjectTypes", "ProjectTypes");
  loadLookup("ProjectStatuses", "ProjectStatuses");
  loadLookup("QueryTypes", "QueryTypes").then((data) => {
    store.dispatch(queryTypeChange(data[0]));
  });
  loadLookup("RecordSubtypes", `customers/${customerId}/RecordSubtypes`);
  loadLookup("RecordTypes", `customers/${customerId}/RecordTypes`);
  loadLookup("ReferenceTypes", `customers/${customerId}/ReferenceTypes`);
  loadLookup("RoyaltyFrequencyTypes", `customers/${customerId}/RoyaltyFrequencyTypes`);
  loadLookup("Statuses", `customers/${customerId}/Statuses`);
  loadLookup("Substatuses", `customers/${customerId}/Substatuses`);
  loadLookup("TimeZones", "TimeZones");
  loadLookup("Users", `customers/${customerId}/Users`);
  loadLookup("UserSettings", `customers/${customerId}/usersettings`).then((data) => {
    store.dispatch(setDefaultSearchId(data.defaultSearchFilterId));
  });
  loadLookup("WidgetTypes", "WidgetTypes");
}

export async function addListType(displayName) {
  debugger;
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { description: displayName, code: displayName, multiValued: true };
  const response = await fetchWrapper.post(`customers/${customerId}/listtypes`, body);
  // UNTESTED
  store.dispatch(addLookupRedux({ name: "ListTypes", lookup: response }));
  //store.dispatch(addTranslation({ [response.translationCode]: displayName }));
  return response;
}

export async function updateListType(lookupId, fieldName, currentValue, newValue, translationCode) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = [
    { path: `/${fieldName}`, op: "test", value: currentValue },
    { path: `/${fieldName}`, op: "replace", value: newValue },
  ];
  fetchWrapper.patch(`customers/${customerId}/listtypes/${lookupId}`, body);
  if (fieldName !== "description")
    store.dispatch(updateLookupRedux({ name: "ListTypes", lookupId, fieldName, newValue }));
  else store.dispatch(updateTranslation({ translationCode, newValue }));
}

export async function deleteListType(listTypeId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  fetchWrapper.delete(`customers/${customerId}/listtypes/${listTypeId}`);
  // UNTESTED
  store.dispatch(removeLookupRedux({ name: "ListTypes", lookupId: listTypeId }));
}

export async function addLookupEntry(name, displayName) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { displayName };

  const response = await fetchWrapper.post(`customers/${customerId}/${name}`, body);
  store.dispatch(addLookupEntryRedux({ name, lookup: response }));
  store.dispatch(addTranslation({ [response.translationCode]: displayName }));
  return response;
}

export async function updateLookupEntry(name, lookupId, fieldName, currentValue, newValue, translationCode) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = [
    { path: `/${fieldName}`, op: "test", value: currentValue },
    { path: `/${fieldName}`, op: "replace", value: newValue },
  ];
  fetchWrapper.patch(`customers/${customerId}/${name}/${lookupId}`, body);
  store.dispatch(updateLookupEntryRedux({ name, lookupId, fieldName, newValue }));
  if (fieldName === "displayName") store.dispatch(updateTranslation({ translationCode, newValue }));
}

export async function deleteLookupEntry(name, lookupId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  fetchWrapper.delete(`customers/${customerId}/${name}/${lookupId}`);
  store.dispatch(removeLookupEntryRedux({ name, lookupId }));
}

export async function addListTypeEntry(listTypeId, displayName) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { description: displayName, shortDescription: displayName };

  const response = await fetchWrapper.post(`customers/${customerId}/listtypes/${listTypeId}/values`, body);
  store.dispatch(addLookupEntryRedux({ name: "ListValues", lookup: response }));
  store.dispatch(addTranslation({ [response.translationCode]: displayName }));
  return response;
}

export async function updateListTypeEntry(listTypeId, listValueId, currentValue, newValue, translationCode) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = [
    { path: "/description", op: "test", value: currentValue },
    { path: "/description", op: "replace", value: newValue },
  ];
  fetchWrapper.patch(`customers/${customerId}/listtypes/${listTypeId}/values/${listValueId}`, body);
  store.dispatch(updateLookupEntryRedux({ name: "ListValues", lookupId: listValueId, newValue }));
  store.dispatch(updateTranslation({ translationCode, newValue }));
}

export async function deleteListTypeEntry(listTypeId, listValueId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  fetchWrapper.delete(`customers/${customerId}/listtypes/${listTypeId}/values/${listValueId}`);
  store.dispatch(removeLookupEntryRedux({ name: "ListValues", lookupId: listValueId }));
}
