import PropTypes from "prop-types";
import PivotBarChart from "./PivotBarChart";
import PivotPieChart from "./PivotPieChart";
import PivotTable from "./PivotTable";

function PivotOutput({ widgetId, output, onClick }) {
  let control = null;

  switch (output.chartType) {
    case "bar":
    case "column":
      control = <PivotBarChart widgetId={widgetId} output={output} onClick={onClick} highcharts={true} />;
      break;
    case "pie":
      control = <PivotPieChart widgetId={widgetId} output={output} onClick={onClick} highcharts={true} />;
      break;
    case "table":
      control = <PivotTable output={output} onClick={onClick} />;
      break;
    default:
      control = (
        <>
          <PivotTable output={output} />
          <PivotPieChart output={output} highcharts={true} />
          <PivotBarChart output={output} highcharts={true} />
        </>
      );
  }

  return control;
}

PivotOutput.propTypes = {
  widgetId: PropTypes.number,
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default PivotOutput;
