import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { getAxisKeys } from "utilities/charting";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";

const colors = scaleOrdinal(schemeCategory10).range();

function getData(axisArray1, pivotData, axisArray2 = null) {
  let series = [];
  if (axisArray1.length > 0) {
    series = axisArray1.map((axis1) => {
      if (axisArray2 === null) {
        let value = 0;

        const totalResultSetRow = pivotData.find((resultRow) => resultRow.keys[axis1.keyColumn] === axis1.value);
        if (totalResultSetRow) {
          value = totalResultSetRow.values[0];
        }

        return { name: axis1.label, value: value };
      } else {
        let obj = { name: axis1.label };
        for (let i = 0; i < axisArray2.length; i++) {
          const axis2 = axisArray2[i];
          const totalResultSetRow = pivotData.find(
            (resultRow) =>
              resultRow.keys[axis1.keyColumn] === axis1.value && resultRow.keys[axis2.keyColumn] === axis2.value
          );
          let value = 0;
          if (totalResultSetRow) {
            value = totalResultSetRow.values[0];
          }

          obj = { ...obj, [axis2.label]: value };
        }

        return obj;
      }
    });
  }

  series.sort((a, b) => (a.name < b.name ? -1 : 1));

  return series;
}

function D3JSBar({ output }) {
  const [data, setData] = useState(null);
  const [bars, setBars] = useState(null);

  useEffect(() => {
    const xAxisKeys = getAxisKeys(output.xAxisColumns, output.rowData);
    const yAxisKeys = getAxisKeys(output.yAxisColumns, output.rowData);

    let stacked = false;
    let data = [];
    if (yAxisKeys.length > 0 && xAxisKeys.length > 0) {
      data = getData(yAxisKeys, output.rowData, xAxisKeys);
      stacked = true;
    } else if (yAxisKeys.length > 0) {
      data = getData(yAxisKeys, output.rowData);
    } else if (yAxisKeys.length > 0) {
      data = getData(xAxisKeys, output.rowData);
    }

    setData(data);

    if (stacked) {
      setBars(
        <>
          {xAxisKeys.map((axisKey, index) => {
            return <Bar dataKey={axisKey.label} stackId="a" fill={colors[index % colors.length]} />;
          })}
        </>
      );
    } else {
      setBars(
        <Bar dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Bar>
      );
    }
  }, [output]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      {data != null && (
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {bars}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
}

D3JSBar.propTypes = {
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default D3JSBar;
