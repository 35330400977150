import { t } from "locale/dictionary";
import { useEffect, useState } from "react";
import { getDateDisplayValue } from "utilities/dateTime";
import { getDataTypeForField, getDisplayNameForField, getLookupValueForField } from "utilities/datafield";
import { useNavigate } from "react-router";
import { compareByFieldValue } from "utilities/stringAndArray";
import { useRef } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { labelTypes } from "utilities/constants";

// Displays data in tabular form for various widgets
export default function DataTable({ fields, data }) {
  const navigate = useNavigate();
  const [dataEntries, setDataEntries] = useState(null);
  const [sortCol, setSortCol] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);

  const hasInitialSortSetRef = useRef(false);

  useEffect(() => {
    if (!hasInitialSortSetRef.current && data.length > 0) {
      hasInitialSortSetRef.current = true;
      let initialSortData = [...data];
      // loop backwards through fields sorting by last and finishing sort with the second (the first column we do not use as it's just for ID)
      for (let i = fields.length - 1; i >= 1; i--) initialSortData = sortColumn(fields[i], initialSortData, true);
      setSortCol(fields[1]);
      setDataEntries(initialSortData);
    }
  }, [data]);

  const sortColumn = (fieldName, dataEntriesToSort, isInitialSort) => {
    const fieldType = getDataTypeForField(fieldName);
    if (fieldType === labelTypes.UNKNOWN) return dataEntriesToSort;
    let newSortAsc = true;
    if (!isInitialSort) newSortAsc = fieldName === sortCol ? !sortAsc : true;
    dataEntriesToSort.sort((a, b) => compareByFieldValue(a, b, fieldName, fieldType, newSortAsc));
    if (!isInitialSort) {
      setSortAsc(newSortAsc);
      setSortCol(fieldName);
      setDataEntries(dataEntriesToSort);
    }
    return dataEntriesToSort;
  };

  const handleColumnClick = (fieldName) => {
    let dataEntriesLocal = [...dataEntries];
    sortColumn(fieldName, dataEntriesLocal);
  };

  const onNavigateToMatter = (id) => {
    navigate(`/matter/${id}`, { replace: false });
  };

  const renderHeader = (
    <tr>
      {fields &&
        fields.length > 0 &&
        fields.map(
          (field) =>
            field !== "matterId" && (
              <th key={field} onClick={() => handleColumnClick(field)}>
                {getDisplayNameForField(field)}
                <div className="sort-spacer">
                  {field === sortCol && <span>{sortAsc ? <FaCaretUp /> : <FaCaretDown />}</span>}
                </div>
              </th>
            )
        )}
    </tr>
  );

  const renderDataEntries =
    dataEntries &&
    dataEntries.length > 0 &&
    dataEntries.map((dataEntry) => {
      return (
        <tr key={dataEntry.matterId} onClick={() => onNavigateToMatter(dataEntry.matterId)}>
          {fields.map((field) => {
            if (!field || field.length === 0 || field === "matterId") return null;
            let displayValue = dataEntry[field];
            if (field.toLowerCase().includes("date")) displayValue = getDateDisplayValue(displayValue, true);
            if (field.toLowerCase().includes("type")) displayValue = getLookupValueForField(field, displayValue);
            if (displayValue?.length > 30) displayValue = displayValue.substring(0, 30);
            return (
              <td key={field} className="data-grid__cell data-grid__cell--40">
                {displayValue}
              </td>
            );
          })}
        </tr>
      );
    });

  return (
    <>
      {dataEntries?.length > 0 && fields.length > 0 ? (
        <div className="search-results__data-grid">
          <table>
            <thead>{renderHeader}</thead>
            <tbody>{renderDataEntries}</tbody>
          </table>
        </div>
      ) : (
        <p>{t("No entries")}</p>
      )}
    </>
  );
}
