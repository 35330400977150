import { t } from "locale/dictionary";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { setDocumentTitle } from "redux/appSlice";

const displayNameMappings = [
  { name: t("Search Results"), path: "/results" },
  { name: t("Report Menu"), path: "/reportmenu" },
  { name: t("Report Editor"), path: "/reporteditor" },
  { name: t("Matter Record"), path: "/matter" },
  { name: t("Image Store"), path: "/imagestore" },
  { name: t("Customers"), path: "/customers" },
  { name: t("Accounts"), path: "/accounts" },
  { name: t("User Groups"), path: "/usergroups" },
  { name: t("Users"), path: "/users" },
  { name: t("Batch Import"), path: "/batch-import" },
  { name: t("Batch Create Records"), path: "/batch-create" },
  { name: t("Configuration"), path: "/configuration" },
];

export default function Breadcrumbs() {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathnamesRaw = location.pathname.split("/").filter((x) => x); // Remove empty string
  const pathnamesInitial = pathnamesRaw.map((pn) => {
    const displayNameMapping = displayNameMappings.find((fn) => fn.path === `/${pn}`);
    if (displayNameMapping) return { displayName: displayNameMapping.name, path: pn };
    return { displayName: pn, path: pn };
  });

  let pathnames = pathnamesInitial;
  // loop through pathnames and combine paths that are numeric params into the path of the last pathnames element
  for (let i = 0; i < pathnames.length; i++) {
    if (!isNaN(pathnames[i].path)) {
      pathnames[i - 1].path = pathnames[i - 1].path + "/" + pathnames[i].path;
      pathnames.splice(i, 1);
      i--;
    }
  }

  useEffect(() => {
    // set last path display name to page title in redux
    // exceptions: matter record page
    if (pathnames.length > 0) {
      const lastPath = pathnames[pathnames.length - 1];
      if (!lastPath.path.includes("matter")) dispatch(setDocumentTitle(lastPath.displayName));
    }
  }, [pathnames]);

  return (
    pathnamesRaw.length > 0 && ( // don't show if home / at root
      <div className="breadcrumb-container">
        <ul className="breadcrumbs">
          <li>
            <Link to="/">{t("Home")}</Link>
          </li>
          {pathnames.map((pn, index) => {
            const pathsUpToIndex = pathnames.slice(0, index + 1);
            const routeTo = pathsUpToIndex.map((path) => path.path).join("/");
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <li key={pn.path}>{pn.displayName}</li>
            ) : (
              <li key={pn.path}>
                <Link to={routeTo}>{pn.displayName}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
}
