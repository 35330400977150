import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getDisplayNameForField } from "utilities/reportEditor";
import { FaTrashAlt } from "react-icons/fa";

function PivotAxisField({ field, onEditClick, onDeleteClick, matterTypeIds }) {
  const [fieldLabel, setFieldLabel] = useState("");

  useEffect(() => {
    setFieldLabel(getDisplayNameForField(field.fieldName, matterTypeIds));
  }, [field.fieldName, matterTypeIds]);

  return (
    <div className="fields-container__field">
      <div className="field__label" onClick={() => onEditClick(field)}>
        {field.function ? (
          <>
            {field.function}
            {" - "}
            {fieldLabel}
          </>
        ) : (
          <>{fieldLabel}</>
        )}
      </div>
      <div className="field__delete" onClick={() => onDeleteClick(field)}>
        <FaTrashAlt />
      </div>
    </div>
  );
}

PivotAxisField.propTypes = {
  field: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  matterTypeIds: PropTypes.array,
};

export default PivotAxisField;
