import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useComponentVisible from "components/global/useComponentVisible";
import { ReportOutputTypes } from "utilities/reportEditor";
import ToolbarButton from "../../toolbar/ToolbarButton";
import { FaCaretDown, FaDesktop, FaFileExcel, FaFileWord } from "react-icons/fa";
import OutputPicker from "./OutputPicker";
import Tooltip from "components/global/Tooltip";

function ToolbarOutputPicker({ outputType, onOutputTypeClick }) {
  const [image, setImage] = useState(null);

  const [refColorPicker, showColorPicker, setShowColorPicker] = useComponentVisible(false);

  useEffect(() => {
    switch (outputType) {
      case ReportOutputTypes.EXCEL:
        setImage(<FaFileExcel className="button__icon" />);
        break;
      case ReportOutputTypes.WORD:
        setImage(<FaFileWord className="button__icon" />);
        break;
      default:
        setImage(<FaDesktop className="button__icon" />);
        break;
    }
  }, [outputType]);

  return (
    <Tooltip content="Change report output medium" direction="left">
      <>
        <ToolbarButton onClick={() => onOutputTypeClick()}>{image}</ToolbarButton>
        <div
          className="toolbar__button"
          onClick={() => setShowColorPicker(!showColorPicker)}
          style={{
            width: "18px",
          }}
        >
          <FaCaretDown className="button__icon" />
        </div>
        <OutputPicker
          inputRef={refColorPicker}
          show={showColorPicker}
          setShow={setShowColorPicker}
          outputType={outputType}
          setValue={(value) => {
            onOutputTypeClick(value);
          }}
        />
      </>
    </Tooltip>
  );
}

ToolbarOutputPicker.propTypes = {
  outputType: PropTypes.number,
  onOutputTypeClick: PropTypes.func,
};

export default ToolbarOutputPicker;
