import AccountUserGroups from "./AccountUserGroups";
import AccountUsers from "./AccountUsers";

export default function AccountDetails() {
  return (
    <>
      <AccountUserGroups />
      <AccountUsers />
    </>
  );
}
