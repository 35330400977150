import React, { useEffect } from "react";
import { useState } from "react";
import Switch from "components/global/Switch";
import RadioHorizontal from "components/global/RadioHorizontal";
import BatchCreateManualPriority from "./BatchCreateManualPriority";
import BatchCreateMatterSearch from "./BatchCreateMatterSearch";
import { createBatchNewLinkedMatter, createMasterTemplate, deleteBatchNewLinkedMatter } from "api/batchCreate";
import { t } from "locale/dictionary";
import { useDispatch, useSelector } from "react-redux";
import {
  setBatchCreateProject,
  addNewLinkedMatter,
  setLinkedMatters,
  setNewLinkedMatters,
} from "redux/batchCreateSlice";
import { clearResults } from "redux/searchResultsSlice";
import { updateBatchProject } from "api/project";
import { idsAreEqual } from "utilities/stringAndArray";
import { setProject } from "redux/projectSlice";

function BatchCreateBuildTemplate() {
  const batchCreateState = useSelector((state) => state.batchCreate);
  //const searchResultsState = useSelector((state) => state.searchResults).currentSearch;

  const project = batchCreateState?.project;

  const dispatch = useDispatch();

  const [isPriority, setIsPriority] = useState(null);
  const [isManual, setIsManual] = useState(null);
  //const [canBeCommitted, setCanBeCommitted] = useState(false);
  //const [isShowingCoreDetails, setIsShowingCoreDetails] = useState(false);

  useEffect(() => {
    if (project?.id) {
      isPriority === null && setIsPriority(project.project_IsPriorityClaimed);
      isManual === null && setIsManual(project.project_IsNewLinkedData);
    }
  }, [project]);

  useEffect(() => {
    if (project?.id && project.project_IsPriorityClaimed !== isPriority && isPriority !== null) {
      updateBatchProject(project.id, "project_IsPriorityClaimed", isPriority);
      dispatch(clearResults());
    }
  }, [isPriority]);

  useEffect(() => {
    if (project?.id && isManual !== null) {
      updateBatchProject(project.id, "project_IsNewLinkedData", isManual);
      dispatch(clearResults());
      if (isManual && project.linkedMatters.length > 0) dispatch(setLinkedMatters([]));
      if (!isManual && project.newLinkedMatters.length > 0) {
        project.newLinkedMatters.forEach((nlm) => {
          deleteBatchNewLinkedMatter(project.id, nlm.id);
        });
        dispatch(setNewLinkedMatters([]));
      }
    }
  }, [isManual]);

  const handleCanBeCommittedChange = (value) => {
    //setCanBeCommitted(value);
  };

  const handleManualPriorityChange = async (id) => {
    const isSettingManual = idsAreEqual(id, 2);
    setIsManual(isSettingManual);
    if (isSettingManual && project.newLinkedMatters.length === 0) {
      const newNewLinkedMatter = await createBatchNewLinkedMatter(project.id);
      dispatch(addNewLinkedMatter(newNewLinkedMatter));
    }
  };

  const handleCreateMasterTemplateClick = async () => {
    const template = await createMasterTemplate(project);
    dispatch(setBatchCreateProject(template));
    //    dispatch(setProject(template));
    //setIsShowingCoreDetails(true);
  };

  const renderSearchAndResults = (isPriority) => {
    return <BatchCreateMatterSearch isPriority={isPriority} onCanBeCommittedChange={handleCanBeCommittedChange} />;
  };

  const renderPriority = () => {
    return (
      <>
        <div className="section__decision">
          <RadioHorizontal
            onRadioChange={(id) => handleManualPriorityChange(id)}
            optionsArray={[
              { id: 1, displayValue: "Link to Existing Record(s)" },
              { id: 2, displayValue: "Enter Manually" },
            ]}
            defaultSelected={isManual === true ? 2 : 1}
          />
        </div>
        {isManual === true && <BatchCreateManualPriority onCanBeCommittedChange={handleCanBeCommittedChange} />}
        {isManual === false && renderSearchAndResults(true)}
      </>
    );
  };

  return (
    <>
      <div className="section__decision">
        <Switch
          text="Priority Claimed?"
          isOn={isPriority}
          onSwitchChange={() => setIsPriority(!isPriority)}
          label="Priority"
        />
      </div>
      {isPriority === true && renderPriority()}
      {isPriority === false && renderSearchAndResults(false)}
      {/* {canBeCommitted === true && ( */}
      <button onClick={handleCreateMasterTemplateClick}>{t("Confirm Selection and Create Master Template")}</button>
      {/* )} */}
    </>
  );
}

export default BatchCreateBuildTemplate;
