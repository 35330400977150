import Accounts from "./Accounts";
import MatterExport from "./MatterExport";
import Users from "./Users";

export default function CustomerDetails() {
  return (
    <>
      <Accounts />
      <Users />
      <MatterExport />
    </>
  );
}
