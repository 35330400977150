// Company related API calls here

import { fetchWrapper } from "utilities/fetchWrapper";

// Get a list of suggested company records based on the user search text
export async function getMatterCompaniesWithAccountId(customerId, accountId, searchWords) {
  const response = await fetchWrapper.get(
    `customers/${customerId}/companies?searchWords=${searchWords}&accountId=${accountId}`
  );
  return response;
}

export async function getMatterCompaniesFromSearchTerms(customerId, searchWords) {
  const response = await fetchWrapper.get(`customers/${customerId}/companies?searchWords=${searchWords}`);
  return response;
}
export async function getCompanyFromId(customerId, companyId) {
  const response = await fetchWrapper.get(`customers/${customerId}/companies/${companyId}`);
  return response;
}
