import { addToast, deleteToast } from "redux/appSlice";
import store from "redux/store";
import { severities } from "./constants";
import { nanoid } from "nanoid";

export function displayToast(message, severity) {
  const id = nanoid();
  store.dispatch(addToast({ id, message, severity }));
  if (severity === severities.INFO) {
    setTimeout(() => {
      store.dispatch(deleteToast(message));
    }, 10000);
  }
}
