import { createSlice } from "@reduxjs/toolkit";
import { filterOutOnId, findIndexById, idsAreEqual } from "utilities/stringAndArray";

// Redux global state component for batch record creation
export const batchCreateSlice = createSlice({
  name: "batchCreate",
  initialState: {
    project: null,
    availableCountries: [],
  },
  reducers: {
    // ACTIVE PROJECT
    setBatchCreateProject: (state, action) => {
      state.project = action.payload;
    },
    updateProjectField: (state, action) => {
      const { fieldName, value } = action.payload;
      state.project[fieldName] = value;
    },

    // setCustomerId: (state, action) => {
    //   debugger;
    //   state.project.customerId = action.payload;
    // },

    // MASTER RECORD
    setAvailableCountries: (state, action) => {
      state.availableCountries = action.payload;
    },
    setLinkedMatters: (state, action) => {
      const project = { ...state.project };
      project.linkedMatters = action.payload;
      state.project = project;
    },
    setNewLinkedMatters: (state, action) => {
      const project = { ...state.project };
      project.newLinkedMatters = action.payload;
      state.project = project;
    },
    addNewLinkedMatter: (state, action) => {
      state.project.newLinkedMatters.push(action.payload);
    },
    updateNewLinkedMatter: (state, action) => {
      const { newLinkedMatterId, changedFieldName, value } = action.payload;
      const newLinkedMatterIndex = state.project.newLinkedMatters.findIndex((matter) =>
        idsAreEqual(matter.id, newLinkedMatterId)
      );
      if (newLinkedMatterIndex !== -1) {
        state.project.newLinkedMatters[newLinkedMatterIndex] = {
          ...state.project.newLinkedMatters[newLinkedMatterIndex],
          [changedFieldName]: value,
        };
      }
    },
    deleteNewLinkedMatter: (state, action) => {
      state.project.newLinkedMatters = filterOutOnId(state.project.newLinkedMatters, action.payload);
    },
    setNewLinkedCountryId: (state, action) => {
      const project = { ...state.project };
      project.project_NewLinkedCountryId = action.payload;
      state.project = project;
    },

    // COMPANY LINKS
    setCompanyLinks: (state, action) => {
      const project = { ...state.project };
      project.companyLinks = action.payload;
      state.project = project;
    },
    deleteCompanyLink: (state, action) => {
      state.project.companyLinks = filterOutOnId(state.project.companyLinks, action.payload);
    },

    // COMPANY CONTACT LINKS
    addCompanyLinkContact: (state, action) => {
      const companyIndex = findIndexById(state.project.companyLinks, action.payload.companyId);
      if (companyIndex !== -1) {
        state.project.companyLinks[companyIndex].contactLinks.push(action.payload.contact);
      }
    },
    updateCompanyLinkContact: (state, action) => {
      const { companyId, contactId, changedFieldName, value } = action.payload;
      const companyIndex = findIndexById(state.project.companyLinks, companyId);
      if (companyIndex !== -1) {
        const contactIndex = findIndexById(state.project.companyLinks[companyIndex].contactLinks, contactId);
        if (contactIndex !== -1) {
          state.project.companyLinks[companyIndex].contactLinks[contactIndex] = {
            ...state.project.companyLinks[companyIndex].contactLinks[contactIndex],
            [changedFieldName]: value,
          };
        }
      }
    },
    deleteCompanyLinkContact: (state, action) => {
      const { companyId, contactId } = action.payload;
      const companyIndex = findIndexById(state.project.companyLinks, companyId);
      if (companyIndex !== -1) {
        state.project.companyLinks[companyIndex].contactLinks = state.project.companyLinks[
          companyIndex
        ].contactLinks.filter((contact) => !idsAreEqual(contact.id, contactId));
      }
    },

    // IMAGES
    addImage: (state, action) => {
      state.project.images.push(action.payload);
    },
    updateImageField: (state, action) => {
      const { imageId, changedFieldName, value } = action.payload;
      const imageIndex = findIndexById(state.project.images, imageId);
      if (imageIndex !== -1) {
        // Immutably update the specific field of the image
        state.project.images[imageIndex] = {
          ...state.project.images[imageIndex],
          [changedFieldName]: value,
        };
      }
    },
    deleteImage: (state, action) => {
      state.project.images = filterOutOnId(state.project.images, action.payload);
    },

    // GOODS
    addGood: (state, action) => {
      state.project.goods.push(action.payload);
    },
    updateGoodField: (state, action) => {
      const { goodId, changedFieldName, value } = action.payload;
      const goodIndex = findIndexById(state.project.goods, goodId);
      if (goodIndex !== -1) {
        state.project.goods[goodIndex] = {
          ...state.project.goods[goodIndex],
          [changedFieldName]: value,
        };
      }
    },
    deleteGood: (state, action) => {
      state.project.goods = filterOutOnId(state.project.goods, action.payload);
    },

    // JURISDICTIONS

    setCountryGroups: (state, action) => {
      const project = { ...state.project };
      project.countryGroups = action.payload;
      state.project = project;
    },

    addCountry: (state, action) => {
      state.project.countries.push(action.payload);
    },
    updateCountryFieldValue: (state, action) => {
      //debugger;
      const { countryId, value, fieldName } = action.payload;
      const countryIndex = findIndexById(state.project.countries, countryId);
      //const countryIndex = state.project.countries.findIndex((country) => idsAreEqual(country.id, countryId));
      //const countryIndex = state.project.countries.findIndex((country) => country.id === countryId);
      if (countryIndex !== -1) {
        state.project.countries[countryIndex] = {
          ...state.project.countries[countryIndex],
          [fieldName]: value,
        };
      }
    },
    removeCountry: (state, action) => {
      state.project.countries = filterOutOnId(state.project.countries, action.payload);
    },

    // SUMMARY

    setDraftRecords: (state, action) => {
      state.project.draftRecords = action.payload;
    },

    clearDraftRecords: (state) => {
      state.project.draftRecords = [];
    },

    updateDraftRecordFieldValue: (state, action) => {
      const { draftRecordId, value, fieldName } = action.payload;
      const recordIndex = findIndexById(state.project.draftRecords, draftRecordId);
      if (recordIndex !== -1) {
        state.project.draftRecords[recordIndex] = {
          ...state.project.draftRecords[recordIndex],
          [fieldName]: value,
        };
      }
    },
  },
});

export const {
  deleteProject,
  setBatchCreateProject,
  updateProjectField,
  setLinkedMatters,
  setNewLinkedMatters,
  addNewLinkedMatter,
  updateNewLinkedMatter,
  deleteNewLinkedMatter,
  setAvailableCountries,
  setNewLinkedCountryId,
  setCompanyLinks,
  addCompanyLinkContact,
  updateCompanyLinkContact,
  deleteCompanyLinkContact,
  deleteCompanyLink,
  addImage,
  updateImageField,
  deleteImage,
  addGood,
  updateGoodField,
  deleteGood,
  setCountryGroups,
  addCountry,
  updateCountryFieldValue,
  removeCountry,
  setDraftRecords,
  clearDraftRecords,
  updateDraftRecordFieldValue,
} = batchCreateSlice.actions;

export default batchCreateSlice.reducer;
