import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { findById, hasMatchingId } from "utilities/stringAndArray";
import Checkbox from "components/global/Checkbox";
import PropTypes from "prop-types";
import { searchMatterFieldsQualified } from "api/search";
import { getMatterTypeIds } from "utilities/simpleSearch";

function SuggestedFieldPopupMatterFields({
  field,
  onValueChange,
  valueArray,
  qualified,
  qualifiedDictionary,
  onIsApplicableChange,
}) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const appState = useSelector((state) => state.app);

  const [existingResultsObjectList] = useState(
    qualified && valueArray ? valueArray.map((matterId) => Number(matterId)) : []
  );
  const [suggestedResultsObjectList, setSuggestedResultsObjectList] = useState([]); // For qualified searches (e.g. "Owner") - builds a list of matching objects
  const [checkedOptions, setCheckedOptions] = useState(
    qualified && valueArray ? valueArray.map((matterId) => Number(matterId)) : []
  );

  useEffect(() => {
    async function fetchData() {
      const matterTypeIds = getMatterTypeIds();
      const response = await searchMatterFieldsQualified(
        appState.customerId,
        field.fieldName,
        simpleSearch.fieldText,
        matterTypeIds
      );
      setSuggestedResultsObjectList(response);
    }
    fetchData();
  }, [appState.customerId, field.fieldName, simpleSearch.fieldText]);

  const handleSuggestedMatterFieldClick = (id) => {
    let checkedOptionsTemp = [...checkedOptions];
    let index = checkedOptionsTemp.findIndex((co) => co === id);
    if (index >= 0) {
      checkedOptionsTemp.splice(index, 1);
    } else {
      checkedOptionsTemp = [...checkedOptionsTemp, id];
    }
    setCheckedOptions(checkedOptionsTemp);
    onIsApplicableChange(checkedOptionsTemp.length > 0);

    if (checkedOptionsTemp.length > 0) {
      let localQualifiedDictionary = qualifiedDictionary ?? [];
      localQualifiedDictionary = [
        ...localQualifiedDictionary,
        ...suggestedResultsObjectList.filter((item) => hasMatchingId(checkedOptionsTemp, item.id)),
      ];

      const localQualifiedDictionaryDeDuped = [
        ...new Map(localQualifiedDictionary.map((item) => [item["id"], item])).values(),
      ];

      onValueChange(checkedOptionsTemp, true, localQualifiedDictionaryDeDuped);
    }
  };
  return (
    <div className="suggested-field-popup__companies">
      {suggestedResultsObjectList?.map((item) => {
        const isChecked = checkedOptions.some((co) => co === item.id);

        return (
          <div className="suggested-field-popup__option-container" key={item.id}>
            <Checkbox isChecked={isChecked} onCheckChange={(e) => handleSuggestedMatterFieldClick(item.id)} />
            <div className="suggested-field-popup__option-texts">
              <div className="suggested-field-popup__option">
                <span className="suggested-field-popup__option suggested-field-popup__option--no-match">
                  {item.fullDescription}
                </span>
              </div>
            </div>
          </div>
        );
      })}
      {existingResultsObjectList
        .filter((matterId) => !hasMatchingId(suggestedResultsObjectList, matterId))
        .map((matterId) => {
          const isChecked = hasMatchingId(checkedOptions, matterId);
          const item = findById(qualifiedDictionary, matterId);
          const displayValue = item?.fullDescription ?? matterId;

          return (
            <div className="suggested-field-popup__option-container" key={matterId}>
              <Checkbox isChecked={isChecked} onCheckChange={(e) => handleSuggestedMatterFieldClick(matterId)} />
              <div className="suggested-field-popup__option-texts">
                <div className="suggested-field-popup__option">
                  <span className="suggested-field-popup__option suggested-field-popup__option--no-match">
                    {displayValue}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

SuggestedFieldPopupMatterFields.propTypes = {
  field: PropTypes.object,
  valueArray: PropTypes.array,
  qualified: PropTypes.bool,
  qualifiedDictionary: PropTypes.array,
  onValueChange: PropTypes.func,
  onIsApplicableChange: PropTypes.func,
};

export default SuggestedFieldPopupMatterFields;
