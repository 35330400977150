import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SuperSelect from "components/global/SuperSelect";
import { getMatterCompaniesFromSearchTerms, getMatterCompaniesWithAccountId } from "api/company";
import { getMatterContactsExtended } from "api/contact";
import { getCountryCodeAlpha2FromIsoCode } from "utilities/countries";
import {
  formatControlId,
  compareByDisplayValueAsc,
  includesId,
  idsAreEqual,
  hasMatchingId,
  findById,
} from "utilities/stringAndArray";
import { createMatterRecord, getMatterById, saveNewChildObject } from "api/matter";
import { useDispatch, useSelector } from "react-redux";
import { addRecord, setComparing, setElementIdToScrollTo, setRecordPinned } from "redux/matterSlice";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { t } from "locale/dictionary";
import { displayToast } from "utilities/toast";
import { matterTypes, severities } from "utilities/constants";
import { setNewCompanyPopupRecordId } from "redux/projectSlice";

// Container Component for the display of the name field for Company and Legal Team Link Contacts
function PanelFieldNameSearch({
  keyProps,
  onChange,
  searchOnRecord = true,
  initialSearchValue,
  isBatchImport,
  existingIdsForLinkType,
  refreshSuggestions,
}) {
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale);

  const [value, setValue] = useState(keyProps.data ?? "");

  const [isChanged, setIsChanged] = useState(false);
  const [suggestedResultsObjectList, setSuggestedResultsObjectList] = useState([]);
  const [suggestedResultsDisplayList, setSuggestedResultsDisplayList] = useState([]);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [isInitialValueLoad, setIsInitialValueLoad] = useState(true);

  const isCompanyNameSearch = keyProps.field.fieldName === "matterCompanyLink_Name";
  const isContactNameSearch = keyProps.field.fieldName === "matterContactLink_FullName";

  const controlId = formatControlId(keyProps);

  useEffect(() => {
    setValue(keyProps.data ?? "");
  }, [keyProps.data]);

  useEffect(() => {
    if (isInitialValueLoad && initialSearchValue) {
      onTextChange(initialSearchValue);
      setIsChanged(false);
    }
    setIsInitialValueLoad(false);

    // If returning to this import project record we need to reload any previously selected destination matters (mapped companies to incoming owners or agents)
    if (isBatchImport && keyProps.destinationMatterId) {
      getMatterById(keyProps.destinationMatterId).then((response) => {
        if (response) {
          const companyObject = {
            matterId: response.id,
            name: response.matter_Name,
            city: response.matter_City,
            state: response.matter_State,
            countryId: response.countries[0],
          };
          setSuggestedResultsObjectList([companyObject]);
          const displayList = formatDisplayList([companyObject]);
          setSuggestedResultsDisplayList(displayList);
          // TODO: This doesn't always work - the SuperSelect does not always visually update to show the selected option. Reason unknown.
          setSelectedOptionId(companyObject.matterId);
        }
      });
    }
  }, [isBatchImport, keyProps.destinationMatterId, initialSearchValue, refreshSuggestions]);

  // console.log(selectedOptionId, typeof selectedOptionId);
  // console.log(suggestedResultsDisplayList.map((result) => `${result.id}, ${typeof result.id}`));

  const formatDisplayList = (response, isContact) => {
    return response.map((entity) => {
      const id = entity.matterId;
      const displayValue = isContact
        ? `${entity.givenName} ${entity.familyName} (${entity.companyName})`
        : `${entity.name ?? ""} - ${entity.city ? `${entity.city},` : ""} ${entity.state ? `${entity.state},` : ""} ${
            getCountryCodeAlpha2FromIsoCode(entity.countryId) ?? ""
          }`;
      return { id, displayValue };
    });
  };

  const onTextChange = (newValue) => {
    const handleNameSearchResponse = (response, isContact) => {
      // filter out existing links
      let filteredResponse = response;
      if (existingIdsForLinkType && existingIdsForLinkType.length > 0) {
        filteredResponse = response.filter((result) => !hasMatchingId(existingIdsForLinkType, result.matterId));
      }
      setSuggestedResultsObjectList(filteredResponse);
      const displayList = formatDisplayList(filteredResponse, isContact);
      setSuggestedResultsDisplayList(displayList);
      // if (isBatchImport && displayList.length > 0) {
      //   setSelectedOptionId(displayList?.[0]?.id ?? null);
      // }
    };

    if (newValue === value && isChanged) setIsChanged(false);
    if (newValue !== value && !isChanged) setIsChanged(true);

    setValue(newValue);
    if (newValue.length < 3) {
      setSuggestedResultsObjectList([]);
      setSuggestedResultsDisplayList([]);
      setSelectedOptionId(null);
      return;
    }

    if (!searchOnRecord) {
      getMatterCompaniesFromSearchTerms(keyProps.record.customerId, newValue).then((response) => {
        handleNameSearchResponse(response);
      });
      return;
    }

    if (isCompanyNameSearch) {
      getMatterCompaniesWithAccountId(appState.customerId, keyProps.record.accountId, newValue).then((response) => {
        handleNameSearchResponse(response);
      });
    }

    if (isContactNameSearch) {
      getMatterContactsExtended(keyProps.record.customerId, keyProps.record.accountId, newValue).then((response) => {
        handleNameSearchResponse(response, true);
      });
    }
  };

  const onSetSelectedResult = async (id) => {
    if (idsAreEqual(id, matterTypes.COMPANY)) {
      let matterTypes = getAllLookupValuesForSource("MatterTypes");
      if (matterTypes) matterTypes = matterTypes.sort(compareByDisplayValueAsc);
      const matterType = findById(matterTypes, id);
      const matterTypeName = locale.translations[matterType.translationCode];
      const matter = await createMatterRecord(matterType.id, value);
      if (matter.id) {
        const body = {
          matterCompanyLink_Address1: "",
          matterCompanyLink_City: "",
          matterCompanyLink_Country: null,
          matterCompanyLink_LinkTypeId: keyProps?.rowData?.matterCompanyLink_LinkTypeId,
          matterCompanyLink_MatterId: matter.id,
          matterCompanyLink_Name: matter.matter_Name,
          matterCompanyLink_PostalCode: "",
          matterCompanyLink_State: "",
        };

        if (keyProps.isProject) {
          dispatch(setNewCompanyPopupRecordId(matter.id));
          onChange(keyProps.field.fieldName, { matterId: matter.id });
        } else {
          saveNewChildObject(keyProps.record.id, [], "companyLinks", body);
          displayToast(`${t("Created new")} ${matterTypeName}`, severities.INFO);
          dispatch(addRecord({ matter, selected: false, replace: false }));
          dispatch(setElementIdToScrollTo({ elementId: "panel_Company_Links" }));
          dispatch(setRecordPinned({ recordId: keyProps.record.id, newPinnedStatus: true }));
          dispatch(setComparing({ isComparing: true, numberComparing: 2, isNewRecord: true }));
        }
      } else {
        displayToast(`${t("Error creating new")} ${matterTypeName}`, severities.ERROR);
      }
    } else {
      onChange(
        keyProps.field.fieldName,
        suggestedResultsObjectList.find((result) => idsAreEqual(result.matterId, id))
      );
      setSelectedOptionId(id);
    }
  };
  let placeholderText = `Search for ${isCompanyNameSearch ? "companies" : "people"} here`;

  if (isBatchImport && !isChanged)
    placeholderText =
      suggestedResultsDisplayList.length > 0
        ? "Select or Search For Companies Here"
        : "No Suggestions Found. Search Here For Companies";

  return (
    <SuperSelect
      id={controlId}
      options={
        isChanged && isCompanyNameSearch
          ? [...suggestedResultsDisplayList, { id: matterTypes.COMPANY, displayValue: " Create New Company" }]
          : [...suggestedResultsDisplayList]
      }
      selectedOptionId={selectedOptionId}
      onChange={onSetSelectedResult}
      tabIndex={0}
      onTextChangeUpdateList={onTextChange}
      placeholderText={placeholderText}
    />
  );
}

PanelFieldNameSearch.propTypes = {
  keyProps: PropTypes.object,
  onChange: PropTypes.func,
  searchOnRecord: PropTypes.bool,
  initialSearchValue: PropTypes.string,
  isBatchImport: PropTypes.bool,
  existingIdsForLinkType: PropTypes.array,
};

export default PanelFieldNameSearch;
