import ProjectSection from "../ProjectSection";
import { useDispatch, useSelector } from "react-redux";
import { t } from "locale/dictionary";
import { updateBatchImportCompanyMapping } from "api/batchImport";
import DataPanelFieldNameSearch from "components/content/matter-record/field-display-types/PanelFieldNameSearch";
import { linkTypes } from "utilities/constants";
import MatterRecordUniversalPopup from "components/content/matter-record/UniversalPopup";
import { setNewCompanyPopupRecordId } from "redux/projectSlice";
import { useState } from "react";

export default function BatchImportCompanyLinksMapping() {
  const projectState = useSelector((state) => state.project);
  const batchImportState = useSelector((state) => state.batchImport);
  const appState = useSelector((state) => state.app);

  const [refreshSuggestions, setRefreshSuggestions] = useState(false);

  const dispatch = useDispatch();

  const handleCompanyMappingChange = (companyMap, fieldName, matter) => {
    updateBatchImportCompanyMapping(
      appState.customerId,
      batchImportState.project.id,
      companyMap.id,
      "destinationMatterId",
      companyMap.destinationMatterId,
      matter.matterId
    );
  };

  const handleNewCompanyPopupClose = () => {
    setRefreshSuggestions(!refreshSuggestions);
    dispatch(setNewCompanyPopupRecordId(null));
  };

  const renderCompanyLinksTableHeader = (
    <thead>
      <tr className="data-panel__table-row">
        <th className="data-panel__table-header">{t("Company Name")}</th>
        <th className="data-panel__table-header">{t("Company Address")}</th>
        <th className="data-panel__table-header">{t("Count")}</th>
        <th className="data-panel__table-header">{t("CedarIP Company Suggestions")}</th>
      </tr>
    </thead>
  );

  const renderCompanyLinksTableBody = (linkTypeId) => {
    const companyMaps = batchImportState?.project?.companyMaps?.filter(
      (companyMap) => companyMap.linkTypeId === linkTypeId
    );
    return companyMaps?.map((companyMap) => {
      return (
        <tr key={companyMap.id} className="data-panel__table-row">
          <td className="data-panel__table-cell data-panel__table-cell--15">{companyMap.name}</td>
          <td className="data-panel__table-cell data-panel__table-cell--25">{companyMap.address1}</td>
          <td className="data-panel__table-cell data-panel__table-cell--10">{companyMap.count}</td>
          <td className="data-panel__table-cell data-panel__table-cell--25">
            <div className="table-cell__field-container--select">
              <DataPanelFieldNameSearch
                searchOnRecord={false}
                initialSearchValue={companyMap.name}
                isBatchImport={true}
                keyProps={{
                  record: { customerId: appState.customerId },
                  controlId: `company-search-${companyMap.id}`,
                  field: { fieldName: "matterCompanyLink_Name" },
                  isProject: true,
                  destinationMatterId: companyMap.destinationMatterId,
                }}
                refreshSuggestions={refreshSuggestions}
                onChange={(fieldName, matterId) => handleCompanyMappingChange(companyMap, fieldName, matterId)}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderCompanyLinksSection = (linkTypeId) => {
    return (
      <ProjectSection title={t(linkTypeId === linkTypes.OWNER ? "Owners" : "Agents")}>
        <div className="data-panel__table data-panel__table--100">
          <div className="data-panel__table-scrollable-container">
            <table className="data-panel__table-inner">
              {renderCompanyLinksTableHeader}
              <tbody>{renderCompanyLinksTableBody(linkTypeId)}</tbody>
            </table>
          </div>
        </div>
      </ProjectSection>
    );
  };

  //MAIN RENDER
  return (
    <>
      {renderCompanyLinksSection(linkTypes.OWNER)}
      {renderCompanyLinksSection(linkTypes.AGENT)}
      {projectState?.newCompanyPopupRecordId && (
        <MatterRecordUniversalPopup
          recordId={projectState.newCompanyPopupRecordId}
          onClose={handleNewCompanyPopupClose}
        />
      )}
    </>
  );
}
