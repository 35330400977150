import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { baseMenuItems, getAxisKeys, getChartLabel } from "utilities/charting";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import drilldown from "highcharts/modules/drilldown";
import { compareByName, idsAreEqual } from "utilities/stringAndArray";
import { createDrillFilter } from "utilities/reportEditor";

highcharts3d(Highcharts);
drilldown(Highcharts);

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

function getSeries(seriesKeyValues, pivotData, addDrill, drillSeries, seriesKey, drillKey, onClick) {
  let series = [];
  if (seriesKeyValues.length > 0) {
    series = seriesKeyValues.map((seriesKeyValue) => {
      const value = pivotData
        .filter((resultRow) => resultRow.keys[seriesKeyValue.keyColumn] === seriesKeyValue.value)
        .reduce((accumulator, currentValue) => accumulator + currentValue.values[0], 0);

      const point = { name: seriesKeyValue.label ?? seriesKeyValue.value, rootkey: seriesKeyValue.value, y: value };
      if (addDrill) {
        point.drilldown = point.name ? point.name : "__UNSPECIFIED";
        const drillData = pivotData
          .filter((resultRow) => resultRow.keys[seriesKeyValue.keyColumn] === seriesKeyValue.value)
          .map((resultRow) => ({
            name: getChartLabel(resultRow, drillKey),
            rootkey: seriesKeyValue.value,
            drillkey: resultRow.keys[drillKey.keyColumn],
            y: resultRow.values[0],
          }))
          .sort(compareByName);

        drillSeries.push({
          name: point.name,
          id: point.drilldown,
          data: drillData,
          events: {
            click: function (event) {
              if (onClick) {
                const point = event.point.options;
                const filters = [];
                filters.push(createDrillFilter(seriesKey.keyDataFieldName, point.rootkey));
                filters.push(createDrillFilter(drillKey.keyDataFieldName, point.drillkey));
                onClick(filters);
              }
            },
          },
        });
      }

      return point;
    });
  }
  return [{ data: series, colorByPoint: true }];
}

function HighchartPie({ widgetId, output, onClick }) {
  const chartRef = useRef(null);
  const [options, setOptions] = useState(null);
  const [displayTotal, setDisplayTotal] = useState(0);

  const homeState = useSelector((state) => state.home);

  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (chartRef?.current) {
      const chart = chartRef.current.chart;
      if (chart) {
        chart.reflow();
      }
    }
  }, [isZoomed]);

  useEffect(() => {
    if (idsAreEqual(homeState.zoomedWidgetId, widgetId)) {
      if (!isZoomed) setIsZoomed(true);
    } else if (isZoomed) {
      setIsZoomed(false);
    }
  }, [homeState.zoomedWidgetId]);

  useEffect(() => {
    if (chartRef?.current) {
      const chart = chartRef.current.chart;
      if (chart) {
        chart.setTitle({ text: `Total: ${displayTotal} records` });
      }
    }
  }, [displayTotal]);

  useEffect(() => {
    const categoryKeyValues = getAxisKeys(output.xAxisColumns, output.rowData);
    const seriesKeyValues = getAxisKeys(output.yAxisColumns, output.rowData);
    const addDrill =
      (output.xAxisColumns.length > 0 && output.yAxisColumns.length > 0) ||
      output.xAxisColumns.length > 1 ||
      output.yAxisColumns.length > 1;
    const drillSeries = addDrill ? [] : null;

    let seriesKey;
    if (output.yAxisColumns.length > 0) {
      seriesKey = output.yAxisColumns[0];
    } else if (output.xAxisColumns.length > 0) {
      seriesKey = output.xAxisColumns[0];
    }

    let drillKey = null;
    if (addDrill) {
      if (output.yAxisColumns.length > 0) {
        drillKey = output.xAxisColumns.length > 0 ? output.xAxisColumns[0] : output.yAxisColumns[1];
      } else {
        drillKey = output.xAxisColumns[1];
      }
    }

    let series = [];
    let categories = [];
    if (output.yAxisColumns.length > 0) {
      series = getSeries(seriesKeyValues, output.rowData, addDrill, drillSeries, seriesKey, drillKey, onClick);
      categories = seriesKeyValues.map((axis) => axis.label ?? axis.value);
    } else if (output.xAxisColumns.length > 0) {
      series = getSeries(categoryKeyValues, output.rowData, addDrill, drillSeries, seriesKey, drillKey, onClick);
      categories = categoryKeyValues.map((axis) => axis.label ?? axis.value);
    }

    const drilldown = addDrill
      ? {
          series: drillSeries,
          breadcrumbs: {
            events: {
              click: function (button, breadcrumbs) {
                if (breadcrumbs.level > 0 && breadcrumbs.levelOptions) {
                  const filters = [];
                  filters.push(createDrillFilter(seriesKey.keyDataFieldName, breadcrumbs.levelOptions.rootkey));
                  onClick(filters);
                }
              },
            },
          },
        }
      : {};

    let plotOptionEvents = {};
    if (!addDrill) {
      plotOptionEvents = {
        click: function (event) {
          if (onClick) {
            const point = event.point.options;
            const filters = [];
            filters.push(createDrillFilter(seriesKey.keyDataFieldName, point.rootkey));
            onClick(filters);
          }
        },
      };
    }
    setOptions({
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
        },
        events: {
          render: function (chart, event) {
            const data = chart?.target?.series[0].data;
            if (data) {
              setDisplayTotal(data.reduce((accumulator, element) => accumulator + element.y, 0));
            } else {
              setDisplayTotal(0);
            }
          },
        },
      },
      title: {
        style: { fontSize: "1.25rem", fontWeight: "bold" },
      },
      xAxis: {
        categories: categories,
      },
      series: series,
      drilldown: drilldown,
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.name} ({point.y})",
          },
          events: plotOptionEvents,
        },
        pie: {
          innerSize: 100,
          depth: 45,
        },
      },

      tooltip: {
        headerFormat: "<b>{point.name}</b><br/>",
        pointFormat: "{point.name}: {point.y}",
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: baseMenuItems,
          },
        },
      },
    });
  }, [output, onClick, isZoomed]);

  return options ? (
    <HighchartsReact
      ref={chartRef}
      containerProps={{
        style: { height: "100%", width: isZoomed ? "100%" : document.documentElement.clientWidth > 1800 ? 425 : 350 },
      }}
      highcharts={Highcharts}
      options={options}
      allowChartUpdate={false}
    />
  ) : null;
}

HighchartPie.propTypes = {
  widgetId: PropTypes.number,
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default HighchartPie;
