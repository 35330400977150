import Border from "./Border";

function BorderPicker({ inputRef, border, setBorder, show, setShow }) {
  const handleClick = (type) => {
    setBorder(type);
    setShow(false);
  };

  return (
    <div className="toolbar__border-picker" style={{ display: show ? "block" : "none" }} ref={inputRef}>
      <div className="border-picker__grid">
        <div>
          <Border onSelect={handleClick} type="bottom" />
        </div>
        <div>
          <Border onSelect={handleClick} type="top" />
        </div>
        <div>
          <Border onSelect={handleClick} type="left" />
        </div>
        <div>
          <Border onSelect={handleClick} type="right" />
        </div>
        <div>
          <Border onSelect={handleClick} type="all" />
        </div>
        <div>
          <Border onSelect={handleClick} type="outside" />
        </div>
        <div>
          <Border onSelect={handleClick} type="none" />
        </div>
      </div>
    </div>
  );
}

export default BorderPicker;
