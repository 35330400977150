import { getCustomerById } from "api/security";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { setCurrentAccount, setCurrentCustomer, setCurrentUserGroup } from "redux/securitySlice";
import SecurityHeader from "./SecurityHeader";
import {
  compareByObjectFieldName,
  compareByReferenceArrayFieldName,
  findById,
  idsAreEqual,
} from "utilities/stringAndArray";

export default function SecurityContainer() {
  const dispatch = useDispatch();
  const security = useSelector((state) => state.security);
  const location = useLocation();
  const { customerId, accountId, userGroupId } = useParams();

  useEffect(() => {
    const getCustomerData = async () => {
      if (customerId && customerId !== security.currentCustomer?.id) {
        const currentCustomerData = await getCustomerById(customerId);
        const currentCustomer = formatCurrentCustomerData(currentCustomerData);
        const currentAccount = findById(currentCustomer?.accounts, accountId);
        const currentUserGroup = findById(currentAccount?.userGroups, userGroupId);
        dispatch(setCurrentCustomer(currentCustomer));
        dispatch(setCurrentAccount(currentAccount));
        dispatch(setCurrentUserGroup(currentUserGroup));
      }
    };
    getCustomerData();
  }, [location]);

  const formatCurrentCustomerData = (currentCustomer) => {
    //get linked accounts for each user
    const currentCustomerUsersLinkedAccounts = currentCustomer.users.map((user) => ({
      ...user,
      linkedAccountsCount: getAccountCount(currentCustomer, user),
    }));
    //get user count for each user group
    let accounts = currentCustomer.accounts.map((account) => {
      const userGroups = account.userGroups.map((group) => {
        const usersInGroup = account.users.filter((user) => idsAreEqual(user.userGroupId, group.id));
        const userCount = usersInGroup.length;
        return { ...group, userCount };
      });
      //sort account users by full name alphabetically
      const sortedUsers = compareByReferenceArrayFieldName(
        "fullName",
        account.users,
        currentCustomer.users,
        "customerUserId",
        "id",
        true
      );
      //sort user groups by name alphabetically
      return { ...account, users: sortedUsers, userGroups: compareByObjectFieldName(userGroups, "name", true) };
    });
    return {
      ...currentCustomer,
      accounts: accounts,
      //sort users by full name alphabetically
      users: compareByObjectFieldName(currentCustomerUsersLinkedAccounts, "fullName", true),
    };
  };

  const getAccountCount = (currentCustomer, user) => {
    let linkedAccounts = 0;
    currentCustomer?.accounts?.forEach((account) =>
      account?.users?.forEach((accountUser) => {
        if (idsAreEqual(accountUser.customerUserId, user.id)) linkedAccounts++;
      })
    );
    return linkedAccounts;
  };

  return (
    <div className="security">
      <SecurityHeader />
      <div className="security__body">
        {(location.pathname === "/customers" || security.currentCustomer?.id) && <Outlet />}
      </div>
    </div>
  );
}
