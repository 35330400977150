// Component for displaying a spinner animation
function Spinner() {
  return (
    <div className="modal__spinner">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
export default Spinner;
