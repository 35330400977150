import { useEffect, useRef, useState } from "react";

// Debounce hook that only triggers the callback when the value has not changed for the specified amount of time.
// This is useful for input fields that should only trigger a callback after the user has stopped typing for a certain amount of time.
// The value parameter is the value that should be debounced.
// The timeThreshold parameter specifies the amount of time in milliseconds that should pass before the callback is triggered.
// The callback parameter is the function that should be called when the value has not changed for the specified amount of time.
// Returns the debounced value.
// Note that the onChange callback is not called for the initial value i.e. when the component is mounted.
function useDebounce(value, timeThreshold, callback) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const isFirstRender = useRef(true);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (value !== debouncedValue) {
        setDebouncedValue(value);
        if (callback) {
          callback(value);
        }
      }
    }, timeThreshold);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [value, debouncedValue, timeThreshold, callback]);

  return debouncedValue;
}

export default useDebounce;
