import { t } from "locale/dictionary";
import { useDispatch, useSelector } from "react-redux";
import BatchImportSearchPanel from "./BatchImportSearchPanel";
import BatchImportSearchResults from "./BatchImportSearchResults";
import { useEffect } from "react";
import { setViewTypeSimpleSearch } from "redux/simpleSearchSlice";
import { clearResults, setResults, setSelected, setViewTypeSearchResults } from "redux/searchResultsSlice";
import { linkTypes, projectStatuses, projectTypes, searchViewTypes } from "utilities/constants";
import LoadingModal from "components/global/LoadingModal";
import { parseSearchResponse } from "utilities/searchResults";
import BatchImportMappingContainer from "./BatchImportMappingContainer";
import ProjectSection from "../ProjectSection";

import { setBatchImportProject } from "redux/batchImportSlice";

import ProjectGenerateMatters from "../ProjectGenerateMatters";
import ProjectBrowser from "../ProjectBrowser";
import { idsAreEqual } from "utilities/stringAndArray";

export default function BatchImportContainer() {
  const batchImportState = useSelector((state) => state.batchImport);
  const searchResultsState = useSelector((state) => state.searchResults);
  const projectState = useSelector((state) => state.project);
  const dispatch = useDispatch();

  const status = batchImportState?.project?.project_Status;

  // Number of records (for info display only on confirmation modal) is:
  // Batch Import: the number of company maps with link type OWNER (this is messy, but API isn't returning a simple number)
  const numRecords = batchImportState?.project?.companyMaps?.filter((map) =>
    idsAreEqual(map.linkTypeId, linkTypes.OWNER)
  )?.length;

  useEffect(() => {
    dispatch(setViewTypeSimpleSearch(searchViewTypes.BATCH_IMPORT));
    dispatch(setViewTypeSearchResults(searchViewTypes.BATCH_IMPORT));
    dispatch(clearResults());
    dispatch(setBatchImportProject(null));
  }, []);

  useEffect(() => {
    if (batchImportState?.project?.importSearchResultSet?.body?.length > 0) {
      //get and set results from saved search results from project
      const cols = parseSearchResponse(batchImportState?.project?.importSearchResultSet);
      dispatch(setResults({ cols, logId: batchImportState?.project?.importSearchResultSet.logId, pageSize: 10 }));
    }
  }, [batchImportState?.project?.id]);

  useEffect(() => {
    if (searchResultsState?.currentSearch?.results === null) return;

    // Set selected records from project.
    const cols = searchResultsState.currentSearch.results;
    const importIsSelectedData = cols.find((col) => col.fieldName === "import_IsSelected")?.data;
    if (!importIsSelectedData) return;

    // TODO - AGI - This is a hack by James, this is a translated string so works in English only
    const importKeys = importIsSelectedData
      .filter((item) => item.displayValue === "Yes")
      .map((item) => item.key.toString());

    dispatch(setSelected(importKeys ?? []));
  }, [searchResultsState?.currentSearch?.results]);

  return (
    <>
      <div className="batch-import">
        <h2>{t("Batch Import Records")}</h2>
        <div className="record__data-panel-list">
          <div className="data-panel-list__outer">
            {batchImportState?.project && projectState?.project?.id ? (
              <>
                <ProjectSection title="Trademark Search and Results">
                  <BatchImportSearchPanel />
                  {searchResultsState?.currentSearch?.results?.length > 0 && <BatchImportSearchResults />}
                </ProjectSection>
                {status >= projectStatuses.TEMPLATE_GENERATED && (
                  <>
                    <BatchImportMappingContainer />
                    {status !== projectStatuses.MATTER_GENERATED && <ProjectGenerateMatters numRecords={numRecords} />}
                  </>
                )}
              </>
            ) : (
              <ProjectBrowser projectTypeId={projectTypes.BATCH_IMPORT} />
            )}
          </div>
        </div>
      </div>
      {batchImportState?.isLoading && <LoadingModal />}
    </>
  );
}
