import { useState } from "react";
import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import Switch from "components/global/Switch";
import { stringToBoolean } from "utilities/boolean";

function SuggestedFieldPopupBoolean({ valueArray, onIsApplicableChange, onValueChange }) {
  const [boolValue, setBoolValue] = useState(stringToBoolean(valueArray[0]));

  const handleSwitchChange = () => {
    const newValue = !boolValue;
    setBoolValue(newValue);
    onValueChange([newValue]);
    onIsApplicableChange(true);
  };

  return (
    <Switch isOn={boolValue} text="No" onSwitchChange={handleSwitchChange} tabIndex={0}>
      {t("Yes")}
    </Switch>
  );
}

SuggestedFieldPopupBoolean.propTypes = {
  valueArray: PropTypes.array,
  onValueChange: PropTypes.func,
  onIsApplicableChange: PropTypes.func,
};

export default SuggestedFieldPopupBoolean;
