import { createSlice } from "@reduxjs/toolkit";
import { requiredDataTypes, validationStates } from "utilities/constants";
import {
  getFirstUnpinnedRecord,
  getLastUnpinnedRecord,
  getNextUnpinnedRecord,
  getPrevUnpinnedRecord,
} from "utilities/matter";
import { filterOutOnId, findById, findIndexById, hasMatchingId, idsAreEqual } from "utilities/stringAndArray";

function getRequiredFieldsByMatterType(requiredMatterData, matterTypeId) {
  // If requiredMatterData has no specified matterTypes, then all matterTypes apply, otherwise only include those with matching matterTypeId
  let specificRequiredMatterData = requiredMatterData.filter(
    (rmd) => rmd.matterTypeIds.length === 0 || hasMatchingId(rmd.matterTypeIds, matterTypeId)
  );
  const requiredFields = specificRequiredMatterData.map((rmd) => {
    return {
      fieldName: rmd.dataFieldName,
      parent: rmd.parent,
      type: rmd.type,
      requiredValue: rmd.value,
      translationCode: rmd.translationCode,
    };
  });
  return requiredFields;
}

// VALIDATION CORE FUNCTIONS

// FIELD type check
// ARRAY type check
function setFieldValidationState(record, fieldNameToUpdate, tableName, value, isEditing, isUpdated, rowId) {
  let fieldValidationStates = [];
  let requiredFields = null;

  // Non-error related informational feedback on editing and updating completion
  if (isEditing) fieldValidationStates.push({ state: validationStates.EDITING });
  if (isUpdated) {
    //console.log("isUpdated", isUpdated, fieldNameToUpdate);
    fieldValidationStates.push(
      rowId ? { state: validationStates.SUCCESS, rowId } : { state: validationStates.SUCCESS }
    );
  }

  // Error checking
  // First check if the field is mentioned in the required fields table
  if (record.hasOwnProperty(tableName) && fieldNameToUpdate === "contactLinks") {
    if (!value) value = record[tableName];
    requiredFields = record.requiredFields.filter((rf) => rf.parent === `/${tableName}/contactLinks`);
  }
  // Simple field-level validation check
  else if (record.hasOwnProperty(fieldNameToUpdate)) {
    if (!value) value = record[fieldNameToUpdate];
    requiredFields = record.requiredFields.filter((rf) =>
      fieldNameToUpdate === "countries" ? rf.parent === "/countries" : rf.fieldName === fieldNameToUpdate
    );
  }

  // Now run through any matches in the required fields table to see if the supplied field is failing validation
  requiredFields &&
    requiredFields.length > 0 &&
    requiredFields.forEach((requiredField) => {
      // Most fields - just check the supplied value is not null/empty vs the required part
      if (requiredField.type === requiredDataTypes.FIELD) {
        if (!value && record.requiredFields?.some((rf) => rf.fieldName === fieldNameToUpdate)) {
          fieldValidationStates.push({
            state: validationStates.ERROR,
            string: requiredField.translationCode,
            type: requiredField.type,
          });
        }
      }
      // ARRAY type (countries and contactLinks within actions) - .length checks for length of array instead of length of string
      else if (requiredField.type === requiredDataTypes.ARRAY) {
        let validationState = validationStates.OK;
        // Check for top level empty arrays (e.g. countries)
        if (!tableName && value && value.length === 0) validationState = validationStates.ERROR;
        // Check for empty contactLinks array within other tables (actions, and maybe later also comments, companyLinks)
        else if (
          fieldNameToUpdate === "contactLinks" &&
          value.length > 0 &&
          value.some((row) => row.contactLinks.length === 0)
        )
          validationState = validationStates.ERROR;

        fieldValidationStates.push({
          state: validationState,
          string: requiredField.translationCode,
          type: requiredField.type,
        });
      }
    });

  // Remove any other validation states if there is an error
  const errorValidationState = fieldValidationStates.find((fvs) => fvs.state === validationStates.ERROR);
  if (errorValidationState) fieldValidationStates = [errorValidationState];

  const sectionWithField = record.sections.find(
    (section) =>
      (!tableName || tableName === section.tableName) &&
      section.fields.some((field) => field.fieldName === fieldNameToUpdate)
  );
  const fieldInRedux = sectionWithField?.fields.find((field) => field.fieldName === fieldNameToUpdate);
  if (fieldInRedux) {
    if (rowId) {
      const otherRowValidations = fieldInRedux.validationStates?.filter((vs) => vs.rowId !== rowId);
      if (otherRowValidations) fieldValidationStates = [...fieldValidationStates, ...otherRowValidations];
    }
    fieldInRedux.validationStates = fieldValidationStates;
  }
}

// ARRAY_VALUE type check
function setTableValidationState(record, tableName, tableData, childTableName) {
  let fieldValidationStates = [];
  let requiredFields = record.requiredFields.filter((rf) => rf.type === requiredDataTypes.ARRAY_VALUE);

  if (!tableData) tableData = record[tableName];

  // Filter out unrelated contactLinks table checks
  if (childTableName === "contactLinks") {
    requiredFields = requiredFields.filter((rf) => rf.parent === `/${tableName}/contactLinks`);
  } else if (record.hasOwnProperty(tableName)) {
    requiredFields = requiredFields.filter((rf) => rf.parent === tableName);
  }

  // Now run through any matches in the required fields table to see if the supplied field is failing validation
  requiredFields &&
    requiredFields.forEach((requiredField) => {
      if (
        tableData &&
        tableData.some((arrayElement) => arrayElement[requiredField.fieldName] === requiredField.requiredValue)
      ) {
        fieldValidationStates.push({ state: validationStates.OK, string: null, type: requiredField.type });
      } else {
        fieldValidationStates.push({
          state: validationStates.ERROR,
          string: requiredField.translationCode,
          type: requiredField.type,
        });
      }
      const sectionWithTable = record.sections.find((section) => section.tableName === tableName);
      sectionWithTable.validationStates = fieldValidationStates;
    });
}
////////////////////////////////////////////////////////////////////////////////////
// SLICE START /////////////////////////////////////////////////////////////////////
// Redux global state component for individual Matter Records
export const matterSlice = createSlice({
  name: "matter",
  initialState: {
    requiredMatterData: null,
    suggestedMatterData: null,
    defaultMatterData: null,
    records: [],
    selectedRecordIndex: 0,
    isComparing: false,
    numberComparing: 2,
    isShowingOverview: true,
    pageSelected: "Matter",
    filterOptions: [{ fieldName: "matterContactLink_LegalTeam", valueToEqual: true }],
    showExtraFieldsGoods: false,
    showExtraFieldsDocuments: false,
    childTableIsExpandingAll: [],
    childTableAdding: null,
    isConnectedMatterPopupVisible: false,
    settingFocusFirstColumnTableName: null,
    lastSoftSaveLocation: null,
    isNewRecord: false,
    elementIdToScrollTo: null,
    sectionTableExpandByMatterId: {},
  },
  reducers: {
    // Validation and Sections
    setRequiredMatterData: (state, action) => {
      state.requiredMatterData = action.payload;
    },
    setSuggestedMatterData: (state, action) => {
      state.suggestedMatterData = action.payload;
    },
    setDefaultMatterData: (state, action) => {
      state.defaultMatterData = action.payload;
    },
    registerSectionsAndFields: (state, action) => {
      let recordToRegister = findById(state.records, action.payload.record.id);
      if (!recordToRegister) return;
      const tableName = action.payload.tableName;
      if (!recordToRegister.hasOwnProperty("sections")) recordToRegister.sections = [];
      if (!recordToRegister.sections.some((section) => section.sectionName === action.payload.sectionName))
        recordToRegister.sections.push({
          sectionName: action.payload.sectionName,
          tableName,
          fields: action.payload.fields,
        });
      if (tableName) setTableValidationState(recordToRegister, tableName, recordToRegister[tableName]);
      action.payload.fields.forEach((field) => setFieldValidationState(recordToRegister, field.fieldName, tableName));
    },

    // Record addition and removal
    addTableProperty: (state, action) => {
      let record = state.records[0];
      record[action.payload] = [];
      state.records = [record];
    },
    addRecord: (state, action) => {
      let newRecord = action.payload.matter;
      newRecord.requiredFields =
        state.requiredMatterData &&
        getRequiredFieldsByMatterType(state.requiredMatterData, newRecord.matter_MatterTypeId);

      // If the new record already exists in the list
      if (!action.payload.replace) {
        const existingRecordIndex = findIndexById(state.records, newRecord.id);
        if (existingRecordIndex >= 0) {
          if (action.payload.browseTo || action.payload.selected) state.selectedRecordIndex = existingRecordIndex;
          return;
        }
      }
      // Add as a new record
      let records = action.payload.replace ? [] : [...state.records];
      records.push(newRecord);
      if (action.payload.selected || action.payload.browseTo) state.selectedRecordIndex = records.length - 1;

      state.records = records;
    },
    removeRecord: (state, action) => {
      const recordId = action.payload;
      let records = filterOutOnId(state.records, recordId);
      if (records.length < state.numberComparing) state.numberComparing = records.length;
      // If only one record left then unpin it
      if (records.length === 1) {
        records = [records[0].isPinned === true ? { ...records[0], isPinned: false } : records[0]];
      }
      state.records = records;

      const indexLast = getLastUnpinnedRecord(records, state.numberComparing);
      if (state.selectedRecordIndex > indexLast) {
        state.selectedRecordIndex = indexLast;
      }
      if (records[state.selectedRecordIndex].isPinned) {
        state.selectedRecordIndex = getNextUnpinnedRecord(records, state.numberComparing, state.selectedRecordIndex);
      }
    },
    clearAllRecords: (state) => {
      state.records = [];
    },
    setRecords: (state, action) => {
      state.records = action.payload;
    },

    // Record selection and comparison
    // NOTE: "Selected Index" is the index of the first UNPINNED record in a browse of more than one with any or no pinned records
    browseNavigation: (state, action) => {
      const verb = action.payload;

      if (state.isComparing) {
        switch (verb) {
          case "first":
            state.selectedRecordIndex = getFirstUnpinnedRecord(state.records);
            break;
          case "last":
            state.selectedRecordIndex = getLastUnpinnedRecord(state.records, state.numberComparing);
            break;
          case "next":
            state.selectedRecordIndex = getNextUnpinnedRecord(
              state.records,
              state.numberComparing,
              state.selectedRecordIndex
            );
            break;
          case "prev":
            state.selectedRecordIndex = getPrevUnpinnedRecord(state.records, state.selectedRecordIndex);
            break;
          default:
        }
      } else {
        switch (verb) {
          case "first":
            state.selectedRecordIndex = 0;
            break;
          case "last":
            state.selectedRecordIndex = state.records.length - 1;
            break;
          case "next":
            state.selectedRecordIndex = Math.min(state.selectedRecordIndex + 1, state.records.length - 1);
            break;
          case "prev":
            state.selectedRecordIndex = Math.max(state.selectedRecordIndex - 1, 0);
            break;
          default:
        }
      }
    },

    setSelectedIndex: (state, action) => {
      state.selectedRecordIndex = action.payload;
    },
    setComparing: (state, action) => {
      state.isComparing = action.payload.isComparing;
      state.numberComparing = action.payload.numberComparing;
      state.isNewRecord = action.payload.isNewRecord ?? false;
      const indexLast = getLastUnpinnedRecord(state.records, state.numberComparing);
      if (state.selectedRecordIndex > indexLast) {
        state.selectedRecordIndex = indexLast;
      }
    },
    setRecordPinned: (state, action) => {
      state.records = [
        ...state.records.map((record) => {
          if (idsAreEqual(record.id, action.payload.recordId))
            return { ...record, isPinned: action.payload.newPinnedStatus };
          else if (action.payload.reset) return record.isPinned === true ? { ...record, isPinned: false } : record;
          else return record;
        }),
      ];
      const recordIndex = findIndexById(state.records, action.payload.recordId);

      // If selected record being pinned try and move to the unpinned record
      if (state.selectedRecordIndex === recordIndex && action.payload.newPinnedStatus) {
        state.selectedRecordIndex = getNextUnpinnedRecord(
          state.records,
          state.numberComparing,
          state.selectedRecordIndex
        );
      }
      // If record being unpinned make sure that all the compare slots are full
      if (!action.payload.newPinnedStatus) {
        const lastIndex = getLastUnpinnedRecord(state.records, state.numberComparing);
        if (state.selectedRecordIndex > lastIndex) {
          state.selectedRecordIndex = lastIndex;
        }
      }
    },

    setIsShowingOverview: (state, action) => {
      state.isShowingOverview = action.payload;
    },

    // User Field Updates
    // ZERO LEVEL is the matter record
    // FIRST LEVEL (Simple field is a child of a matter record)
    // FIELD Validation
    updateSimpleFieldValue: (state, action) => {
      const fieldName = action.payload.fieldName;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      if (record.hasOwnProperty(fieldName)) {
        record[fieldName] = action.payload.value;
        // Checking on this one field against the matter record for valid state
        // requiredField.type === requiredDataTypes.FIELD
        // TODO: Is country being checked here? (requiredField.type === requiredDataTypes.ARRAY)
        setFieldValidationState(record, fieldName, null, action.payload.value, null, true);
      }
    },

    // CHILD OBJECTS (i.e. table rows, documents, images)
    // SECOND LEVEL (Checks table-level validation rules against fields within newly added row)
    // THIRD LEVEL (Checks fields which are children of a Row is a child of a table which is a child of a matter record)
    addNewChildObject: (state, action) => {
      const tableName = action.payload.tableName;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      const rowArray = record[tableName];
      if (record.hasOwnProperty(tableName)) {
        rowArray.push(action.payload.newObject);
        state.selectedRecord = record;
      }
      if (action.payload.fieldNamesToValidate) {
        setTableValidationState(record, tableName, rowArray);
        action.payload.fieldNamesToValidate &&
          action.payload.fieldNamesToValidate.forEach((fieldNameToValidate) => {
            // Check every non-read-only field in the newly added row (row of table) for possible validation changes against the matter
            // Currently tableName can ONLY be a FIRST LEVEL table (e.g. CompanyLinks) and not a second level table such as contactLinks within actions
            // requiredField.type === requiredDataTypes.FIELD
            setFieldValidationState(
              record,
              fieldNameToValidate,
              tableName,
              rowArray,
              null,
              true,
              action.payload.newObject.id
            );
          });
      }
    },
    // Same as addNewChildObject for purposes of validation
    updateChildObjectFieldValue: (state, action) => {
      const tableName = action.payload.tableName;
      const rowId = action.payload.rowId;
      const fieldName = action.payload.fieldName;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      if (record.hasOwnProperty(tableName)) {
        const rowArray = record[tableName];
        const rowObject = findById(rowArray, rowId);
        if (rowObject.hasOwnProperty(fieldName)) {
          rowObject[fieldName] = action.payload.value;
          state.selectedRecord = record;
          setFieldValidationState(record, fieldName, tableName, rowArray, null, true, rowObject.id);
        }
        setTableValidationState(record, tableName, rowArray);
      }
    },
    // SECOND LEVEL (Checks table-level validation rules against table row)
    deleteChildObjectRow: (state, action) => {
      const tableName = action.payload.tableName;
      const rowId = action.payload.rowId;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      if (record.hasOwnProperty(tableName)) {
        record[tableName] = filterOutOnId(record[tableName], rowId);
        state.selectedRecord = record;
        setTableValidationState(record, tableName, record[tableName]);
      }
    },

    // CHILD TABLE LINKS (Rows of child tables that are within rows of a top-level table)
    // Currently ONLY deals with contactLinks (within Actions, Comments or Company Links)
    addChildTableLink: (state, action) => {
      const parentTableName = action.payload.parentTableName;
      const parentRowId = action.payload.parentRowId;
      const childTableName = action.payload.childTableName;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      if (record.hasOwnProperty(parentTableName)) {
        const parentTable = record[parentTableName];
        const parentRowObject = findById(parentTable, parentRowId);
        if (parentRowObject.hasOwnProperty(childTableName)) {
          let childTable = parentRowObject[childTableName];
          childTable.push(action.payload.newChildObject);
          state.selectedRecord = record;
          setTableValidationState(record, parentTableName, parentTable, childTableName, null, true);
          action.payload.fieldNamesToValidate &&
            action.payload.fieldNamesToValidate.forEach((fieldNameToValidate) => {
              setFieldValidationState(record, fieldNameToValidate, parentTableName, parentTable);
            });
        }
      }
    },
    updateChildTableLink: (state, action) => {
      const parentTableName = action.payload.parentTableName;
      const parentRowId = action.payload.parentRowId;
      const childTableName = action.payload.childTableName;
      const childLinkId = action.payload.childLinkId;
      const fieldName = action.payload.fieldName;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      if (record.hasOwnProperty(parentTableName)) {
        const parentTable = record[parentTableName];
        const parentRowObject = findById(parentTable, parentRowId);
        if (parentRowObject.hasOwnProperty(childTableName)) {
          const childTable = parentRowObject[childTableName];
          const childObject = findById(childTable, childLinkId);
          if (childObject && childObject.hasOwnProperty(fieldName)) {
            childObject[fieldName] = action.payload.value;
            state.selectedRecord = record;
            setFieldValidationState(record, fieldName, parentTableName, childTable, null, true);
          }
          setTableValidationState(record, parentTableName, parentTable, childTableName);
        }
      }
    },
    deleteChildTableLink: (state, action) => {
      const parentTableName = action.payload.parentTableName;
      const parentRowId = action.payload.parentRowId;
      const childTableName = action.payload.childTableName;
      const childLinkId = action.payload.childLinkId;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      if (record.hasOwnProperty(parentTableName)) {
        const parentTable = record[parentTableName];
        const parentRowObject = findById(parentTable, parentRowId);
        if (parentRowObject.hasOwnProperty(childTableName)) {
          // Seem to have to explicitly filter directly to the parentRowObject[childTableName]
          parentRowObject[childTableName] = filterOutOnId(parentRowObject[childTableName], childLinkId);

          //let childTable = parentRowObject[childTableName];
          // vvv This doesn't update redux vvvv
          // childTable = filterExcludeById( childTable, childLinkId);

          state.selectedRecord = record;
          setTableValidationState(record, parentTableName, parentTable, childTableName);
          action.payload.fieldNamesToValidate &&
            action.payload.fieldNamesToValidate.forEach((fieldNameToValidate) => {
              setFieldValidationState(record, fieldNameToValidate, parentTableName, parentTable);
            });
        }
      }
    },

    // List Values
    updateMatterListValues: (state, action) => {
      const listTypeId = action.payload.listTypeId;
      const listValues = action.payload.listValues;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      let listToUpdate = record.lists.find((list) => list.matterList_ListTypeId === listTypeId);
      if (listToUpdate) listToUpdate.matterList_ListValues = listValues;
      else record.lists.push({ matterList_ListTypeId: listTypeId, matterList_ListValues: listValues });
      state.selectedRecord = record;
      setFieldValidationState(record, action.payload.fieldName, null, null, null, true);
    },
    updateMatterCountries: (state, action) => {
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      record.countries = action.payload.countryIsoCodes;
      state.selectedRecord = record;
      setFieldValidationState(record, "countries", action.payload.tableName, action.payload.value, null, true);
    },

    // References
    updateMatterReference: (state, action) => {
      const referenceTypeId = action.payload.referenceTypeId;
      const referenceNumber = action.payload.referenceNumber;
      let record = state.records.find((record) => record.matter_MatterId === action.payload.matterId);
      let referenceToUpdate = record.references.find(
        (reference) => reference.matterReference_ReferenceTypeId === referenceTypeId
      );
      if (referenceToUpdate) referenceToUpdate.matterReference_ReferenceNumber = referenceNumber;
      else
        record.references.push({
          matterReference_ReferenceTypeId: referenceTypeId,
          matterReference_ReferenceNumber: referenceNumber,
        });
      setFieldValidationState(record, `REF${referenceTypeId}`, null, action.payload.value, null, true);
    },

    // Misc
    setPageSelected: (state, action) => {
      state.pageSelected = action.payload;
    },
    setFilterOptions: (state, action) => {
      state.filterOptions = action.payload;
    },
    setShowExtraFieldsGoods: (state, action) => {
      state.showExtraFieldsGoods = action.payload;
    },
    setShowExtraFieldsDocuments: (state, action) => {
      state.showExtraFieldsDocuments = action.payload;
    },
    setChildTableIsExpandingAll: (state, action) => {
      const sectionId = action.payload;
      let expandingAllLocal = state.childTableIsExpandingAll;
      if (expandingAllLocal.includes(sectionId)) expandingAllLocal = filterOutOnId(expandingAllLocal, sectionId);
      else expandingAllLocal.push(sectionId);
      state.childTableIsExpandingAll = expandingAllLocal;
    },
    setChildTableAdding: (state, action) => {
      state.childTableAdding = action.payload;
    },
    setConnectedMatterPopupVisible: (state, action) => {
      state.isConnectedMatterPopupVisible = action.payload;
    },
    setSettingFocusFirstColumnTableName: (state, action) => {
      state.settingFocusFirstColumnTableName = action.payload;
    },
    setLastSoftSaveLocation: (state, action) => {
      state.lastSoftSaveLocation = action.payload;
    },
    setElementIdToScrollTo: (state, action) => {
      state.elementIdToScrollTo = action.payload.elementId;
    },
    addSectionTableExpandByMatterId: (state, action) => {
      const currentMatterExpand = { ...state.sectionTableExpandByMatterId };
      currentMatterExpand[action.payload.sectionId] = { [action.payload.matterTypeId]: action.payload.isExpanded };
      state.sectionTableExpandByMatterId = currentMatterExpand;
    },
    clearSectionTableExpandByMatterId: (state, action) => {
      state.sectionTableExpandByMatterId = {};
    },
  },
});

export const {
  setRequiredMatterData,
  setSuggestedMatterData,
  setDefaultMatterData,
  registerSectionsAndFields,
  // setFieldValidationStateDirect,
  addTableProperty,
  addRecord,
  removeRecord,
  clearAllRecords,
  setRecords,
  browseNavigation,
  setSelectedIndex,
  setComparing,
  setRecordPinned,
  setIsShowingOverview,
  setPageSelected,
  setFilterOptions,
  setShowExtraFieldsGoods,
  setShowExtraFieldsDocuments,
  setChildTableIsExpandingAll,
  updateSimpleFieldValue,
  addNewChildObject,
  updateChildObjectFieldValue,
  deleteChildObjectRow,
  addChildTableLink,
  updateChildTableLink,
  deleteChildTableLink,
  updateMatterListValues,
  updateMatterCountries,
  updateMatterReference,
  setChildTableAdding,
  setConnectedMatterPopupVisible,
  setSettingFocusFirstColumnTableName,
  setLastSoftSaveLocation,
  setElementIdToScrollTo,
  addSectionTableExpandByMatterId,
  clearSectionTableExpandByMatterId,
} = matterSlice.actions;

export default matterSlice.reducer;
