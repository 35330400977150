import {
  setAccountId,
  setBearerToken,
  setCustAdmin,
  setCustomerId,
  setFinishedLoading,
  setSysAdmin,
  setUserId,
} from "redux/appSlice";
import { signIn } from "api/user";
import { loadAllGlobalLookups } from "api/lookup";
import { loadDataFieldGroups, loadDataFields, loadSearchFavouriteFields } from "api/datafield";
import { loadTranslations } from "api/translations";
import { useState } from "react";
import { setInitialLanguageStore } from "redux/localeSlice";
import { useDispatch, useSelector } from "react-redux";
import { t } from "locale/dictionary";
import Checkbox from "components/global/Checkbox";
import { useEffect } from "react";
import { loadBookmarks, loadCountryGroups, loadLegalTeam, loadWidgets } from "api/customer";
import { getDefaultMatterData, getRequiredMatterData, getSuggestedMatterData } from "api/matter";
import { setDefaultSearch, processDataFields } from "utilities/simpleSearch";
import { loadCountryMapData, loadWorldMap3dData, loadWorldMapData } from "api/mapData";
import { setFullName } from "redux/customerSlice";
import { setOverrideDisplayNames } from "utilities/datafield";
import { useNavigate } from "react-router-dom";
import { getSavedSearches } from "api/search";
import { setSavedSearches } from "redux/simpleSearchSlice";
import { getUsersFromCustomer } from "api/security";
import { loadAllMatterTypes } from "api/app";
import { idsAreEqual } from "utilities/stringAndArray";

// Functionality for authentication of a user
export default function Authentication() {
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modes = { LOGIN: 0, FORGOTPASSWORD: 1 };

  const [isLoaded, setIsLoaded] = useState(false);
  const [isShowingLogin, setIsShowingLogin] = useState(false);
  const [isShowingCredentialError, setIsShowingCredentialError] = useState(false);
  const [usernameText, setUsernameText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [mode, setMode] = useState(modes.LOGIN);

  useEffect(() => {
    if (appState.customerId) {
      getUsersFromCustomer(appState.customerId);
    }
  }, [appState.customerId]);

  useEffect(() => {
    if (appState.customerId && appState.accountId && !isLoaded) initialLoader(appState.isSysAdmin);
  }, [isLoaded, appState.customerId, appState.accountId, appState.isSysAdmin]);

  const getAuthTokenIfNone = () => {
    let jwt = null;
    let username = "";
    let password = "";
    let fullName = "";
    let sysAdmin = false;
    let userId = "";
    let customerId = "";
    let accountId = "";
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");
    //check cookies for sysAdmin
    cookieArr.forEach((cookie) => {
      const splitCookie = cookie.split("=");
      if (splitCookie[0].trim() === "a") sysAdmin = splitCookie[1];
      if (splitCookie[0].trim() === "i") userId = splitCookie[1];
      if (splitCookie[0].trim() === "c") customerId = splitCookie[1];
      if (splitCookie[0].trim() === "t") accountId = splitCookie[1];
    });

    if (appState.bearerToken) {
      dispatch(setCustomerId(customerId));
      dispatch(setAccountId(accountId));
      dispatch(setUserId(userId));
      dispatch(setCustAdmin(true));
      dispatch(setSysAdmin(sysAdmin === "true" ? true : false));
      return;
    }

    // Loop through the array elements
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
      if (cookiePair[0].trim() === "jwt") {
        // Decode the cookie value and return
        jwt = decodeURIComponent(cookiePair[1]);
        dispatch(setBearerToken(jwt));
        dispatch(setAccountId(accountId));
        dispatch(setUserId(userId));
        dispatch(setSysAdmin(sysAdmin));
      }
      if (cookiePair[0].trim() === "u") username = decodeURIComponent(cookiePair[1]);
      if (cookiePair[0].trim() === "p") password = decodeURIComponent(cookiePair[1]);
      if (cookiePair[0].trim() === "n") {
        fullName = decodeURIComponent(cookiePair[1]);
        dispatch(setFullName(fullName));
      }
    }
    if (jwt) return;
    // No token cookie present or has expired, so get new one

    // No username/password in cookies, so show login panel, otherwise authenticate with cookie credentials
    if (username.length === 0 || password.length === 0) {
      setIsShowingLogin(true);
      navigate("/");
    } else authenticate(username, password);
  };

  const authenticate = async (username, password) => {
    const authInfo = await signIn(username, password);
    if (authInfo) {
      setIsShowingCredentialError(false);

      if (isRememberMe) {
        const expiresRemember = new Date(Date.now() + 14 * 24 * 3600 * 1000).toUTCString(); // set to 14 days
        document.cookie = `u=${encodeURIComponent(username)}; path=/; expires=${expiresRemember}`;
        document.cookie = `p=${encodeURIComponent(password)}; path=/; expires=${expiresRemember}`;
      }

      const expiresJwt = new Date(Date.now() + 12 * 3600 * 1000).toUTCString(); // set to 12 hours
      document.cookie = `jwt=${encodeURIComponent(authInfo.token)}; path=/; expires=${expiresJwt}`;
      document.cookie = `n=${encodeURIComponent(authInfo.fullName)}; path=/; expires=${expiresJwt}`;
      document.cookie = `a=${encodeURIComponent(authInfo.sysAdmin)}; path=/; expires=${expiresJwt}`;
      document.cookie = `i=${encodeURIComponent(authInfo.id)}; path=/; expires=${expiresJwt}`;
      dispatch(setBearerToken(authInfo.token));
      dispatch(setFullName(authInfo.fullName));

      // Currently setting groups to 0 - why?
      let customerId = null;
      let accountId = null;
      if (authInfo.groups && authInfo.groups[0]) {
        document.cookie = `c=${encodeURIComponent(authInfo.groups[0].customerId)}; path=/; expires=${expiresJwt}`;
        document.cookie = `t=${encodeURIComponent(authInfo.groups[0].accountId)}; path=/; expires=${expiresJwt}`;
        customerId = authInfo.groups[0].customerId;
        accountId = authInfo.groups[0].accountId;
      } else if (authInfo.sysAdmin !== true) {
        console.error("No customer or account found in authInfo.groups[0] - exiting authentication");
        return;
      }
      dispatch(setCustomerId(customerId));
      dispatch(setAccountId(accountId));
      dispatch(setUserId(authInfo.id));
      dispatch(setSysAdmin(authInfo.sysAdmin));
      // Set redux customer admin flag if authInfo.custAdmins contains a customerId property matching current customerId
      if (authInfo.custAdmins && authInfo.custAdmins[0]) {
        if (authInfo.custAdmins.find((custAdminObj) => idsAreEqual(custAdminObj.customerId, customerId)))
          dispatch(setCustAdmin(true));
      }

      setIsShowingLogin(false);
    } else {
      setIsShowingLogin(true);
      setIsShowingCredentialError(true);
    }
  };

  const onLoginSubmit = async () => {
    authenticate(usernameText, passwordText);
  };

  /*****************************************
    This is the main initialisation function for all the data in the customer's browser
    *****************************************/
  const initialLoader = async (isSysAdmin) => {
    setIsLoaded(true);
    dispatch(setInitialLanguageStore());
    if (!isSysAdmin) {
      await loadCountryGroups();
    }
    await loadTranslations();
    await loadAllGlobalLookups();
    await loadDataFields();
    await loadSearchFavouriteFields();
    setOverrideDisplayNames();
    processDataFields();
    loadDataFieldGroups();
    loadAllMatterTypes();
    await loadLegalTeam();
    getRequiredMatterData();
    getSuggestedMatterData();
    getDefaultMatterData();
    const savedSearches = await getSavedSearches();
    dispatch(setSavedSearches(savedSearches));
    setDefaultSearch(savedSearches);
    loadWorldMapData();
    loadWorldMap3dData();
    loadCountryMapData("us");
    if (!isSysAdmin) {
      loadBookmarks();
      loadWidgets();
    }
  };

  const onUsernameTextChange = (e) => {
    setUsernameText(e.target.value);
  };

  const onPasswordTextChange = (e) => {
    setPasswordText(e.target.value);
  };

  const handleResetPassword = () => {
    navigate(`/new-user?email=${usernameText}`, { replace: false });
  };

  useEffect(() => {
    getAuthTokenIfNone();
  }, [appState.bearerToken]);

  return (
    isShowingLogin && (
      <div className="login">
        <div className="login__popup">
          <div className="login__header">{t("Login")}</div>
          <div className="login__body">
            <div className="login__row">
              <div className="textbox">
                <div className="textbox__label">{t("Username")}</div>
                <input value={usernameText} onChange={onUsernameTextChange} />
              </div>
            </div>
            {mode === modes.LOGIN ? (
              <>
                <div className="login__row">
                  <div className="textbox">
                    <div className="textbox__label">{t("Password")}</div>
                    <input type="password" value={passwordText} onChange={onPasswordTextChange} />
                  </div>
                </div>
                <button onClick={onLoginSubmit}>{t("Submit")}</button>
                <div className="login__row">
                  <Checkbox
                    label={t("Remember me on this computer")}
                    isChecked={isRememberMe}
                    onCheckChange={() => setIsRememberMe(!isRememberMe)}
                  />
                </div>
                <div className="link" onClick={() => setMode(modes.FORGOTPASSWORD)}>
                  {t("Forgot password?")}
                </div>
              </>
            ) : (
              <>
                <button onClick={handleResetPassword}>{t("Reset Password")}</button>
                <p>&nbsp;</p>
                <div className="link" onClick={() => setMode(modes.LOGIN)}>
                  {t("Back to Login")}
                </div>
              </>
            )}
            {isShowingCredentialError && (
              <div className="login__row">
                <div className="validation-error">{t("Username or password is not recognised")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}
