import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setColumnWidth } from "redux/reportSlice";
import PropTypes from "prop-types";
import InputNumberPicker from "./InputNumberPicker";

function ToolbarGridColumn({ format, selected }) {
  const dispatch = useDispatch();

  const [width, setWidth] = useState(null);

  useEffect(() => {
    let width = null;
    for (let i = 0; i < selected.length; i++) {
      const column = format.gridColumns.find((col) => col.column === selected[i].col);
      if (i === 0) {
        width = column.width;
      } else if (width !== column.width) {
        width = null;
        break;
      }
    }
    setWidth(width);
  }, [selected, format.gridColumns]);

  return (
    <InputNumberPicker
      label="Column width"
      value={width}
      onChange={(value) => dispatch(setColumnWidth({ value: value }))}
      min={1}
      max={8000}
      numbers={[
        20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 340, 380, 420, 500, 640, 800, 1000,
      ]}
      tooltipText="Select column width"
    />
  );
}

ToolbarGridColumn.propTypes = {
  format: PropTypes.object,
  selected: PropTypes.arrayOf(PropTypes.object),
};

export default ToolbarGridColumn;
