// Wrapper Functions for http verbs in calling the API
import { t } from "locale/dictionary";
import store from "redux/store.js";
import { severities } from "./constants";
import { formatErrorMessage } from "./error";
import { displayToast } from "./toast";

export const fetchWrapper = {
  get,
  post,
  patch,
  put,
  delete: _delete,
};

const urlBase = process.env.REACT_APP_API_ROOT;

function get(path) {
  const requestOptions = {
    method: "GET",
    headers: { Authorization: store.getState().app.bearerToken },
  };
  return fetch(urlBase + path, requestOptions).then(handleResponse);
}

function post(path, body) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: store.getState().app.bearerToken },
    body: JSON.stringify(body),
  };
  return fetch(urlBase + path, requestOptions).then(handleResponse);
}

function patch(path, body) {
  const requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json", Authorization: store.getState().app.bearerToken },
    body: JSON.stringify(body),
  };
  return fetch(urlBase + path, requestOptions).then(handleResponse);
}

function put(path, body) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", Authorization: store.getState().app.bearerToken },
    body: JSON.stringify(body),
  };
  return fetch(urlBase + path, requestOptions).then(handleResponse);
}

// prefixed with underscore because delete is a reserved word in javascript
function _delete(path) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", Authorization: store.getState().app.bearerToken },
  };
  return fetch(urlBase + path, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      let error = (data && data.message) || response.statusText;
      if (text) {
        const parsedError = JSON.parse(text);
        if (parsedError) {
          if (parsedError.title?.length > 0) {
            error = `${error}. ${parsedError.title}`;
          } else {
            try {
              const translatedErrors = formatErrorMessage(parsedError);
              error = `${error}. ${translatedErrors}`;
            } catch (e) {
              error = `${error} Details: ${JSON.stringify(parsedError.errors)}`;
            }
          }
        }
      }
      const errorMessage = Object.keys(data).find((key) => key.includes("DUPKEY"))
        ? t("Cannot add a row with the same combination of data as an existing row")
        : t(`An error occurred while interacting with the server")}: ${error}.`);
      displayToast(errorMessage, severities.ERROR);
      return Promise.reject(error);
    }

    return data;
  });
}
