// Compares two filter arrays and returns true if they are equal, false if not
export function deepCompare(arr1, arr2) {
  // Check if lengths of both arrays are the same
  if (arr1.length !== arr2.length) return false;

  // Sort arrays based on fieldName for comparison
  const sortedArr1 = [...arr1].sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1));
  const sortedArr2 = [...arr2].sort((a, b) => (a.fieldName > b.fieldName ? 1 : -1));

  // Compare each filter object
  for (let i = 0; i < sortedArr1.length; i++) {
    if (
      sortedArr1[i].fieldName !== sortedArr2[i].fieldName ||
      sortedArr1[i].operator !== sortedArr2[i].operator ||
      JSON.stringify(sortedArr1[i].valueArray) !== JSON.stringify(sortedArr2[i].valueArray) ||
      sortedArr1[i].qualified !== sortedArr2[i].qualified
    ) {
      return false;
    }
  }

  return true;
}
