import PropTypes from "prop-types";
import { FaGlobe, FaTimesCircle } from "react-icons/fa";
import { dndTypes } from "utilities/constants";
import { useDrag } from "react-dnd";
import { useEffect, useState } from "react";
import { getCustomerImages, getImageData, saveUploadedCustomerImage } from "api/image";
import { deleteChildObject, saveMatterChildObjectFieldChanges } from "api/matter";
import Delete from "components/global/Delete";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { useSelector } from "react-redux";
import { render } from "@testing-library/react";
import PopupHeader from "components/global/PopupHeader";
import { getBatchImageData } from "api/batchCreate";

// Container Component for the image thumbnail within the images panel table
function PanelFieldThumbnail({ keyProps, image, onDrop, onChange, onDelete }) {
  const customerImages = useSelector((state) => state.customer).images;

  const [base64data, setBase64Data] = useState(null);
  const [description, setDescription] = useState(image.matterImage_Description ?? "");
  const [isChanged, setIsChanged] = useState(false);
  const [showAddToGlobalStoreModal, setShowAddToGlobalStoreModal] = useState(false);
  const [addGlobalConfirmation, setAddGlobalConfirmation] = useState(false);

  /* eslint-disable */
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: dndTypes.THUMBNAIL,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (dropResult) onDrop(image.id, dropResult.droppedBeforeImageId);
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [keyProps.record?.images]
  );

  useEffect(() => {
    setDescription(image.matterImage_Description ?? "");
  }, [image.matterImage_Description]);

  useEffect(() => {
    const runGetImageData = async () => {
      let data = null;
      data = keyProps.isProject
        ? await getBatchImageData(keyProps.record.id, image.id)
        : await getImageData(image.id, image.matterId);
      setBase64Data(data);
    };
    runGetImageData();
  }, [image]);

  const onDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription === description && isChanged) setIsChanged(false);
    if (newDescription !== description && !isChanged) setIsChanged(true);

    setDescription(newDescription);
  };

  const saveDescriptionChanges = () => {
    if (description !== image.matterImage_Description) {
      if (keyProps.isProject) onChange("description", image.id, description);
      else
        saveMatterChildObjectFieldChanges(
          image.matterId,
          "images",
          image.id,
          "matterImage_Description",
          description,
          image.matterImage_Description
        );
    }
    setIsChanged(false);
  };

  const handleDelete = () => {
    if (keyProps.isProject) onDelete(image.id);
    else deleteChildObject(image.matterId, null, "images", image.id);
  };

  const handleAddToGlobalStoreConfirmation = async () => {
    let customerImagesLength = customerImages ? customerImages.length : 0;
    if (!customerImagesLength) {
      const customerImagesLocal = await getCustomerImages();
      customerImagesLength = customerImagesLocal.length;
    }
    const sortIndex = customerImagesLength + 1;
    let imageData = await getImageData(image.id, image.matterId, true);
    imageData = imageData.replace("data:image;base64,", "");
    saveUploadedCustomerImage(image.matterImage_Filename, imageData, sortIndex, image.matterImage_Description);
    setAddGlobalConfirmation(true);
  };

  const secondaryInfoText = `${t("Filename")}: ${image.matterImage_Filename}, ${t("File size")}: ${
    image.matterImage_ByteCount
  } ${t("bytes")}`;

  const renderAddToGlobalStoreModal = showAddToGlobalStoreModal && (
    <div className="modal-mask">
      <div className="modal__selection-popup modal__selection-popup--wide">
        <PopupHeader title="Add Image to Global Store" onClose={() => setShowAddToGlobalStoreModal(false)} />
        <div className="modal__selection-body">
          {addGlobalConfirmation ? (
            <>
              <div className="modal__selection-row">
                <p>{t(`"${image.matterImage_Description}" added to global store`)}</p>
              </div>
              <div className="modal__selection-row">
                <button onClick={() => setShowAddToGlobalStoreModal(false)}>{t("Close")}</button>
              </div>
            </>
          ) : (
            <>
              <p>{t(`Are you sure that you want to add "${image.matterImage_Description}" to the global store?`)}</p>
              <div className="modal__selection-grid">
                <button onClick={handleAddToGlobalStoreConfirmation}>Add</button>
                <button onClick={() => setShowAddToGlobalStoreModal(false)}>Cancel</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="thumbnail">
        <div ref={drag} className="thumbnail__body">
          <img src={base64data} alt={image.matterImage_Filename} />
          {/* {image.matterImage_SortIndex ?? "NULL"} */}
          <div className="body__overlay">
            <div className="overlay__items">
              <div className="overlay__item">
                <Delete message={`Remove "${image.matterImage_Description}" from the list`} onConfirm={handleDelete} />
                <FaTimesCircle />
              </div>
              <div className="overlay__item" onClick={() => setShowAddToGlobalStoreModal(true)}>
                <Tooltip content="Add to global store" direction="right">
                  <FaGlobe />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <Tooltip content={secondaryInfoText}>
          <div className="thumbnail__description">
            <input
              value={description}
              onChange={onDescriptionChange}
              onBlur={saveDescriptionChanges}
              autoComplete="off"
            />
          </div>
        </Tooltip>
      </div>
      {renderAddToGlobalStoreModal}
    </>
  );
}

PanelFieldThumbnail.propTypes = {
  keyProps: PropTypes.object,
  image: PropTypes.object,
  onDrop: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PanelFieldThumbnail;
