const reds = [
  { name: "Light Salmon", value: "#FFA07A" },
  { name: "Salmon", value: "#FA8072" },
  { name: "Dark Salmon", value: "#E9967A" },
  { name: "Light Coral", value: "#F08080" },
  { name: "Indian Red", value: "#CD5C5C" },
  { name: "Crimson", value: "#DC143C" },
  { name: "Red", value: "#FF0000" },
  { name: "Fire Brick", value: "#B22222" },
  { name: "Dark Red", value: "#8B0000" },
];

const oranges = [
  { name: "Orange", value: "#FFA500" },
  { name: "Dark Orange", value: "#FF8C00" },
  { name: "Coral", value: "#FF7F50" },
  { name: "Tomato", value: "#FF6347" },
  { name: "Orange Red", value: "#FF4500" },
];

const yellows = [
  { name: "Gold", value: "#FFD700" },
  { name: "Yellow", value: "#FFFF00" },
  { name: "Light Yellow", value: "#FFFFE0" },
  { name: "Lemon Chiffon", value: "#FFFACD" },
  { name: "Light Goldenrod Yellow", value: "#FAFAD2" },
  { name: "Papaya Whip", value: "#FFEFD5" },
  { name: "Moccasin", value: "#FFE4B5" },
  { name: "Peach Puff", value: "#FFDAB9" },
  { name: "Pale GoldenRod", value: "#EEE8AA" },
  { name: "Khaki", value: "#F0E68C" },
  { name: "Dark Khaki", value: "#BDB76B" },
];

const greens = [
  { name: "Green Yellow", value: "#ADFF2F" },
  { name: "Chartreuse", value: "#7FFF00" },
  { name: "Lawn Green", value: "#7CFC00" },
  { name: "Lime", value: "#00FF00" },
  { name: "Lime Green", value: "#32CD32" },
  { name: "Pale Green", value: "#98FB98" },
  { name: "Light Green", value: "#90EE90" },
  { name: "Medium Spring Green", value: "#00FA9A" },
  { name: "Spring Green", value: "#00FF7F" },
  { name: "Medium Sea Green", value: "#3CB371" },
  { name: "Sea Green", value: "#2E8B57" },
  { name: "Forest Green", value: "#228B22" },
  { name: "Green", value: "#008000" },
  { name: "Dark Green", value: "#006400" },
  { name: "Yellow Green", value: "#9ACD32" },
  { name: "Olive Drab", value: "#6B8E23" },
  { name: "Dark Olive Green", value: "#556B2F" },
  { name: "Medium Aquamarine", value: "#66CDAA" },
  { name: "Dark Sea Green", value: "#8FBC8F" },
  { name: "Light Sea Green", value: "#20B2AA" },
  { name: "Dark Cyan", value: "#008B8B" },
  { name: "Teal", value: "#008080" },
];

const cyans = [
  { name: "Aqua", value: "#00FFFF" },
  { name: "Cyan", value: "#00FFFF" },
  { name: "Light Cyan", value: "#E0FFFF" },
  { name: "Pale Turquoise", value: "#AFEEEE" },
  { name: "Aquamarine", value: "#7FFFD4" },
  { name: "Turquoise", value: "#40E0D0" },
  { name: "Medium Turquoise", value: "#48D1CC" },
  { name: "Dark Turquoise", value: "#00CED1" },
];

const blues = [
  { name: "Cadet Blue", value: "#5F9EA0" },
  { name: "Steel Blue", value: "#4682B4" },
  { name: "Light Steel Blue", value: "#B0C4DE" },
  { name: "Light Blue", value: "#ADD8E6" },
  { name: "Powder Blue", value: "#B0E0E6" },
  { name: "Light Sky Blue", value: "#87CEFA" },
  { name: "Sky Blue", value: "#87CEEB" },
  { name: "Cornflower Blue", value: "#6495ED" },
  { name: "Deep Sky Blue", value: "#00BFFF" },
  { name: "Dodger Blue", value: "#1E90FF" },
  { name: "Royal Blue", value: "#4169E1" },
  { name: "Blue", value: "#0000FF" },
  { name: "Medium Blue", value: "#0000CD" },
  { name: "Dark Blue", value: "#00008B" },
  { name: "Navy", value: "#000080" },
  { name: "Midnight Blue", value: "#191970" },
];

const purples = [
  { name: "Lavender", value: "#E6E6FA" },
  { name: "Thistle", value: "#D8BFD8" },
  { name: "Plum", value: "#DDA0DD" },
  { name: "Orchid", value: "#DA70D6" },
  { name: "Violet", value: "#EE82EE" },
  { name: "Fuchsia", value: "#FF00FF" },
  { name: "Magenta", value: "#FF00FF" },
  { name: "Medium Orchid", value: "#BA55D3" },
  { name: "Dark Orchid", value: "#9932CC" },
  { name: "Dark Violet", value: "#9400D3" },
  { name: "Blue Violet", value: "#8A2BE2" },
  { name: "Dark Magenta", value: "#8B008B" },
  { name: "Purple", value: "#800080" },
  { name: "Medium Purple", value: "#9370DB" },
  { name: "Medium Slate Blue", value: "#7B68EE" },
  { name: "Slate Blue", value: "#6A5ACD" },
  { name: "Dark Slate Blue", value: "#483D8B" },
  { name: "Rebecca Purple", value: "#663399" },
  { name: "Indigo", value: "#4B0082" },
];

const pinks = [
  { name: "Pink", value: "#FFC0CB" },
  { name: "Light Pink", value: "#FFB6C1" },
  { name: "Hot Pink", value: "#FF69B4" },
  { name: "Deep Pink", value: "#FF1493" },
  { name: "Pale Violet Red", value: "#DB7093" },
  { name: "Medium Violet Red", value: "#C71585" },
];

const whites = [
  { name: "White", value: "#FFFFFF" },
  { name: "Snow", value: "#FFFAFA" },
  { name: "Honeydew", value: "#F0FFF0" },
  { name: "Mint Cream", value: "#F5FFFA" },
  { name: "Azure", value: "#F0FFFF" },
  { name: "Alice Blue", value: "#F0F8FF" },
  { name: "Ghost White", value: "#F8F8FF" },
  { name: "White Smoke", value: "#F5F5F5" },
  { name: "Seashell", value: "#FFF5EE" },
  { name: "Beige", value: "#F5F5DC" },
  { name: "Old Lace", value: "#FDF5E6" },
  { name: "Floral White", value: "#FFFAF0" },
  { name: "Ivory", value: "#FFFFF0" },
  { name: "Antique White", value: "#FAEBD7" },
  { name: "Linen", value: "#FAF0E6" },
  { name: "Lavender Blush", value: "#FFF0F5" },
  { name: "Misty Rose", value: "#FFE4E1" },
];

const grays = [
  { name: "Gainsboro", value: "#DCDCDC" },
  { name: "Light Gray", value: "#D3D3D3" },
  { name: "Silver", value: "#C0C0C0" },
  { name: "Dark Gray", value: "#A9A9A9" },
  { name: "Dim Gray", value: "#696969" },
  { name: "Gray", value: "#808080" },
  { name: "Light Slate Gray", value: "#778899" },
  { name: "Slate Gray", value: "#708090" },
  { name: "Dark Slate Gray", value: "#2F4F4F" },
  { name: "Black", value: "#000000" },
];

const browns = [
  { name: "Cornsilk", value: "#FFF8DC" },
  { name: "Blanched Almond", value: "#FFEBCD" },
  { name: "Bisque", value: "#FFE4C4" },
  { name: "Navajo White", value: "#FFDEAD" },
  { name: "Wheat", value: "#F5DEB3" },
  { name: "Burlywood", value: "#DEB887" },
  { name: "Tan", value: "#D2B48C" },
  { name: "Rosy Brown", value: "#BC8F8F" },
  { name: "Sandy Brown", value: "#F4A460" },
  { name: "Goldenrod", value: "#DAA520" },
  { name: "Dark Goldenrod", value: "#B8860B" },
  { name: "Peru", value: "#CD853F" },
  { name: "Chocolate", value: "#D2691E" },
  { name: "Olive", value: "#808000" },
  { name: "Saddle Brown", value: "#8B4513" },
  { name: "Sienna", value: "#A0522D" },
  { name: "Brown", value: "#A52A2A" },
  { name: "Maroon", value: "#800000" },
];

function ColorPicker({ inputRef, color, setColor, show, setShow }) {
  let colorTable = [];
  colorTable.push(grays);
  colorTable.push(whites);
  colorTable.push(reds);
  colorTable.push(oranges);
  colorTable.push(browns);
  colorTable.push(yellows);
  colorTable.push(greens);
  colorTable.push(cyans);
  colorTable.push(blues);
  colorTable.push(purples);
  colorTable.push(pinks);

  let maxColors = 0;
  for (let i = 0; i < colorTable.length; i++) {
    maxColors = Math.max(maxColors, colorTable[i].length);
  }

  let columns = new Array(colorTable.length);
  for (let i = 0; i < colorTable.length; i++) {
    columns[i] = i;
  }
  let colorRows = new Array(maxColors);
  for (let i = 0; i < maxColors; i++) {
    colorRows[i] = i;
  }

  return (
    <div className="toolbar__color-picker" style={{ display: show ? "block" : "none" }} ref={inputRef}>
      <table className="color-picker__table" style={{ width: columns.length * 16 + "px" }}>
        <colgroup>
          {columns.map((col) => {
            return <col key={"CG-" + col} style={{ width: "16px" }} />;
          })}
        </colgroup>
        <tbody>
          {colorRows.map((colorRow) => {
            let colorTypeColumn = 0;
            return (
              <tr key={"C" + colorTypeColumn + "-" + colorRow}>
                {colorTable.map((colorType) => {
                  ++colorTypeColumn;
                  if (colorRow >= colorType.length) {
                    return <td key={"COL" + colorTypeColumn + "-" + colorRow} />;
                  } else {
                    const color = colorType[colorRow].value;

                    return (
                      <td
                        key={"C" + color}
                        style={{
                          backgroundColor: color,
                          height: "16px",
                        }}
                        onClick={() => {
                          setColor(color);
                          setShow(false);
                        }}
                      />
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ColorPicker;
