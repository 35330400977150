import ImageBrowser from "components/global/ImageBrowser";
import React from "react";

function ImageStore() {
  return (
    <div className="content__image-store">
      <ImageBrowser />
    </div>
  );
}

export default ImageStore;
