import PanelField from "./PanelField";
import PropTypes from "prop-types";

// Container Component for the form type data panel for a matter keyProps.record
function PanelForm({ keyProps }) {
  if (!keyProps.record) return null;

  const fieldsRender =
    keyProps.fieldList &&
    keyProps.fieldList.map((field) => {
      let data = null;
      if (field.fieldName.startsWith("REF")) data = keyProps.record.references;
      else if (field.fieldName === "Countries") data = keyProps.record.countries;
      else if (field.isListType) {
        data = keyProps.record.lists;
      } else if (keyProps.record.hasOwnProperty(field.fieldName)) {
        data = keyProps.record[field.fieldName];
      }
      let keyPropsField = { ...keyProps };
      keyPropsField.fieldList = null;
      keyPropsField.field = field;
      keyPropsField.data = data;
      return <PanelField key={`${field.fieldName}${field.referenceTypeId}`} keyProps={keyPropsField} />;
    });

  return <div className="data-panel__form">{keyProps.record && fieldsRender}</div>;
}

PanelField.propTypes = {
  keyProps: PropTypes.object,
};

export default PanelForm;
