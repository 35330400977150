import { createSlice } from "@reduxjs/toolkit";
import { filterOutOnId, findById } from "utilities/stringAndArray";

// Redux global state component for customer-specific settings and data
export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    fullName: null,
    legalTeam: [],
    bookmarks: [],
    countryGroups: [],
    widgets: [],
    users: null,
    images: null,
  },
  reducers: {
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setLegalTeam: (state, action) => {
      state.legalTeam = action.payload;
    },
    setBookmarks: (state, action) => {
      state.bookmarks = action.payload;
    },
    setCountryGroups: (state, action) => {
      state.countryGroups = action.payload;
    },
    addBookmark: (state, action) => {
      state.bookmarks = [...state.bookmarks, action.payload];
    },
    deleteBookmark: (state, action) => {
      state.bookmarks = filterOutOnId(state.bookmarks, action.payload.id);
    },
    setWidgets: (state, action) => {
      state.widgets = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    addImage: (state, action) => {
      state.images = [...state.images, action.payload.response];
    },
    setImages: (state, action) => {
      state.images = action.payload;
    },
    updateImageField: (state, action) => {
      const image = findById(state.images, action.payload.imageId);
      if (image) {
        image[action.payload.fieldName] = action.payload.value;
      }
    },
    deleteImage: (state, action) => {
      state.images = filterOutOnId(state.images, action.payload);
    },
  },
});

export const {
  setFullName,
  setLegalTeam,
  setBookmarks,
  setCountryGroups,
  addBookmark,
  deleteBookmark,
  setWidgets,
  setUsers,
  addImage,
  setImages,
  deleteImage,
  updateImageField,
} = customerSlice.actions;

export default customerSlice.reducer;
