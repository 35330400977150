import { getLookupValueBySource } from "utilities/lookup";
import CountryFlagContainer from "components/global/CountryFlagContainer";
import { getDateDisplayValue } from "utilities/dateTime";
import PropTypes from "prop-types";
import { getDisplayNameForField, getLookupValueForField } from "utilities/datafield";
import DateAlert from "components/global/DateAlert";
import { headerCellTypes } from "utilities/constants";
import { t } from "locale/dictionary";
import { navigateToSection } from "utilities/dom";
import { useSelector } from "react-redux";
import { findById, idsAreEqual } from "utilities/stringAndArray";

// Container Component for a cell within the matter record header block
function MatterRecordHeaderCell({ record, cellDefinition }) {
  const lookupState = useSelector((state) => state.lookup);
  const localeState = useSelector((state) => state.locale);
  const legalTeamTypes = lookupState.global.find((lookup) => lookup.name === "LegalTeamContactLinkTypes");
  let displayName = null;
  let itemContent = null;

  switch (cellDefinition.cellType) {
    case headerCellTypes.SINGLE_FIELD:
      displayName = getDisplayNameForField(cellDefinition.fieldName);
      itemContent = record.hasOwnProperty(cellDefinition.fieldName) && record[cellDefinition.fieldName];
      break;

    case headerCellTypes.COUNTRIES: {
      itemContent =
        record &&
        record.countries &&
        record.countries.map((isoCode) => {
          return <CountryFlagContainer key={isoCode} isoCode={isoCode} />;
        });
      break;
    }

    case headerCellTypes.STATUS_AND_SUBSTATUS:
      const displayNameStatus = getDisplayNameForField("matter_StatusId");
      const displayNameSubstatus = getDisplayNameForField("matter_SubstatusId");
      const statusString = getLookupValueBySource("Statuses", record.matter_StatusId);
      const substatusString = getLookupValueBySource("Substatuses", record.matter_SubstatusId);
      displayName =
        statusString && substatusString
          ? `${displayNameStatus} - ${displayNameSubstatus}`
          : `${statusString && displayNameStatus} ${substatusString && displayNameSubstatus}`;
      itemContent =
        statusString && substatusString ? `${statusString} - ${substatusString}` : statusString + substatusString;
      break;

    case headerCellTypes.TYPE_AND_SUBTYPE:
      const displayNameType = getDisplayNameForField("matter_RecordTypeId");
      const displayNameSubtype = getDisplayNameForField("matter_RecordSubtypeId");
      const typeString = getLookupValueBySource("RecordTypes", record.matter_RecordTypeId);
      const subtypeString = getLookupValueBySource("RecordSubtypes", record.matter_RecordSubtypeId);
      displayName =
        typeString && subtypeString
          ? `${displayNameType} - ${displayNameSubtype}`
          : `${typeString && displayNameType} ${subtypeString && displayNameSubtype}`;
      itemContent = typeString && subtypeString ? `${typeString} - ${subtypeString}` : typeString + subtypeString;
      break;

    case headerCellTypes.LINK:
      displayName = getLookupValueBySource("LinkTypes", cellDefinition.linkTypeId);
      itemContent =
        record &&
        record.companyLinks &&
        record.companyLinks
          .filter((cl) => idsAreEqual(cl.matterCompanyLink_LinkTypeId, cellDefinition.linkTypeId))
          .map((cl) => cl.matterCompanyLink_Name);

      if (itemContent.length > 1) itemContent = `${itemContent[0]} +${itemContent.length - 1}`;
      itemContent = (
        <div className="link" onClick={() => navigateToSection("Company Links")}>
          {itemContent}
        </div>
      );
      break;

    case headerCellTypes.NEXT_ACTION:
      // Next action date and type is the next action in the future which is not completed (ignore past dated actions, even if incomplete)
      displayName = t("Next Action Date");
      const futureIncompleteActions =
        record.actions &&
        record.actions.filter(
          (action) => Date.parse(action.matterAction_ActionDate) > Date.now() && !action.matterAction_CompletedDate
        );

      const nextAction =
        futureIncompleteActions &&
        futureIncompleteActions.length > 0 &&
        futureIncompleteActions.reduce((prev, curr) =>
          Date.parse(prev.matterAction_ActionDate) < Date.parse(curr.matterAction_ActionDate) ? prev : curr
        );

      itemContent = nextAction && (
        <div className="link" onClick={() => navigateToSection("Actions")}>
          <DateAlert data={nextAction.matterAction_ActionDate} hasAlert={true}>
            <>
              {getDateDisplayValue(nextAction.matterAction_ActionDate)} -&nbsp;
              {getLookupValueForField("matterAction_ActionTypeId", nextAction.matterAction_ActionTypeId)}
            </>
          </DateAlert>
        </div>
      );
      break;

    case headerCellTypes.REFERENCE:
      displayName = getLookupValueBySource("ReferenceTypes", cellDefinition.referenceTypeId);
      const referenceRow =
        record &&
        record.references &&
        record.references.find((ref) =>
          idsAreEqual(ref.matterReference_ReferenceTypeId, cellDefinition.referenceTypeId)
        );
      itemContent = referenceRow?.matterReference_ReferenceNumber;
      break;

    case headerCellTypes.TABLE_FIELD_LIST:
      displayName = getDisplayNameForField(cellDefinition.fieldName);
      const table = record?.hasOwnProperty(cellDefinition.tableName) && record[cellDefinition.tableName];
      const valueArray = table?.map(
        (item) => item.hasOwnProperty(cellDefinition.fieldName) && item[cellDefinition.fieldName]
      );
      itemContent = (
        <div className="link" onClick={() => navigateToSection(cellDefinition?.scrollToSection)}>
          {valueArray.join(", ")}
        </div>
      );
      break;
    case headerCellTypes.LEGAL_TEAM:
      displayName =
        localeState.translations[findById(legalTeamTypes.lookup, cellDefinition.legalTypeId)?.translationCode];

      const legalEntityNames = record?.legalTeamContactLinks
        .filter((link) =>
          idsAreEqual(link.matterLegalTeamContactLink_LegalTeamContactLinkTypeId, cellDefinition.legalTypeId)
        )
        .map((legalEntity) => legalEntity.matterLegalTeamContactLink_FullName);
      itemContent = legalEntityNames && legalEntityNames.length > 0 && (
        <div className="link" onClick={() => navigateToSection("Legal Team")}>
          {legalEntityNames.join(", ")}
        </div>
      );
      break;
    default:
      itemContent = "Invalid/No cell type";
      break;
  }

  return (
    itemContent && (
      <div className="record-header__item">
        <div className="record-header__display-name">{displayName}</div>
        <div className="record-header__display-value">{itemContent}</div>
      </div>
    )
  );
}

MatterRecordHeaderCell.propTypes = {
  record: PropTypes.object,
  cellDefinition: PropTypes.object.isRequired,
};

export default MatterRecordHeaderCell;
