import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "locale/dictionary";

// Tooltip component from article https://paladini.dev/posts/how-to-make-an-extremely-reusable-tooltip-component-with-react--and-nothing-else/
function Tooltip({ delay, direction, content, children, disabled }) {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 600);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return disabled ? (
    children
  ) : (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onClick={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && (
        <div className={`Tooltip-Tip ${direction || "top"}`}>
          {/* Content */}
          {t(content)}
        </div>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  delay: PropTypes.number,
  direction: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.element,
  disabled: PropTypes.bool,
};

export default Tooltip;
