import { createSlice } from "@reduxjs/toolkit";
import { filterOutOnId, findIndexById } from "utilities/stringAndArray";

// Redux global state component for batch import record creation
export const batchImportSlice = createSlice({
  name: "batchImport",
  initialState: {
    project: null,
    isLoading: false,
  },
  reducers: {
    //PROJECT
    setBatchImportProject: (state, action) => {
      state.project = action.payload;
    },
    updateBatchImportProject: (state, action) => {
      const { fieldName, value } = action.payload;
      state.project[fieldName] = value;
    },
    //FILTERS
    addSearchFilter: (state, action) => {
      state.project.searchFilters.push(action.payload);
    },
    removeSearchFilter: (state, action) => {
      state.project.searchFilters = filterOutOnId(state.project.searchFilters, action.payload);
    },
    updateSearchFilter: (state, action) => {
      const { fieldName, filterId, value } = action.payload;
      const filterIndex = findIndexById(state.project.searchFilters, filterId);
      state.project.searchFilters[filterIndex][fieldName] = value;
    },
    //LOADING
    setBatchImportLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    //MAPPINGS
    updateStatusMapping: (state, action) => {
      const { mapId, fieldName, value } = action.payload;
      const statusMapIndex = findIndexById(state.project.statusMaps, mapId);

      state.project.statusMaps[statusMapIndex][fieldName] = value;
    },
    updateCompanyMapping: (state, action) => {
      const { mapId, fieldName, value } = action.payload;
      const companyMapIndex = findIndexById(state.project.companyMaps, mapId);
      state.project.companyMaps[companyMapIndex][fieldName] = value;
    },
  },
});

export const {
  setBatchImportProject,
  updateBatchImportProject,
  addSearchFilter,
  removeSearchFilter,
  updateSearchFilter,
  setBatchImportLoading,
  updateStatusMapping,
  updateCompanyMapping,
  //addBatchImportLegalTeamContactLink,
  //updateBatchImportLegalTeamContactLink,
  //deleteBatchImportLegalTeamContactLink,
} = batchImportSlice.actions;

export default batchImportSlice.reducer;
