import React from "react";
import BatchImportStatusMapping from "./BatchImportStatusMapping";
import BatchImportCompanyLinksMapping from "./BatchImportCompanyLinksMapping";
import ProjectLegalTeam from "../ProjectLegalTeam";

export default function BatchImportMappingContainer() {
  return (
    <>
      <BatchImportStatusMapping />
      <BatchImportCompanyLinksMapping />
      <ProjectLegalTeam />
    </>
  );
}
