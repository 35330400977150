import { useEffect, useState } from "react";
import { FaCaretDown, FaTimes, FaPlusCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import {
  compareByDisplayValueAsc,
  compareByIdAsc,
  findById,
  hasMatchingId,
  showPartialMatchHighlight,
} from "utilities/stringAndArray";
import { isInViewport, isVisibleWithinScrollableView } from "utilities/dom";
import { findNextInput } from "utilities/findNextTabStop";
import useComponentVisible from "./useComponentVisible";
import { t } from "locale/dictionary";
import { superSelectOperationTypes } from "utilities/constants";
import Checkbox from "./Checkbox";

/* ********************************************************************
 *  An enhanced replacement component for the standard select input type
 *
 * Features:
 * Multi or single selection
 * Alphabetised list of options
 * Autocomplete input shows matching strings in options list, prioritised above non-matching list *
 * Tab into and out of the control, up/down arrows to change dropdown selection, Enter to select
 * */

function SuperSelect({
  id,
  options,
  selectedOptionId,
  selectedOptionIds,
  onChange,
  onChangeMulti,
  isMultiValued,
  alwaysExpanded,
  onTextChangeUpdateList,
  placeholderText,
  disabled,
  sortValuesById,
}) {
  const [refDropdown, showDropdown, setShowDropdown] = useComponentVisible(false); // Determines whether the dropdown will show based on location of mouse clicks and other determinations

  const [bottomPopupOrientation, setBottomPopupOrientation] = useState(false); // if popup will go beyond bottom of screen, have it popup up above and not below main element
  const [inputText, setInputText] = useState(""); // text within the input box
  const [selectedOption, setSelectedOption] = useState(null); // the selected object
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null); // the selected object index - used for keyboard up/down events
  const [optionsMatching, setOptionsMatching] = useState([]); // option objects matching the input text, displayed first
  const [optionsRemaining, setOptionsRemaining] = useState([]); // option objects that do not match the input text, displayed last
  const [showFullScreenPopup, setShowFullScreenPopup] = useState(false); // show the full list of options in a separate popup

  const outerElementId = `select__${id}`;
  const inputElementId = `input__${id}`;

  // For multi-select, we do not want to show in the remaining options those already selected
  // const filterSelected = (optionsTemp) => {
  //   if (!selectedOptionIds || selectedOptionIds.length === 0) return optionsTemp;
  //   return optionsTemp.filter((option) => selectedOptionIds && !hasMatchingId(selectedOptionIds, option.id));
  // };
  // if (inputElementId.includes("matterGoods_LanguageCode")) {
  //   console.log("inputElementId: " + inputElementId + " - selectedOptionIds: ", selectedOptionIds);
  //   console.log("inputElementId: " + inputElementId + " - options: ", options);
  // }

  const resetSelectedItem = () => {
    const selectedOptionLocal = options && Array.isArray(options) && findById(options, selectedOptionId);
    setInputText(selectedOptionLocal ? selectedOptionLocal.displayValue : "");
    setSelectedOption(selectedOptionLocal);
    //console.log("resetSelectedItem: " + selectedOptionLocal?.displayValue);
  };

  // Set the input text to the full display name of the newly selected option
  useEffect(() => {
    resetSelectedItem();
  }, [selectedOptionId]);

  // Handle scrolling when user is using up/down arrows
  useEffect(() => {
    const outerDiv = document.getElementById(outerElementId);
    const popupDivs = outerDiv.getElementsByClassName("select__popup");
    if (popupDivs?.length > 0) {
      const popupDiv = popupDivs[0];
      const activeOptionEls = popupDiv.getElementsByClassName("select__option--active");
      if (activeOptionEls) {
        const activeOptionEl = activeOptionEls[0];
        const isVisible = activeOptionEl && isVisibleWithinScrollableView(activeOptionEl, popupDiv);
        if (!isVisible) activeOptionEl?.scrollIntoView({ block: "nearest", inline: "nearest" });
      }
    }
  }, [selectedOptionIndex]);

  // Ensure popup is fully visible within viewport
  // ALSO: Reset the <input> element if we're clicking away from the control entirely (and thus hiding popup via useComponentVisible)
  useEffect(() => {
    if (!showDropdown) {
      setBottomPopupOrientation(false);
      resetSelectedItem();
      return;
    }
    const outerDiv = document.getElementById(outerElementId);
    const popupDiv = outerDiv.getElementsByClassName("select__popup")[0];
    const isVisible = isInViewport(popupDiv);
    if (!isVisible) setBottomPopupOrientation(true);
  }, [showDropdown]);

  // Changes in inputText state affect which options are matching or not matching
  useEffect(() => {
    if (showDropdown === true) {
      const el = document.getElementById(inputElementId);
      if (el) el.focus();
    }

    let matchingOptionsTemp = [];
    let remainingOptionsTemp = options;
    if (inputText?.length > 1) {
      matchingOptionsTemp = options.filter((option) =>
        option.displayValue?.toLowerCase().includes(inputText.toLowerCase())
      );
      remainingOptionsTemp = options.filter(
        (option) => !option.displayValue?.toLowerCase().includes(inputText.toLowerCase())
      );
    }
    // if (isMultiValued) {
    //   matchingOptionsTemp = filterSelected(matchingOptionsTemp);
    //   remainingOptionsTemp = filterSelected(remainingOptionsTemp);
    // }
    setOptionsMatching(matchingOptionsTemp?.length > 0 ? [...matchingOptionsTemp]?.sort(compareByDisplayValueAsc) : []);
    setOptionsRemaining(
      remainingOptionsTemp.length > 0
        ? [...remainingOptionsTemp]?.sort(sortValuesById ? compareByIdAsc : compareByDisplayValueAsc)
        : []
    );
    if (matchingOptionsTemp.length > 0) setSelectedOptionIndex(0);
  }, [inputText, showDropdown, options]);

  const onAlwaysVisibleSectionClick = () => {
    if (!(isMultiValued && showDropdown)) setShowDropdown(true);
  };

  // This doesn't work - it still blurs if you click on the dropdown part of it - DO NOT USE
  // const handleBlur = () => {
  //   resetSelectedItem();
  //   //inputElement.blur(); // DOESN'T WORK
  // };

  // Update the controlled state for the inputText when any keyboard clicks in the control are made
  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    if (onTextChangeUpdateList) onTextChangeUpdateList(text);
  };

  // Bubble up to the Select control's parent when the user makes a final selection
  const handleSelectionChange = (option) => {
    if (isMultiValued) {
      const operation = hasMatchingId(selectedOptionIds, option.id)
        ? superSelectOperationTypes.REMOVE
        : superSelectOperationTypes.ADD;
      onChangeMulti(option.id, operation);
      // const optionsRemainingFiltered = filterSelected([...optionsRemaining]);
      // setOptionsRemaining(optionsRemainingFiltered);
      // const optionsMatchingFiltered = filterSelected([...optionsMatching]);
      // setOptionsMatching(optionsMatchingFiltered);
      setInputText("");
    } else {
      onChange(option.id);
      setShowDropdown(false);
      setShowFullScreenPopup(false);
    }
  };

  // Tabbing out of the control results in user selecting currently highlighted item and hiding of the popup (special case needed to handle shift+tab)
  // Up/down arrow key events change the selection index
  // Enter results in a confirmed selection
  const handleKeyDown = (e) => {
    let index = null;
    switch (e.code) {
      case "Tab":
        if (selectedOption && inputText !== selectedOption.displayValue) setInputText(selectedOption.displayValue);
        if (isMultiValued && e.shiftKey) {
          e.preventDefault();
          const elTabTarget = e.target.parentNode;
          elTabTarget.focus();
          const nextEl = findNextInput(elTabTarget, e.shiftKey);
          nextEl.focus();
        }
        setShowDropdown(false);
        if (isMultiValued) return;
      // intentional no "break" statement - perform Enter operation (select active event) upon tabbing out
      /* falls through */
      case "Enter":
        if (selectedOptionIndex !== null) {
          let selectedOption = null;
          if (selectedOptionIndex < optionsMatching.length) selectedOption = optionsMatching[selectedOptionIndex];
          else {
            selectedOption = optionsRemaining[selectedOptionIndex - optionsMatching.length];
          }
          handleSelectionChange(selectedOption);
        }
        break;
      case "ArrowDown":
      case "ArrowUp":
        if (e.code === "ArrowDown")
          index =
            selectedOptionIndex !== null
              ? selectedOptionIndex < options.length - 1
                ? selectedOptionIndex + 1
                : selectedOptionIndex
              : 0;
        else
          index =
            selectedOptionIndex !== null
              ? selectedOptionIndex > 0
                ? selectedOptionIndex - 1
                : selectedOptionIndex
              : null;
        setSelectedOptionIndex(index);
        break;
      case "Escape":
        resetSelectedItem();
        break;
      default:
        break;
    }
  };

  // Input control allows for partial matching to aid in selection of long lists of options
  const renderInput = (
    <input
      id={inputElementId}
      value={inputText}
      onChange={handleInputChange}
      onFocus={() => setShowDropdown(true)}
      onKeyDown={handleKeyDown}
      autoComplete="off"
      placeholder={t(placeholderText)}
    />
  );

  // Always visible section is the top part of the control that does not include the dropdown popup
  let renderAlwaysVisibleSection = null;
  if (isMultiValued === true) {
    renderAlwaysVisibleSection = (
      // "elem-to-focus" is used to capture keyboard tab events since the input is not in the DOM until edit mode is enabled (same as textareas)
      <div
        className="select__selected-multi-container elem-to-focus"
        tabIndex={0}
        onFocus={() => setShowDropdown(true)}
      >
        {selectedOptionIds &&
          selectedOptionIds.map((selectedOptionId) => {
            return (
              <div key={selectedOptionId} className="select__selected-multi-item">
                {findById(options, selectedOptionId)?.displayValue}
                <span
                  className="select__selected-multi-item__close"
                  onClick={(id, op) => onChangeMulti(selectedOptionId, superSelectOperationTypes.REMOVE)}
                >
                  <FaTimes className="clickable" />
                </span>
              </div>
            );
          })}
        {showDropdown && renderInput}
      </div>
    );
  } else {
    renderAlwaysVisibleSection = renderInput;
  }

  // console.log("optionsMatching", optionsMatching);
  // console.log("optionsRemaining", optionsRemaining);

  // The dropdown popup listing out the available options, in the order of matching (to input text) options, then remaining options
  const renderOptions = (
    <>
      {optionsMatching &&
        Array.isArray(optionsMatching) &&
        optionsMatching.length > 0 &&
        optionsMatching.map((option, index) => {
          const matchingRenderArray = showPartialMatchHighlight(option.displayValue, [inputText]);
          let className = "select__option";
          if (selectedOptionIndex === index) className += " select__option--active";
          return (
            <div key={option.id} className="select__popup-row" onClick={() => handleSelectionChange(option)}>
              {isMultiValued && <Checkbox isChecked={hasMatchingId(selectedOptionIds, option.id)} />}
              <div key={option.id} className={className}>
                {matchingRenderArray[0]}
                <span className="matching-chars">{matchingRenderArray[1]}</span>
                {matchingRenderArray[2]}
              </div>
            </div>
          );
        })}
      {optionsRemaining &&
        Array.isArray(optionsRemaining) &&
        optionsRemaining.map((option, index) => {
          if (!option.id && isMultiValued) return null;
          let className = "select__option";
          if (selectedOptionIndex === index + (optionsMatching !== null ? optionsMatching.length : 0))
            className += " select__option--active";
          return (
            <div key={option.id} className="select__popup-row" onClick={() => handleSelectionChange(option)}>
              {isMultiValued && <Checkbox isChecked={hasMatchingId(selectedOptionIds, option.id)} />}
              <div className={className}>{option.id ? option.displayValue : <span>&nbsp;</span>}</div>
            </div>
          );
        })}
    </>
  );

  const renderFullScreenPopup = (
    <div className="modal-mask">
      <div className="select__full-screen-popup">
        <div className="popup-content">{renderOptions}</div>
        <button className="select__expand-button" onClick={() => setShowFullScreenPopup(false)}>
          {t("Close")}
        </button>
      </div>
    </div>
  );

  const popupClassNameBase = "select__popup";
  let popupClassName = popupClassNameBase;
  if (bottomPopupOrientation) popupClassName += ` ${popupClassNameBase}--bottom-up`;
  if (isMultiValued) popupClassName += ` ${popupClassNameBase}--multi-valued`;

  const renderDropdown = (
    <div className={popupClassName} ref={refDropdown}>
      {options?.length > 10 && (
        <button className="select__expand-button" onClick={() => setShowFullScreenPopup(true)}>
          {t("Show All")}
        </button>
      )}
      {renderOptions}
    </div>
  );

  const classNameBase = "select";
  let className = classNameBase;
  if (disabled) className = `${classNameBase} ${classNameBase}--disabled`;

  return (
    <div id={outerElementId} className={className}>
      {disabled ? (
        <div className="select__dropdown">
          <FaCaretDown />
        </div>
      ) : (
        <>
          {(showDropdown || alwaysExpanded) && (showFullScreenPopup ? renderFullScreenPopup : renderDropdown)}
          <div className="select__dropdown clickable" onClick={onAlwaysVisibleSectionClick}>
            {renderAlwaysVisibleSection}
            <FaCaretDown />
          </div>
        </>
      )}
    </div>
  );
}

SuperSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.any, // TODO: fix so it's only array
  selectedOptionId: PropTypes.any,
  selectedOptionIds: PropTypes.array,
  onChange: PropTypes.func,
  onChangeMulti: PropTypes.func,
  isMultiValued: PropTypes.bool,
  alwaysExpanded: PropTypes.bool,
  onTextChangeUpdateList: PropTypes.func,
  placeholderText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SuperSelect;
