import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getOutputDataFieldGroups } from "utilities/reportEditor";
import { anyWordStartsWith } from "utilities/stringAndArray";
import { t } from "locale/dictionary";
import FieldGroups from "./FieldGroups";
import { FaSearch } from "react-icons/fa";

function FieldList({ format, matterTypeIds }) {
  const [fieldGroups, setFieldGroups] = useState([]);
  const [visibleFieldGroups, setVisibleFieldGroups] = useState([]);

  useEffect(() => {
    const fieldGroups = getOutputDataFieldGroups(format.queryType, format.formatType, matterTypeIds);
    setFieldGroups(fieldGroups);
    if (fieldGroups.length > 0) {
      setVisibleFieldGroups([{ name: "unfilt__" + fieldGroups[0].name }]);
    }
  }, [format.queryType, format.formatType, matterTypeIds]);

  const [filterInput, setFilterInput] = useState("");
  const [filteredFieldGroups, setFilteredFieldGroups] = useState([]);

  useEffect(() => {
    const filterInputLower = filterInput.toLowerCase();

    setFilteredFieldGroups(
      fieldGroups.map((fieldGroup) => {
        const filteredDataFields =
          filterInputLower.length > 1
            ? fieldGroup.dataFields.filter((f) => anyWordStartsWith(f.displayName.toLowerCase(), filterInputLower))
            : [];

        return {
          name: fieldGroup.name,
          label: fieldGroup.label,
          filteredDataFields: filteredDataFields,
          unFilteredDataFields:
            filteredDataFields.length > 0
              ? fieldGroup.dataFields.filter((f) => !filteredDataFields.find((dataField) => dataField.name === f.name))
              : fieldGroup.dataFields,
        };
      })
    );
  }, [fieldGroups, filterInput]);

  const handleVisibleFieldGroup = (groupName) => {
    setVisibleFieldGroups((prevValue) =>
      prevValue.find((fieldGroup) => fieldGroup.name === groupName)
        ? prevValue.filter((fieldGroup) => fieldGroup.name !== groupName)
        : [...prevValue, { name: groupName }]
    );
  };

  return (
    <div className="report-editor__field-list">
      <input
        className="field-list__filter-input"
        value={filterInput}
        onChange={(event) => setFilterInput(event.target.value)}
        placeholder={t("Search for a field")}
        autoComplete="off"
      />
      <div className="filter-input__icon">
        <FaSearch />
      </div>
      <div className="field-list__groups">
        <FieldGroups
          fieldGroups={filteredFieldGroups}
          onHeaderClick={handleVisibleFieldGroup}
          filterInput={filterInput}
          visibleFieldGroups={visibleFieldGroups}
          filtered={true}
        />
        <FieldGroups
          fieldGroups={filteredFieldGroups}
          onHeaderClick={handleVisibleFieldGroup}
          filterInput={filterInput}
          visibleFieldGroups={visibleFieldGroups}
          filtered={false}
        />
      </div>
    </div>
  );
}

FieldList.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default FieldList;
