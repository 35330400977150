import { setRecords, setSelectedIndex } from "redux/matterSlice";
import store from "redux/store";
import { labelTypes } from "./constants";
import { getDataTypeForField } from "./datafield";
import { findById } from "./stringAndArray";

// Sorts browsed results (i.e. more than one record selected) by last column sorted
export function recordsSortChange(navigatedRecordId) {
  //const dataType = getDataTypeForField(fieldName);
  const state = store.getState();
  const searchResultsRenderData = state.searchResults.currentSearch.renderData;
  const records = [...state.matter.records];
  let recordsSorted = [];
  searchResultsRenderData.forEach((rowData) => {
    const recordId = rowData.matterKey;
    const recordToAdd = findById(records, recordId);
    if (recordToAdd) recordsSorted.push(recordToAdd);
  });
  //records = records.sort((a, b) => compareByFieldValue(a, b, fieldName, dataType, ascending));
  if (recordsSorted.length > 0) store.dispatch(setRecords(recordsSorted));

  if (navigatedRecordId) {
    const selectedIndex = recordsSorted.findIndex((record) => `${record.id}` === navigatedRecordId);
    store.dispatch(setSelectedIndex(selectedIndex));
  }
}

// Makes any preparation for pre-filled data when adding new rows to a table
export function initializeAddingRowData(record, fieldList, index, fillKeyValuePairInput) {
  const state = store.getState();

  let fillKeyValuePair;
  if (fillKeyValuePairInput) {
    fillKeyValuePair = fillKeyValuePairInput;
  }

  // Some rows require default values to be added to specific fields for convenience
  let initialAddingRowData = {};
  fieldList &&
    fieldList.forEach((field) => {
      let fieldName = field.fieldName;
      const defaultValueData = state.matter.defaultMatterData.find(
        (dmd) =>
          //dmd.parent === tableName &&
          dmd.dataFieldName === fieldName &&
          (dmd.matterTypeIds.length === 0 || dmd.matterTypeIds.some((mt) => mt === record.matter_MatterTypeId))
      );
      if (defaultValueData) {
        let value = defaultValueData.value;
        const fieldDataType = getDataTypeForField(fieldName);
        if (fieldDataType === labelTypes.LOOKUP) {
          value = parseInt(value);
        } else if (fieldDataType === labelTypes.DATE && value === "TODAY") value = new Date();

        fillKeyValuePair = { field: fieldName, value };
      }

      let value = "";
      const fieldType = getDataTypeForField(fieldName) ?? labelTypes.STRING;
      if (fieldType === labelTypes.DATE) value = null;
      if (fieldType === labelTypes.LOOKUP) value = null;
      if (fieldType === labelTypes.BOOLEAN) value = false;
      if (fieldName === "contactLinks") value = [];
      if (fieldName === "matterGoods_LanguageCode") value = "en";
      if (fieldName === "matterCompanyLinkContactLink_CompanyLinkContactLinkTypeId") value = 400; // Primary Contact
      if (fillKeyValuePair?.field === fieldName) value = fillKeyValuePair.value;
      initialAddingRowData[fieldName] = value;
      initialAddingRowData.index = `${index}`;
    });
  return initialAddingRowData;
}

export function getFirstUnpinnedRecord(records) {
  let indexFirst = 0;

  for (let i = 0; i < records.length; i++) {
    if (records[i].isPinned === true) continue;
    indexFirst = i;
    break;
  }

  return indexFirst;
}

export function getLastUnpinnedRecord(records, numberComparing) {
  const numPinnedRecords = records.filter((r) => r.isPinned === true).length;
  const unpinnedCompareCount = numberComparing - numPinnedRecords;
  let unpinnedCount = 0;
  let indexLast = 0;

  for (let i = records.length - 1; i >= 0; i--) {
    if (records[i].isPinned === true) continue;
    if (++unpinnedCount < unpinnedCompareCount) continue;
    indexLast = i;
    break;
  }

  return indexLast;
}

export function getPrevUnpinnedRecord(records, selectedRecordIndex) {
  const indexFirst = getFirstUnpinnedRecord(records);
  let indexPrev = Math.max(selectedRecordIndex, indexFirst);

  if (indexPrev > indexFirst) {
    for (let i = indexPrev - 1; i >= 0; i--) {
      if (records[i].isPinned === true) continue;
      indexPrev = i;
      break;
    }
  }

  return indexPrev;
}

export function getNextUnpinnedRecord(records, numberComparing, selectedRecordIndex) {
  const indexLast = getLastUnpinnedRecord(records, numberComparing);
  let indexNext = Math.min(selectedRecordIndex, indexLast);

  if (indexNext < indexLast) {
    for (let i = indexNext + 1; i < records.length; i++) {
      if (records[i].isPinned === true) continue;
      if (i > indexLast) break;
      indexNext = i;
      break;
    }
  }

  return indexNext;
}

export function getUnpinnedRecordPosition(records, selectedRecordIndex) {
  let unPinnedPosition = 0;

  for (let i = 0; i < records.length; i++) {
    if (records[i].isPinned === true) continue;
    ++unPinnedPosition;
    if (i < selectedRecordIndex) continue;
    break;
  }

  return unPinnedPosition;
}
