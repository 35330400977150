import PropTypes from "prop-types";
import HighchartsMap from "./highcharts/HighchartsMap";

function MapOutput({ widgetId, output, onClick }) {
  return output ? <HighchartsMap widgetId={widgetId} output={output} onClick={onClick} /> : null;
}

MapOutput.propTypes = {
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default MapOutput;
