import { createSlice } from "@reduxjs/toolkit";

// Redux global state component for map data
export const mapDataSlice = createSlice({
  name: "mapData",
  initialState: { countryMapData: [], worldMapData: {}, worldMap3dData: {} },
  reducers: {
    setCountryMapData: (state, action) => {
      state.countryMapData = [
        ...state.countryMapData,
        { countryCode: action.payload.countryCode, mapData: action.payload.mapData },
      ];
    },
    setWorldMapData: (state, action) => {
      state.worldMapData = action.payload;
    },
    setWorldMap3dData: (state, action) => {
      state.worldMap3dData = action.payload;
    },
  },
});

export const { setCountryMapData, setWorldMapData, setWorldMap3dData } = mapDataSlice.actions;

export default mapDataSlice.reducer;
