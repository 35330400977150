import {
  addListType,
  addListTypeEntry,
  addLookupEntry,
  deleteListTypeEntry,
  deleteLookupEntry,
  updateListType,
  updateListTypeEntry,
  updateLookupEntry,
} from "api/lookup";
import Checkbox from "components/global/Checkbox";
import Delete from "components/global/Delete";
import List from "components/global/List";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { matterTypes, superSelectOperationTypes } from "utilities/constants";
import { getAllListDisplayValues, getAllListTypes, getAllLookupValuesForSource } from "utilities/lookup";
import PropTypes from "prop-types";
import { getDisplayNameForField } from "utilities/datafield";
import { set } from "date-fns";

// Component for customizing lookups
function Lookups({ selectedMatterTypeId }) {
  const isLoading = useSelector((state) => state.app).isLoading;
  const lookupState = useSelector((state) => state.lookup.global);
  const dataFieldState = useSelector((state) => state.dataField);
  const localeState = useSelector((state) => state.locale);

  const [selectedLookup, setSelectedLookup] = useState(null);
  const [lookupTableValues, setLookupTableValues] = useState(null);
  const [selectedTableValueId, setSelectedTableValueId] = useState(null);
  const [isAddingTable, setIsAddingTable] = useState(false);
  const [isEditingTableName, setIsEditingTableName] = useState(false);
  const [selectedTableNameText, setSelectedTableNameText] = useState(null);
  const [isAddingTableValue, setIsAddingTableValue] = useState(false);
  const [selectedTableValueText, setSelectedTableValueText] = useState(null);
  const [codeText, setCodeText] = useState(null);
  const [selectedMatterTypeIds, setSelectedMatterTypeIds] = useState(null);

  const [configurableLookups, setConfigurableLookups] = useState(null);
  const [listValues, setListValues] = useState(null);
  const [selectedListValueId, setSelectedListValueId] = useState(null);
  const [selectedListValueText, setSelectedListValueText] = useState(null);

  const matterTypesAll = getAllLookupValuesForSource("MatterTypes");

  useEffect(() => {
    if (isLoading) return;
    const baseTypes = convertLookupsToOptions();

    //setBaseLookupTypes([...baseTypes]);
    setConfigurableLookups([...baseTypes]);
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) return;
    const baseTypes = convertLookupsToOptions(selectedMatterTypeId);
    let listTypes = getAllListTypes().filter(
      (type) =>
        type.matterTypeIds?.includes(selectedMatterTypeId) ||
        type.code === "UNIT" ||
        type.code === "DIVISION" ||
        type.code === "REGION"
    );
    listTypes = listTypes.map((type) => {
      return { ...type, displayValue: localeState.translations[type.translationCode] ?? type.code, isListType: true };
    });
    setConfigurableLookups([...baseTypes, ...listTypes]);
  }, [isLoading, selectedMatterTypeId, localeState]);

  useEffect(() => {
    if (isLoading) return;
    const lookupValuesLocal = getLookupValues(selectedLookup);
    setLookupTableValues(lookupValuesLocal);
  }, [lookupState, localeState, selectedLookup]);

  const convertLookupsToOptions = (selectedMatterTypeId) => {
    // All matter types have these base lookup types
    let baseTypes = lookupState.filter(
      (lookup) =>
        lookup.name === "ActionTypes" ||
        lookup.name === "CommentTypes" ||
        lookup.name === "DocumentTypes" ||
        lookup.name === "LegalTeam" ||
        lookup.name === "LinkTypes" ||
        lookup.name === "RecordSubtypes" ||
        lookup.name === "RecordTypes" ||
        lookup.name === "ReferenceTypes" ||
        lookup.name === "Statuses" ||
        lookup.name === "Substatuses"
    );

    // Some matter types have additional base lookup types
    if (selectedMatterTypeId) {
      if (
        selectedMatterTypeId === matterTypes.PATENT ||
        selectedMatterTypeId === matterTypes.COPYRIGHTS ||
        selectedMatterTypeId === matterTypes.DESIGNS
      ) {
        baseTypes.push(lookupState.find((lookup) => lookup.name === "ContactLinkTypes"));
      } else if (selectedMatterTypeId === matterTypes.COMPANY) {
        baseTypes.push(lookupState.find((lookup) => lookup.name === "AccountNumberTypes"));
      }
    }

    return baseTypes.map((type, index) => {
      const dataFieldWithThisTableName = dataFieldState.dataFields.find(
        (field) => field.lookupDataSourceName === type.name
      );
      const displayValue = getDisplayNameForField(dataFieldWithThisTableName.name, [selectedMatterTypeId]) ?? type.name;
      return { ...type, id: index, displayValue, isListType: false };
    });
  };

  const getLookupValues = (selectedLookup) => {
    if (!selectedLookup) return;
    let lookupValuesLocal = null;
    if (!selectedLookup.isListType) {
      lookupValuesLocal = getAllLookupValuesForSource(selectedLookup.name);
    } else {
      lookupValuesLocal = getAllListDisplayValues(selectedLookup.id);
    }

    return lookupValuesLocal;
  };

  // TABLE EVENT HANDLERS

  const handleTableSelectionChange = (selected) => {
    const lookupTable = configurableLookups.find((lookup) => lookup.id === selected);
    setSelectedLookup(lookupTable);
    const lookupValuesLocal = getLookupValues(lookupTable);
    setLookupTableValues(lookupValuesLocal);
    setSelectedTableValueId(null);
    setSelectedTableValueText(null);
    setIsAddingTable(false);
    setIsEditingTableName(false);
    setIsAddingTableValue(false);
  };

  const handleAddNewTableClick = () => {
    setSelectedTableValueId(null);
    setSelectedTableNameText("");
    setIsAddingTable(true);
  };

  const handleEditTableNameClick = () => {
    setIsEditingTableName(true);
    setSelectedTableNameText(selectedLookup.displayValue);
  };

  const handleTableNameTextChange = (e) => {
    setSelectedTableNameText(e.target.value);
  };

  const handleTableNameTextBlur = async () => {
    if (isAddingTable) {
      const response = await addListType(selectedTableNameText);
      if (response) {
        setSelectedLookup(response);
        setIsAddingTable(false);
        setIsEditingTableName(false);
      }
    } else {
      updateListType(
        selectedLookup.id,
        "description",
        selectedLookup.displayValue,
        selectedTableNameText,
        selectedLookup.translationCode
      );
    }
  };

  // TABLE VALUE HANDLERS

  const handleTableValueSelectionChange = (selected) => {
    setSelectedTableValueId(selected);
    const lookupValue = lookupTableValues.find((value) => value.id === selected);
    const selectedLookupDisplayValue = lookupValue?.displayValue;
    setSelectedMatterTypeIds(lookupValue?.matterTypeIds);
    setSelectedTableValueText(selectedLookupDisplayValue);
    if (lookupValue.hasOwnProperty("code")) setCodeText(lookupValue.code);
    setIsAddingTableValue(false);

    selectedLookup.isListType && setListValues(getAllListDisplayValues(selected.id));
  };

  const handleAddNewTableValueClick = () => {
    setSelectedTableValueId(null);
    setSelectedTableValueText("");
    setIsAddingTableValue(true);
  };

  const handleTableValueTextChange = (e) => {
    setSelectedTableValueText(e.target.value);
  };

  const handleCodeTextChange = (e) => {
    setCodeText(e.target.value);
  };

  const handleTableValueTextBlur = async () => {
    if (isAddingTableValue) {
      const response = selectedLookup.isListType
        ? await addListTypeEntry(selectedLookup.id, selectedTableValueText)
        : await addLookupEntry(selectedLookup.name, selectedTableValueText);
      if (response) {
        setSelectedTableValueId(response.id);
        setIsAddingTableValue(false);
      }
    } else {
      const selectedValue = lookupTableValues.find((value) => value.id === selectedTableValueId);
      if (selectedLookup.isListType)
        updateListTypeEntry(
          selectedLookup.id,
          selectedTableValueId,
          selectedValue.displayValue,
          selectedTableValueText,
          selectedValue.translationCode
        );
      else
        updateLookupEntry(
          selectedLookup.name,
          selectedTableValueId,
          "displayName",
          selectedValue.displayValue,
          selectedTableValueText,
          selectedValue.translationCode
        );
    }
  };

  const handleMatterTypeIdsChange = (selected, operation) => {
    const selectedMatterIdsCurrent = selectedMatterTypeIds ? [...selectedMatterTypeIds] : [];
    let selectedMatterIdsNew = null;
    if (operation === superSelectOperationTypes.ADD)
      selectedMatterIdsNew = selectedMatterTypeIds?.length > 0 ? [...selectedMatterTypeIds, selected] : [selected];
    else selectedMatterIdsNew = selectedMatterTypeIds.filter((id) => id !== selected);
    setSelectedMatterTypeIds(selectedMatterIdsNew);
    updateLookupEntry(
      selectedLookup.name,
      selectedTableValueId,
      "matterTypeIds",
      selectedMatterIdsCurrent,
      selectedMatterIdsNew
    );
  };

  const handleTableValueDelete = () => {
    if (selectedLookup.isListType) deleteListTypeEntry(selectedLookup.id, selectedTableValueId);
    else deleteLookupEntry(selectedLookup.name, selectedTableValueId);
    setSelectedTableValueId(null);
    setSelectedTableValueText(null);
    setSelectedMatterTypeIds(null);
    setIsAddingTableValue(false);
  };

  //////////////////////
  // LIST VALUE HANDLERS
  const handleListValueSelectionChange = (selected) => {
    setSelectedListValueId(selected);
    const listValue = listValues.find((value) => value.id === selected);
    const selectedListDisplayValue = listValue?.displayValue;
    setSelectedListValueText(selectedListDisplayValue);
    setIsAddingTableValue(false);
  };

  const handleAddNewListValueClick = () => {
    setSelectedListValueId(null);
    setSelectedListValueText("");
    setIsAddingTableValue(true);
  };

  const handleListValueTextChange = (e) => {
    setSelectedListValueText(e.target.value);
  };

  const handleListValueTextBlur = async () => {
    // const looku = configurableLookups.find((lookup) => lookup.id === selectedLookupId);
    // if (isAddingTableValue) {
    //   const response = await addLookupEntry(lookupTable.id, selectedTableValueText);
    //   if (response) {
    //     setSelectedTableValueId(response.id);
    //     setIsAddingTableValue(false);
    //   }
    // } else {
    //   const selectedValue = lookupTableValues.find((value) => value.id === selectedTableValueId);
    //   updateLookupEntry(
    //     selectedLookupId,
    //     selectedTableValueId,
    //     "displayName",
    //     selectedValue.displayValue,
    //     selectedTableValueText,
    //     selectedValue.translationCode
    //   );
    // }
  };

  const handleListValueDelete = () => {
    // TODO: Need to add new API function to delete list values?
    //deleteListValueEntry(selectedLookupId, selectedTableValueId);
    setSelectedListValueId(null);
    setSelectedListValueText(null);
    setIsAddingTableValue(false);
  };

  const renderTables = (
    <div className="lookup__column">
      <div className="column__title">{t("Lookup Table")}</div>
      <List
        id="lookups"
        options={configurableLookups}
        selectedOptionId={selectedLookup?.id}
        onChange={handleTableSelectionChange}
      />

      {/* HIDDEN PENDING BUSINESS DECISION ON WHETHER NEEDED. TODO: API NEEDS TO WORK ALSO
       {!isAddingTable && (
        <button className="column__button" onClick={handleAddNewTableClick}>
          {t("Add New Table")}
        </button>
      )} */}
    </div>
  );

  //console.log("lookupValues:", lookupValues);

  const renderValues = (
    <div className="lookup__column">
      {isAddingTable || isEditingTableName ? (
        <div className="edit__row">
          <div className="edit__label">{isAddingTable ? t("New Table Name") : t("Display Name")}</div>
          <div className="edit__value">
            <input
              value={selectedTableNameText}
              onChange={handleTableNameTextChange}
              onBlur={handleTableNameTextBlur}
            />
          </div>
        </div>
      ) : (
        <div className="column__title">
          {selectedLookup?.displayValue}&nbsp;
          {selectedLookup?.isListType && (
            <span className="link link--small" onClick={handleEditTableNameClick}>
              {t("Rename")}
            </span>
          )}
        </div>
      )}
      {!isAddingTable && (
        <>
          <div className="column__title">{t("Table Values")}</div>
          <List
            id="lookup-values"
            options={lookupTableValues}
            selectedOptionId={selectedTableValueId}
            onChange={handleTableValueSelectionChange}
            cssClassNameModifier="lookup-values"
            // showAvailableMatterTypes={true}
          />
          {!isAddingTableValue && (
            <button className="column__button" onClick={handleAddNewTableValueClick}>
              {t("Add New Value")}
            </button>
          )}
        </>
      )}
    </div>
  );

  const renderEdit = () => {
    if (!selectedTableValueId && !isAddingTableValue) return null;
    const selectedValue = lookupTableValues.find((value) => value.id === selectedTableValueId);
    const isReferenceType = selectedLookup.name === "ReferenceTypes";
    const isSystemOwned = false; // selectedValue.isSystemOwned;

    const isListType = selectedLookup.name === "ListTypes";

    const renderValueDetails = () => {
      return (
        <div className="lookup__column">
          <div className="column__title">{isAddingTableValue ? t("New Table Value") : t("Table Value Details")}</div>
          <div className="edit__row">
            <div className="edit__label">{t("Display Name")}</div>
            {isSystemOwned ? (
              <span>{selectedTableValueText}</span>
            ) : (
              <div className="edit__value">
                <input
                  value={selectedTableValueText}
                  onChange={handleTableValueTextChange}
                  onBlur={handleTableValueTextBlur}
                />
              </div>
            )}
          </div>
          {!isAddingTableValue && !isReferenceType && !selectedLookup.isListType && (
            <div className="edit__row">
              <div className="edit__label">{t("Valid Matter Types")}</div>
              <div className="edit__value">
                <SuperSelect
                  id="matter-types-table-value"
                  options={matterTypesAll}
                  selectedOptionIds={selectedMatterTypeIds}
                  isMultiValued={true}
                  onChangeMulti={handleMatterTypeIdsChange}
                />
              </div>
            </div>
          )}
          {(isReferenceType || isListType) && (
            <div className="edit__row">
              <div className="edit__label">{t("Code")}</div>
              <div className="edit__value">
                {isSystemOwned ? (
                  <span>{selectedTableValueText}</span>
                ) : (
                  <div className="edit__value">
                    <input value={codeText} onChange={handleCodeTextChange} /*onBlur={handleValueTextBlur}*/ />
                  </div>
                )}
              </div>
            </div>
          )}
          {isListType && (
            <>
              <div className="edit__row">
                <div className="edit__label">{t("Sort Values By Id?")}</div>
                <div className="edit__value">
                  <span>
                    {isSystemOwned ? (
                      selectedValue.sortValuesById ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : (
                      <Checkbox isChecked={selectedValue.sortValuesById} />
                    )}
                  </span>
                </div>
              </div>
              <div className="edit__row">
                <div className="edit__label">{t("Multi Value?")}</div>
                <div className="edit__value">
                  <span>
                    {isSystemOwned ? (
                      selectedValue.multivalued ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : (
                      <Checkbox isChecked={selectedValue.multivalued} />
                    )}
                  </span>
                </div>
              </div>
            </>
          )}

          {!isSystemOwned && !isAddingTableValue && (
            <div className="edit__row">
              <div className="edit__label">{t("Delete this table value")}</div>
              <div className="edit__value edit__value--control">
                <Delete
                  message="Delete this table entry"
                  onConfirm={handleTableValueDelete}
                  showTrashIconInstead={true}
                />
              </div>
            </div>
          )}
        </div>
      );
    };

    const renderListValues = () => {
      return (
        <>
          <div className="lookup__column">
            <div className="column__title">{t("List Values")}</div>
            <List
              id="list-values"
              options={listValues}
              selectedOptionId={selectedListValueId}
              onChange={handleListValueSelectionChange}
            />
            <button className="column__button" onClick={handleAddNewListValueClick}>
              {t("Add New Value")}
            </button>
          </div>

          <div className="lookup__column">
            <div className="column__title">{t("List Value Details")}</div>
            <div className="edit__row">
              <div className="edit__label">{t("Display Name")}</div>
              <div className="edit__value">
                <input
                  value={selectedListValueText}
                  onChange={handleListValueTextChange}
                  onBlur={handleListValueTextBlur}
                />
              </div>
            </div>
            {!isAddingTableValue && (
              <div className="edit__row">
                <div className="edit__label">{t("Delete this list value")}</div>
                <div className="edit__value edit__value--control">
                  <Delete
                    message="Delete this list entry"
                    onConfirm={handleListValueDelete}
                    showTrashIconInstead={true}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      );
    };

    return (
      <>
        {renderValueDetails()}
        {isListType && renderListValues()}
      </>
    );
  };

  return (
    <div className="configuration__lookups">
      <div className="lookups__title">{t("Lookups")}</div>
      <div className="lookups__columns">
        {isLoading ? (
          <div className="loading">{t("Loading...")}</div>
        ) : (
          <>
            {renderTables}
            {(selectedLookup !== null || isAddingTable) && renderValues}
            {renderEdit()}
          </>
        )}
      </div>
    </div>
  );
}

Lookups.propTypes = {
  selectedMatterTypeId: PropTypes.number,
};

export default Lookups;
