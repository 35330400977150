import { t } from "locale/dictionary";
import React from "react";
import { sortColumn } from "utilities/sort";
import PropTypes from "prop-types";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

function SecurityColumnHeader({ fieldName, fieldType, displayName, tableData, sortParams, onUpdateSortParams }) {
  const handleSort = () => {
    const { sortedData, newSortParams } = sortColumn(fieldName, fieldType, tableData, sortParams);
    onUpdateSortParams(sortedData, newSortParams);
  };

  return (
    <th className="security__table__header-row-cell security__table__header-row-cell--clickable" onClick={handleSort}>
      {t(displayName)}
      <div className="sort-spacer">
        {fieldName === sortParams.sortCol && (sortParams.sortAsc ? <FaCaretUp /> : <FaCaretDown />)}
      </div>
    </th>
  );
}

SecurityColumnHeader.propTypes = {
  fieldName: PropTypes.string,
  fieldType: PropTypes.number,
  displayName: PropTypes.string,
  tableData: PropTypes.array,
  sortParams: PropTypes.object,
  onUpdateSortParams: PropTypes.func,
};

export default SecurityColumnHeader;
