import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { idsAreEqual } from "utilities/stringAndArray";

export default function TabsHorizontal({ tabArray = [], currentTabId, onTabChange, children }) {
  const tabLabel = (tab) => {
    const className = `tabs__header__label ${idsAreEqual(currentTabId, tab.id) ? "tabs__header__label--focused" : ""}`;
    return (
      <div key={tab.label} className={className} onClick={() => onTabChange(tab)}>
        {t(tab.label)}
      </div>
    );
  };

  return (
    <div className="tabs">
      {tabArray.length > 0 ? (
        <>
          <div className="tabs__header">{tabArray.map((tab) => tabLabel(tab))}</div>
          <div className="tabs__content">{children}</div>
        </>
      ) : null}
    </div>
  );
}
TabsHorizontal.propTypes = {
  tabArray: PropTypes.array,
  onTabChange: PropTypes.func,
  currentTabId: PropTypes.object,
};
