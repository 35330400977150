import { createSlice } from "@reduxjs/toolkit";
import { labelTypes, searchViewTypes, simpleSearchKeyboardFocusedPopup } from "utilities/constants";
import { stringToBoolean } from "utilities/boolean";
import { anyWordStartsWith, splitAndCleanStringToArray } from "utilities/stringAndArray";
import { checkForValidDateEntered } from "utilities/dateTime";
import { deepCompare } from "utilities/object";
// We need to maintain separate instances of this slice's state for main search and other searches (e.g. connected matters)
// so we keep each as an object in an array, with the same initialiser
const initSearch = {
  isPopupVisible: false,
  favouriteFields: [],
  inputText: "", // Main text in search bar
  fieldText: "", // Local popup text
  inputArray: [],
  inputValues: [],
  queryType: null,
  matterTypesSelected: ["All"],
  fieldsMatchingAll: [],
  fieldsMatchingValue: [],
  fieldsMatchingName: [],
  fieldsQualifiedValues: [],
  fieldOverrides: [],
  //fieldCountriesAndCodes: [],
  filters: [],
  labelSelected: { section: "", fieldName: "", clickSource: "" },
  fields: [],
  savedSearches: [],
  defaultSearchId: [],
  reloadFieldsForMatterTypes: false,
  savedSearchLoadedId: null, // indicates the current search in filters is a Saved Search with its ID
  isKeyboardFocusedSelection: simpleSearchKeyboardFocusedPopup.NONE, // indicates if the current selection in the popup is from keyboard navigation
  popupWholeSection: false, // indicates if the popup should be shown for the whole section, or just the field
};

// compare state.currentSearch.filters with filters in savedSearches and if matching set savedSearchLoadedId to the matching saved search id
const compareActiveFiltersWithSavedSearches = (currentSearchLocal) => {
  const savedSearches = [...currentSearchLocal.savedSearches];
  const filters = currentSearchLocal.filters;
  const savedSearch = savedSearches.find((savedSearch) => {
    const savedSearchFilters = [...savedSearch.filters];
    return deepCompare(savedSearchFilters, filters);
  });
  return savedSearch?.id;
};

// Redux global state component for Simple Search
export const simpleSearchSlice = createSlice({
  name: "simpleSearch",
  initialState: {
    currentSearch: { ...initSearch, viewType: searchViewTypes.MAIN },
    otherSearches: [],
  },
  reducers: {
    // Indicates whether the main search panel in the header is being used, or a junior version within another panel
    setViewTypeSimpleSearch: (state, action) => {
      const newViewType = action.payload;
      if (state.currentSearch.viewType === newViewType) return;

      // console.log("view type has changed from " + state.currentSearch.viewType + " to ", newViewType);
      // console.log("current search fields length: " + state.currentSearch.fields.length);
      // state.otherSearches.forEach((search) =>
      //   console.log("other search (" + search.viewType + ") fields length: " + search.fields.length)
      // );

      let otherSearchesLocal = [...state.otherSearches];
      otherSearchesLocal.push(state.currentSearch);

      const requestedSearch = otherSearchesLocal.find((s) => s.viewType === newViewType);
      if (requestedSearch) {
        state.currentSearch = requestedSearch;
        otherSearchesLocal = otherSearchesLocal.filter((s) => s.viewType !== newViewType);
      } else {
        const initSearchWithMainFields = { ...initSearch, viewType: newViewType, fields: state.currentSearch.fields };
        state.currentSearch = initSearchWithMainFields;
      }

      // HARD reset of "temporary" searches when switching back to main search
      // if (newViewType === searchViewTypes.MAIN) {
      //   otherSearchesLocal = [];
      // }

      state.otherSearches = otherSearchesLocal;
    },

    // TODO: Sort out last - UI only
    popupVisibleChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.isPopupVisible = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    queryTypeChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.queryType = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    matterTypesSelectedChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.matterTypesSelected = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    inputTextValueChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.inputText = !action.payload ? "" : action.payload;
      state.currentSearch = currentSearchLocal;
    },
    fieldTextValueChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.fieldText = !action.payload ? "" : action.payload;
      state.currentSearch = currentSearchLocal;
    },
    searchValueChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      const inputText = action.payload.text;
      const locale = action.payload.locale;
      const fieldsQualifiedValues = action.payload.fieldsQualifiedValues;
      currentSearchLocal.fieldsQualifiedValues = fieldsQualifiedValues;

      let fieldsMatchingName = [];
      let fieldsMatchingValue = [];

      if (inputText.length < 3) {
        currentSearchLocal.fieldsMatchingValue = [];
        currentSearchLocal.fieldsMatchingName = [];
        currentSearchLocal.fieldsMatchingAll = [];
        currentSearchLocal.inputArray = [];
        currentSearchLocal.inputValues = [];
        state.currentSearch = currentSearchLocal;
        return;
      }

      const inputArray = splitAndCleanStringToArray(inputText);
      currentSearchLocal.inputArray = inputArray;

      // Fields that have a FIELD NAME *starting with* the inputted text
      inputArray.forEach((inputItem) => {
        const inputItemLower = inputItem.toLowerCase();
        fieldsMatchingName = [
          ...fieldsMatchingName,
          ...currentSearchLocal.fields.filter((f) => anyWordStartsWith(f.displayName.toLowerCase(), inputItemLower)),
        ];

        // Fields that have a basic or nested lookup *starting with* the inputted text
        fieldsMatchingValue = [
          ...fieldsMatchingValue,
          ...currentSearchLocal.fields.filter(
            (f) =>
              f.dataType === labelTypes.LOOKUP &&
              f.lookupDisplayList &&
              Array.isArray(f.lookupDisplayList) &&
              f.lookupDisplayList.some(
                (lookup) =>
                  (lookup.displayValue && anyWordStartsWith(lookup.displayValue.toLowerCase(), inputItemLower)) ||
                  (lookup.optionalCodes && anyWordStartsWith(lookup.optionalCodes.toLowerCase(), inputItemLower))
              )
          ),
        ];
      });

      fieldsQualifiedValues?.forEach((qualifySearchResult) => {
        const fieldName = qualifySearchResult.dataFieldName.toLowerCase();
        fieldsMatchingValue = [
          ...fieldsMatchingValue,
          ...currentSearchLocal.fields.filter((f) => f.fieldName.toLowerCase() === fieldName),
        ];
      });

      currentSearchLocal.inputValues = inputArray.map((inputValue) => {
        const inputBooleanValue = stringToBoolean(inputValue);
        const inputNumValue = Number(inputValue);
        const inputDateValue = checkForValidDateEntered(inputValue, locale);
        return {
          stringValue: inputValue,
          isBoolean: !isNaN(inputBooleanValue),
          booleanValue: inputBooleanValue,
          isDate: !isNaN(Date.parse(inputDateValue)),
          dateValue: Date.parse(inputDateValue),
          isNumber: !isNaN(inputNumValue),
          numberValue: inputNumValue,
        };
      });

      // de-dupe
      currentSearchLocal.fieldsMatchingName = [...new Set(fieldsMatchingName)];
      currentSearchLocal.fieldsMatchingValue = [...new Set(fieldsMatchingValue)];

      // Concat names and values and de-dupe
      currentSearchLocal.fieldsMatchingAll = [...new Set([...fieldsMatchingName, ...fieldsMatchingValue])];

      state.currentSearch = currentSearchLocal;
    },
    // setFieldsMatchingAll: (state, action) => {
    //   state.fieldsMatchingAll = action.payload;
    // },
    // setFieldsMatchingValue: (state, action) => {
    //   state.fieldsMatchingValue = action.payload;
    // },
    // setFieldsMatchingName: (state, action) => {
    //   state.fieldsMatchingName = action.payload;
    // },
    addFilter: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };

      // Remove any existing filter that matches the section and fieldName on the filter being saved to prevent duplicates
      // TODO: Remove this if we want to allow multiple filters on the same field
      currentSearchLocal.filters = currentSearchLocal.filters.filter(
        (filter) => !(filter.fieldName === action.payload.fieldName)
      );
      // END TODO

      currentSearchLocal.filters.push({
        id: action.payload.id,
        fieldName: action.payload.fieldName,
        section: action.payload.section,
        operator: action.payload.operator,
        valueArray: action.payload.valueArray,
        qualified: action.payload.qualified,
        qualifiedDictionary: action.payload.qualifiedDictionary,
      });

      if (action.payload.fieldName === "matter_MatterTypeId") currentSearchLocal.reloadFieldsForMatterTypes = true;

      currentSearchLocal.savedSearchLoadedId = compareActiveFiltersWithSavedSearches(currentSearchLocal);

      state.currentSearch = currentSearchLocal;
    },
    clearFilter: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };

      currentSearchLocal.filters = currentSearchLocal.filters.filter((item) => item.fieldName !== action.payload);

      currentSearchLocal.savedSearchLoadedId = compareActiveFiltersWithSavedSearches(currentSearchLocal);

      state.currentSearch = currentSearchLocal;
    },
    clearAllFilters: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };

      if (action.payload === true) {
        currentSearchLocal.filters = [];
      } else {
        currentSearchLocal.filters = currentSearchLocal.filters.filter(
          (filter) => filter.fieldName === "matter_MatterTypeId"
        );
      }

      currentSearchLocal.savedSearchLoadedId = compareActiveFiltersWithSavedSearches(currentSearchLocal);

      state.currentSearch = currentSearchLocal;
    },
    setAllFilters: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      const filters = action.payload ?? [];

      currentSearchLocal.filters = filters;

      if (filters.some((filter) => filter.fieldName === "matter_MatterTypeId"))
        currentSearchLocal.reloadFieldsForMatterTypes = true;

      currentSearchLocal.savedSearchLoadedId = compareActiveFiltersWithSavedSearches(currentSearchLocal);

      state.currentSearch = currentSearchLocal;
    },
    labelSelectedChange: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.labelSelected = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    setFields: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.fields = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    setFavouriteFields: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.favouriteFields = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    setSavedSearches: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.savedSearches = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    setDefaultSearchId: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.defaultSearchId = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    resetReloadFieldsForMatterTypes: (state) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.reloadFieldsForMatterTypes = false;
      state.currentSearch = currentSearchLocal;
    },
    setSavedSearchLoadedId: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.savedSearchLoadedId = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    setKeyboardFocusedSelection: (state, action) => {
      // let focusedString = "";
      // switch (action.payload) {
      //   case simpleSearchKeyboardFocusedPopup.NONE:
      //     focusedString = "NONE";
      //     break;
      //   case simpleSearchKeyboardFocusedPopup.MAIN:
      //     focusedString = "MAIN";
      //     break;
      //   case simpleSearchKeyboardFocusedPopup.RHS:
      //     focusedString = "RHS";
      //     break;
      //   case simpleSearchKeyboardFocusedPopup.SEARCH_PANEL:
      //     focusedString = "SEARCH_PANEL";
      //     break;
      //   default:
      //     focusedString = "UNKNOWN";
      //     break;
      // }
      // console.log("setting keyboard focused selection to: ", focusedString);
      // console.trace();
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.isKeyboardFocusedSelection = action.payload;
      state.currentSearch = currentSearchLocal;
    },
    setPopupWholeSection: (state, action) => {
      const currentSearchLocal = { ...state.currentSearch };
      currentSearchLocal.popupWholeSection = action.payload;
      state.currentSearch = currentSearchLocal;
    },
  },
});

export const {
  setViewTypeSimpleSearch,
  popupVisibleChange,
  queryTypeChange,
  matterTypesSelectedChange,
  inputTextValueChange,
  fieldTextValueChange,
  searchValueChange,
  addFilter,
  clearFilter,
  clearAllFilters,
  setAllFilters,
  labelSelectedChange,
  setFavouriteFields,
  setFields,
  setSavedSearches,
  setDefaultSearchId,
  resetReloadFieldsForMatterTypes,
  setSavedSearchLoadedId,
  setKeyboardFocusedSelection,
  setPopupWholeSection,
} = simpleSearchSlice.actions;

export default simpleSearchSlice.reducer;
