import { useEffect, useState } from "react";
import { getDateDisplayValue, isDate } from "utilities/dateTime";
import { saveMatterChildObjectFieldChanges, saveMatterSimpleFieldChanges } from "api/matter";
import DatePicker from "components/global/DatePicker";
import DateAlert from "components/global/DateAlert";
import PropTypes from "prop-types";
import { sectionTypes } from "utilities/constants";
import { parseISO } from "date-fns";

// Container Component for the display of a date field within a matter data panel
function PanelFieldDate({ keyProps, canEdit, onChange, displayName }) {
  const [value, setValue] = useState(null);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    let dateObj = keyProps.data ? (isDate(keyProps.data) ? keyProps.data : parseISO(keyProps.data)) : null; //new Date();
    setValue(dateObj);
    setInitialValue(dateObj);
    //setInitialValue(keyProps.data ? toISOStringWithOffset(new Date(keyProps.data)) : null);
  }, [keyProps.data]);

  const onDateChange = (date) => {
    setValue(date);
    if ((keyProps.sectionType === sectionTypes.TABLE) === true) {
      if (keyProps.isAdding) onChange(keyProps.field.fieldName, date);
      else
        saveMatterChildObjectFieldChanges(
          keyProps.record.id,
          keyProps.tableName,
          keyProps.rowData.id,
          keyProps.field.fieldName,
          date,
          initialValue
        );
    } else saveMatterSimpleFieldChanges(keyProps.record.id, keyProps.field.fieldName, date, initialValue);
  };

  // if there is a linkedAlertField (usually "Date Completed", then check to see if this is not null. We only want to trigger an alert if there is a null linked alert field value)

  const linkedAlertDate =
    keyProps.field.linkedAlertField && keyProps.rowData && keyProps.rowData[keyProps.field.linkedAlertField];

  const renderValueContents = (
    <DateAlert data={value} hasAlert={keyProps.field.linkedAlertField && !linkedAlertDate} hasAbsolutePosition={true}>
      {canEdit ? (
        <DatePicker keyProps={keyProps} selected={value} onDateChange={onDateChange} />
      ) : (
        <span>{getDateDisplayValue(value)}</span>
      )}
    </DateAlert>
  );

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <div className="field__display-value">{renderValueContents}</div>
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value">{renderValueContents}</div>
    </>
  );
}

PanelFieldDate.propTypes = {
  keyProps: PropTypes.object,
  canEdit: PropTypes.bool,
  displayName: PropTypes.string,
  onChange: PropTypes.func,
};

export default PanelFieldDate;
