import { batchImportPrepare } from "api/batchImport";
import { t } from "locale/dictionary";
import { useDispatch, useSelector } from "react-redux";
import { setBatchImportLoading } from "redux/batchImportSlice";
import { toggleCollapsedSection } from "redux/projectSlice";
import { projectStatuses } from "utilities/constants";

export default function BatchImportPrepare() {
  const currentSearchResultsState = useSelector((state) => state.searchResults).currentSearch;
  const appState = useSelector((state) => state.app);
  const batchImportState = useSelector((state) => state.batchImport);
  const dispatch = useDispatch();

  const isDisabled =
    currentSearchResultsState?.selected?.length === 0 ||
    batchImportState?.project?.project_Status >= projectStatuses.TEMPLATE_GENERATED;

  const handleImportRecords = async () => {
    dispatch(setBatchImportLoading(true));
    const response = await batchImportPrepare(appState.customerId, batchImportState.project.id);
    debugger;
    if (response) dispatch(setBatchImportLoading(false));
    dispatch(toggleCollapsedSection("Trademark Search and Results"));
  };

  return (
    <div className="batch-import__prepare">
      <button className={`${isDisabled && "disabled"}`} disabled={isDisabled} onClick={handleImportRecords}>
        {t("Import")}
      </button>
    </div>
  );
}
