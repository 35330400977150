import { useEffect, useState } from "react";
import { t } from "locale/dictionary";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import PanelForm from "./PanelForm";
import PanelTable from "./PanelTable";
import PanelThumbnails from "./PanelThumbnails";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { registerSectionsAndFields, setElementIdToScrollTo } from "redux/matterSlice";
import { sectionTypes, validationStates } from "utilities/constants";
import { getPanelIdString, idsAreEqual } from "utilities/stringAndArray";
import PanelTableLinks from "./PanelTableLinks";
import { useLayoutEffect } from "react";

// Master Container Component for the all types of matter data panel
function Panel({ keyProps, children, onSetConnectedMatterPopupVisible, onAddNew, onChange, onDelete, isReadOnly }) {
  const matterState = useSelector((state) => state.matter);
  const [isExpanded, setIsExpanded] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    keyProps.record &&
      !keyProps.record.sections &&
      dispatch(
        registerSectionsAndFields({
          record: keyProps.record,
          sectionName: keyProps.sectionName,
          tableName: keyProps.tableName,
          fields: keyProps.fieldList.map((field) => {
            return {
              fieldName: field.fieldName,
              validationStates: [{ state: validationStates.UNKNOWN, string: null }],
            };
          }),
        })
      );
  }, [keyProps.record]);

  useLayoutEffect(() => {
    const element = document.getElementById(matterState.elementIdToScrollTo);
    if (matterState.elementIdToScrollTo && element && !matterState.isComparing) {
      element.scrollIntoView();
      dispatch(setElementIdToScrollTo({ elementId: null }));
    }
  }, []);

  let renderContent = <div></div>;
  if (isExpanded && keyProps.fieldList) {
    switch (keyProps.sectionType) {
      case sectionTypes.FORM:
        renderContent = <PanelForm keyProps={{ ...keyProps, isPanelReadOnly: isReadOnly }} />;
        break;
      case sectionTypes.TABLE:
        renderContent = idsAreEqual(keyProps.sectionId, 80) ? ( // Connected Matters
          <PanelTableLinks
            keyProps={{ ...keyProps, isPanelReadOnly: isReadOnly }}
            onSetConnectedMatterPopupVisible={onSetConnectedMatterPopupVisible}
          />
        ) : (
          <PanelTable keyProps={{ ...keyProps, isPanelReadOnly: isReadOnly }} onChange={onChange} onDelete={onDelete}>
            {children}
          </PanelTable>
        );
        break;

      case sectionTypes.THUMBNAILS:
        renderContent = (
          <PanelThumbnails
            keyProps={{ ...keyProps, isPanelReadOnly: isReadOnly }}
            onAddNew={onAddNew}
            onChange={onChange}
            onDelete={onDelete}
          />
        );
        break;
      default:
        console.log("Invalid sectionType");
    }
  }

  const classNameBase = "data-panel__header";
  let className = classNameBase;
  if (isExpanded) {
    className = `${classNameBase} ${classNameBase}--expanded`;
  }

  return (
    <div className="data-panel-list__data-panel" id={getPanelIdString(keyProps.sectionName)}>
      <div className={className}>
        <div className="data-panel__header-lhs">
          <div className="data-panel__caret clickable" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <FaCaretDown /> : <FaCaretRight />}
          </div>
          <div className="data-panel__title">{t(keyProps.sectionName)}</div>
        </div>
        {isExpanded && keyProps.hasSpecialActionBar && children && keyProps.sectionId !== 90 && (
          <div className="header-bar">{children}</div>
        )}
      </div>
      {renderContent}
    </div>
  );
}

Panel.propTypes = {
  keyProps: PropTypes.object,
  onSetConnectedMatterPopupVisible: PropTypes.func,
  onAddNew: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default Panel;
