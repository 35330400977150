import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import iconDocumentDefault from "images/icon-document.png";
import iconWord from "images/icon-word.png";
import iconExcel from "images/icon-excel.png";
import iconOutlook from "images/icon-outlook.png";
import iconEmail from "images/icon-email.png";
import iconPdf from "images/icon-pdf.png";
import iconText from "images/icon-text.png";
import iconPresentation from "images/icon-presentation.png";
import iconArchive from "images/icon-archive.png";
import iconPicture from "images/icon-picture.png";
import iconAudio from "images/icon-audio.png";
import iconVideo from "images/icon-video.png";
import { getDocumentData } from "api/document";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";

// Container Component for the display of a downloadable document with display text and icon
function PanelFieldDocument({ keyProps }) {
  let iconImage = null;
  const fileExt = keyProps.data.substr(keyProps.data.lastIndexOf(".") + 1);
  switch (fileExt) {
    case "doc":
    case "docx":
    case "odt":
      iconImage = iconWord;
      break;
    case "csv":
    case "ods":
    case "xls":
    case "xlsx":
      iconImage = iconExcel;
      break;
    case "pdf":
      iconImage = iconPdf;
      break;
    case "txt":
      iconImage = iconText;
      break;
    case "msg":
      iconImage = iconEmail;
      break;
    case "eml":
      iconImage = iconOutlook;
      break;
    case "odp":
    case "ppt":
    case "pptx":
      iconImage = iconPresentation;
      break;
    case "7z":
    case "zip":
    case "zipx":
      iconImage = iconArchive;
      break;
    case "bmp":
    case "jpg":
    case "jpeg":
    case "gif":
    case "tif":
    case "tiff":
    case "png":
    case "heiff":
    case "webm":
      iconImage = iconPicture;
      break;
    case "aif":
    case "aifc":
    case "aiff":
    case "wav":
    case "ogg":
    case "mp3":
    case "rf64":
      iconImage = iconAudio;
      break;
    case "avi":
    case "avchd":
    case "mp2":
    case "mp4":
    case "mpe":
    case "m4v":
    case "m4p":
    case "mkv":
    case "mov":
    case "qt":
    case "mpg":
    case "mpeg":
    case "mpv":
    case "3g2":
    case "3pg":
    case "3gpp":
    case "wmv":
      iconImage = iconVideo;
      break;
    default:
      iconImage = iconDocumentDefault;
      break;
  }

  const getDocument = async () => {
    const blob = await getDocumentData(keyProps.record.id, keyProps.rowData.id);
    saveAs(blob, keyProps.rowData.matterDocument_Filename);
  };

  const secondaryInfoText = `${t("File size")}: ${keyProps.rowData.matterDocument_ByteCount} ${t("bytes")}`;

  return (
    <Tooltip content={secondaryInfoText}>
      <div className="table-cell__filename-with-icon clickable" onClick={getDocument}>
        <img src={iconImage} alt={keyProps.data} />
        {keyProps.data}
      </div>
    </Tooltip>
  );
}

PanelFieldDocument.propTypes = {
  keyProps: PropTypes.object,
};

export default PanelFieldDocument;
