import store from "redux/store";

export function setBorderStyle(style, border, prefix) {
  if (border) {
    style[prefix + "Style"] = border.style;
    if (border.width) {
      style[prefix + "Width"] = border.width;
    } else {
      style[prefix + "Width"] = "thin";
    }
    if (border.color) {
      style[prefix + "Color"] = border.color;
    } else {
      style[prefix + "Color"] = "#000";
    }
  }
}

export function getTranslation(translationCode) {
  const state = store.getState();

  return state.locale.translations[translationCode];
}
