import { t } from "locale/dictionary";
import PopupHeader from "./PopupHeader";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { setIsShowingFullScreenModal } from "redux/appSlice";
import PropTypes from "prop-types";

function Modal({
  children,
  title,
  onConfirm,
  customCssClass,
  forceAbsoluteTopPosition,
  closeOnly,
  leaveOpen,
  buttonLabel = "",
}) {
  const dispatch = useDispatch();
  const [displayChildren, setDisplayChildren] = useState(false);

  const onConfirmClick = () => {
    onConfirm();
    if (!leaveOpen) handleModalDisplay(false);
  };

  const handleModalDisplay = (visibility) => {
    dispatch(setIsShowingFullScreenModal(visibility));
    setDisplayChildren(visibility);
  };

  const popupClassNameBase = "delete__confirmation-popup";
  let popupClassName = popupClassNameBase;
  if (forceAbsoluteTopPosition) popupClassName = `${popupClassNameBase} ${popupClassNameBase}--force-top`;

  return (
    <>
      {buttonLabel.length > 0 && <button onClick={() => handleModalDisplay(true)}>{t(buttonLabel)}</button>}
      {displayChildren && (
        <div className="modal-mask">
          <div className={popupClassName}>
            <PopupHeader title={t(title)} onClose={() => handleModalDisplay(false)} />
            <div className="delete__confirmation-body">
              {children}
              <div className="delete__confirmation-row">
                {!closeOnly && <button onClick={onConfirmClick}>{t("Confirm")}</button>}
                <button onClick={() => handleModalDisplay(false)}>{t(`${closeOnly ? "Close" : "Cancel"}`)}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Modal.propTypes = {
  onConfirm: PropTypes.func,
  customCssClass: PropTypes.string,
  forceAbsoluteTopPosition: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  closeOnly: PropTypes.bool,
  leaveOpen: PropTypes.bool,
};

export default Modal;
