// Link type utility functions

import store from "redux/store";
import { matterTypes } from "./constants";
import { hasMatchingId, idsAreEqual } from "./stringAndArray";

const cmtid = matterTypes.COMPANY;

// Filter a link type for linking a matter record to a non-company matter record
function nonCompanyMatterTypeLinkFilter(matterTypeLink, matterTypeId1, matterTypeId2 = null) {
  if (matterTypeId1 === null) {
    // Matter type 1 must be specified
    return false;
  } else if (matterTypeLink.matterTypeIdA === cmtid && matterTypeLink.matterTypeIdB === cmtid) {
    // Not valid - company to company link
    return false;
  } else if (matterTypeLink.matterTypeIdA === cmtid || matterTypeLink.matterTypeIdB === cmtid) {
    // One side company link
    if (matterTypeId1 === cmtid && matterTypeId2 === cmtid) {
      // Not valid - company to company link
      return false;
    } else if (matterTypeLink.matterTypeIdA === cmtid) {
      return (
        (matterTypeLink.matterTypeIdA === matterTypeId1 &&
          (matterTypeLink.matterTypeIdB === null ||
            matterTypeId2 === null ||
            matterTypeLink.matterTypeIdB === matterTypeId2)) ||
        (matterTypeLink.matterTypeIdA === matterTypeId2 &&
          (matterTypeLink.matterTypeIdB === null || matterTypeLink.matterTypeIdB === matterTypeId1))
      );
    } else if (matterTypeLink.matterTypeIdB === cmtid) {
      return (
        (matterTypeLink.matterTypeIdB === matterTypeId1 &&
          (matterTypeLink.matterTypeIdA === null ||
            matterTypeId2 === null ||
            matterTypeLink.matterTypeIdA === matterTypeId2)) ||
        (matterTypeLink.matterTypeIdB === matterTypeId2 &&
          (matterTypeLink.matterTypeIdA === null || matterTypeLink.matterTypeIdA === matterTypeId1))
      );
    }
  } else {
    // Check both sides for matched non-company link
    return (
      ((matterTypeLink.matterTypeIdA === null || matterTypeLink.matterTypeIdA === matterTypeId1) &&
        (matterTypeLink.matterTypeIdB === null ||
          matterTypeId2 === null ||
          matterTypeLink.matterTypeIdB === matterTypeId2)) ||
      ((matterTypeLink.matterTypeIdA === null ||
        matterTypeId2 === null ||
        matterTypeLink.matterTypeIdA === matterTypeId2) &&
        (matterTypeLink.matterTypeIdB === null || matterTypeLink.matterTypeIdB === matterTypeId1))
    );
  }
}

// Get available link types for linking a matter record to a non-company matter record
export function getMatterLinkTypes(matterTypeId1, matterTypeId2 = null) {
  const state = store.getState();
  const linkTypes = state.lookup.global.find((lookup) => lookup.name === "LinkTypes");

  return linkTypes.lookup.filter((linkType) =>
    linkType.matterTypeLinks.some((matterTypeLink) =>
      nonCompanyMatterTypeLinkFilter(matterTypeLink, matterTypeId1, matterTypeId2)
    )
  );
}

// Get available link types for linking a matter record to a company matter record
export function getCompanyLinkTypes(matterTypeId) {
  const state = store.getState();
  const linkTypes = state?.lookup?.global.find((lookup) => lookup.name === "LinkTypes");

  return linkTypes?.lookup?.filter((linkType) =>
    linkType.matterTypeLinks.some((matterTypeLink) => {
      if (idsAreEqual(matterTypeId, cmtid)) {
        // A AND B must be companies
        return idsAreEqual(matterTypeLink.matterTypeIdA, cmtid) && idsAreEqual(matterTypeLink.matterTypeIdB, cmtid);
      } else {
        // One side has to be a company and the other match the matter type parameter
        if (idsAreEqual(matterTypeLink.matterTypeIdA, cmtid)) {
          return matterTypeLink.matterTypeIdB === null || idsAreEqual(matterTypeLink.matterTypeIdB, matterTypeId);
        } else if (idsAreEqual(matterTypeLink.matterTypeIdB, cmtid)) {
          return matterTypeLink.matterTypeIdA === null || idsAreEqual(matterTypeLink.matterTypeIdA, matterTypeId);
        } else {
          return false;
        }
      }
    })
  );
}

// Get all available company link types
export function getCompanyLinkTypesAll() {
  const state = store.getState();
  const linkTypes = state?.lookup?.global.find((lookup) => lookup.name === "LinkTypes");

  return linkTypes?.lookup?.filter((linkType) =>
    linkType.matterTypeLinks.some(
      (matterTypeLink) =>
        !idsAreEqual(matterTypeLink.matterTypeIdA, cmtid) && idsAreEqual(matterTypeLink.matterTypeIdB, cmtid)
    )
  );
}

// Get available link types for linking a matter record to a company matter record
export function getNonCompanyLinkTypes(matterTypeIds) {
  const state = store.getState();
  const linkTypes = state.lookup.global.find((lookup) => lookup.name === "LinkTypes");
  const companyMatterFilter = hasMatchingId(matterTypeIds, cmtid);
  const singleCompanyMatterFilter = companyMatterFilter && matterTypeIds.length === 1;

  return linkTypes.lookup.filter((linkType) =>
    linkType.matterTypeLinks.some((matterTypeLink) => {
      if (
        companyMatterFilter &&
        idsAreEqual(matterTypeLink.matterTypeIdA, cmtid) &&
        idsAreEqual(matterTypeLink.matterTypeIdB, cmtid)
      ) {
        return false;
      } else if (
        !companyMatterFilter &&
        (idsAreEqual(matterTypeLink.matterTypeIdA, cmtid) || idsAreEqual(matterTypeLink.matterTypeIdB, cmtid))
      ) {
        return false;
      } else if (
        singleCompanyMatterFilter &&
        (matterTypeLink.matterTypeIdA === null || idsAreEqual(matterTypeLink.matterTypeIdB, cmtid))
      ) {
        return true;
      } else if (
        !singleCompanyMatterFilter &&
        (matterTypeLink.matterTypeIdA === null || matterTypeLink.matterTypeIdB === null)
      ) {
        return true;
      } else if (matterTypeIds) {
        return matterTypeIds.some(
          (matterTypeId) =>
            idsAreEqual(matterTypeLink.matterTypeIdA, matterTypeId) ||
            idsAreEqual(matterTypeLink.matterTypeIdB, matterTypeId)
        );
      } else {
        return true;
      }
    })
  );
}

// Get valid matter type ids for a given link type and matter type id
//   Required for search filtering when searching for matter records to link to
//   Note: returns an array of matter type ids, an empty array requires a filter excluding company matter records.
export function getLinkTypeMatterTypes(linkType, matterTypeId) {
  return linkType.matterTypeLinks
    .filter((linkMatterType) => nonCompanyMatterTypeLinkFilter(linkMatterType, matterTypeId))
    .filter((linkMatterType) => {
      return linkMatterType.matterTypeIdA === null || linkMatterType.matterTypeIdA === matterTypeId
        ? linkMatterType.matterTypeIdB !== null
        : linkMatterType.matterTypeIdA !== null;
    })
    .map((linkMatterType) =>
      linkMatterType.matterTypeIdA === null || linkMatterType.matterTypeIdA === matterTypeId
        ? linkMatterType.matterTypeIdB
        : linkMatterType.matterTypeIdA
    );
}
