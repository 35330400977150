import { useEffect, useState } from "react";
import InputNumberPicker from "./InputNumberPicker";
import { useDispatch } from "react-redux";
import { setSectionRowHeight } from "redux/reportSlice";
import { findSection } from "utilities/reportEditor";
import PropTypes from "prop-types";

function ToolbarSectionRow({ format, selected }) {
  const dispatch = useDispatch();

  const [rowHeight, setRowHeight] = useState(null);

  useEffect(() => {
    let rowHeight = null;
    for (let i = 0; i < selected.length; i++) {
      const section = findSection(format.gridSectionGroup, selected[i].sectionId);
      let row = null;
      if (section.rows) {
        row = section.rows.find((row) => row.row === selected[i].row);
      }

      if (!row) {
        rowHeight = null;
      } else if (i === 0) {
        rowHeight = row.rowHeight;
      } else if (rowHeight !== row.rowHeight) {
        rowHeight = null;
        break;
      }
    }
    setRowHeight(rowHeight);
  }, [selected, format.gridSectionGroup]);

  return (
    <InputNumberPicker
      label="Row height"
      value={rowHeight}
      onChange={(value) => dispatch(setSectionRowHeight({ value: value }))}
      min={1}
      max={1024}
      numbers={[4, 8, 16, 24, 28, 32, 36, 42, 48, 56, 64, 96, 128, 256, 512]}
      tooltipText="Select row height"
    />
  );
}

ToolbarSectionRow.propTypes = {
  format: PropTypes.object,
  selected: PropTypes.arrayOf(PropTypes.object),
};

export default ToolbarSectionRow;
