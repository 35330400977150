import { useSelector } from "react-redux";
import Tooltip from "./Tooltip";
import PropTypes from "prop-types";
import { findById, idsAreEqual } from "utilities/stringAndArray";

// A wrapper component for the display of a country flag, with props input ISO code
function CountryFlagContainer({ isoCode }) {
  const lookup = useSelector((state) => state.lookup);
  const locale = useSelector((state) => state.locale);

  const lookupSource = lookup.global.find((lookup) => lookup.name === "Countries");
  const country = findById(lookupSource.lookup, isoCode) ?? "??";
  const displayName = locale.translations[country.translationCode];

  const className = country.isoCodeAlpha2 ? `flag flag-${country.isoCodeAlpha2.toLowerCase()}` : "flag";

  return (
    <Tooltip content={displayName}>
      <div className="flag-container">
        <div className="flag-label">{country.isoCodeAlpha2}</div>
        <div className={className}></div>
      </div>
    </Tooltip>
  );
}

CountryFlagContainer.propTypes = {
  isoCode: PropTypes.number,
};

export default CountryFlagContainer;
